import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import * as StrategyConsts from './StrategyConstants';
import classNames from "classnames";
import debounce from "lodash.debounce"

import StrategyCSS from "./Strategy.module.scss";
import {
  fetchFilterData,
  fetchStrategyTableData,
  fetchStrategyTableDropdown,
  fetchStoregroupMinStock,
  setTableInstance,
  fetchStoreTableData,
  fetchDCData,
  enableFilters,
  resetFiltersOptions,
  createAllocation,
  editDataAda,
  updateResQty,
  save,
  resetAll,
  saveDraft,
  // getDrafts,
  resetSaveDraftData,
  deleteDraft,
  validatePlanName,
  setLoaderToFalse,
  reviewAda,
  resetStyleIndex,
  fetchStrategyTableDataForCheckAll,
  resetStyleIndexForCA
  // getstoregroupminstock,
} from "./actions";
import { setAdaAllocationRequest, setAdaCurrentDate } from '../AdaVisual/actions/AdaVisualActions';
import Api from "../../configs/apiConfig"
import ReactTable from "../../components/Table/ReactTable";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import SelectCell from "../../components/Table/Cellrenderer/SelectCell";
import DatePicker from "../../components/Table/Cellrenderer/DatePicker";
import MultiSelectCell from "../../components/Table/Cellrenderer/MultiSelectCell";
import MultiSelect from "../../components/Filters/MultiSelect";
import SingleSelect from "../../components/Filters/SingleSelect";
import { clone, cloneDeep, difference, filter, forEach, includes, intersection, intersectionWith, isEmpty, isEqual, isNull, omit, set } from "lodash";
import { Tab, Tabs } from "react-bootstrap";
import InputCell from "../../components/Table/Cellrenderer/InputCell";
import ToggleComponent from "../../components/Toggle/ToggleComponent";
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import SelectCellComponent from "../../components/Table/Cellrenderer/SelectCellComponent"
import PageLoader from "../../components/Loader/PageLoader";
import Select from "../../components/Filters/SingleSelect";
import ErrorModal from "../../components/ErrorModal/ErrorModal"
import { FINALIZE_PAGE } from "../../constants/routeConstants";
import ColumnFilter from "../UserManagement/ColumnFilter";
import { GET_DC_DATA, GET_SIZE_DC, GET_APS, GET_STORE_GROUP_MIN_STOCK, SSE_CREATE_ALLOCATON } from "../../constants/apiConstants";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { addPer, numberUSFormatting, zeroDecimal } from "../../utils/formatters/valueFormatters";
import SelectColumnFilter from "../../components/Filters/MultiSelectColumnFilter";
import { levelFiltersLabelFormatter, updateArticleName } from "../../utils/filterLevelMapping";
import AdaVisualContainer from '../AdaVisual/AdaVisualContainer';
import { getUserPreferenceFilter, getLevelFiltersForUserPreference, shouldHideForCurrentClient, hideToProd, scrollIntoView, setUserPreferenceFilter, isAshley, getFilterForClients, getDefaultFiltersCondition, isMandatory, isFutureDate, reloadPageWithUpdateToken, disableFutureDates } from "../../utils/commonUtilities";
import Drafts from "../Drafts/Drafts"
import NumberRangeColumnFilter from "../../components/Filters/NumberRangeColumnFilter";
import SetAllModal from "../../components/ErrorModal/SetAllModal";
import { oneDecimal } from "../../utils/formatters/valueFormatters";
import ConfirmationAlert from "../../components/ConfirmationAlert/ConfirmationAlert";
import ColumnFilterServerSide from "../../components/Filters/ColumnFilterServerSide";
import NumberRangeColumnFilterServerSide from "../../components/Filters/NumberRangeColumnFilterServerSide";
import { range } from "../Constraints/ConstraintsTable/Store";
import { getArrayDifference, getFinalCheckedArticles, getFinalCheckedRowsForCAData, getKeyOnSearchedterm, getNewArticleToBeUnchecked, getNewData, getRecentUpdatesOnArticle, getSetAllReqForNextSetOfData, getStoreAndDcRequestForNewSetOfArticles, removeCheckedArticles } from "./StrategyHelper";
import { DateRangePicker } from 'react-dates';
import { ERROR_MSG } from "../../constants/globalConstant";
import ReactTollTip from "../../components/Table/Cellrenderer/ReactToolTip";
import { INVENTORY_SOURCE_FILTER_DATA } from "../StrategyConfiguration/StrategyConfigConstants";
import { getDrafts } from '../Drafts/DraftsAction'


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const recordsPerPage = 10;
let eventSource
var newUpdatedData;

let getUPC = (p_rowData, p_sizeValues) => {
  return (p_sizeValues.map(val => p_rowData[val]))
}

let getEstimatedDemand = (p_rowData, p_columnId, p_Value) => {
  try {
    let l_otherColumnId
    if (p_columnId == "WOS_rounded")
      l_otherColumnId = "APS/ROS_rounded"
    else
      l_otherColumnId = "WOS_rounded"
      return zeroDecimal(
        Math.max(
          Math.min(
            p_rowData.max_stock,
            Math.max(p_rowData.min_stock, p_rowData[l_otherColumnId] * p_Value)
          ) -
            (Number(p_rowData.onhand) +
              Number(p_rowData.onorder) +
              Number(p_rowData.intransit)),
          0
        )
      );
    // return (zeroDecimal(Math.max(0, Math.max(Number(p_rowData.min_stock), (p_rowData[l_otherColumnId] * p_Value)) -
    //   (Number(p_rowData.onhand) + Number(p_rowData.onorder) + Number(p_rowData.intransit)))));
  }
  catch {
    return 0;
  }
}

const Strategy = (props) => {
  
  const getStoreCodes = () => {
    let filters = props.history?.location?.state?.filters;
    let storeumbers = props.history?.location?.state?.storeNumbers;
    return filters?.lms_store_attribute ? storeumbers : null ;  
  }

  const [tableData, setTableData] = useState([]);
  const [storeGroupStoreResult, setStoreGroupStoreResult] = useState([]);
  const [storeMinStockResult, setStoreMinStockResult] = useState([]);
  const [tableInstance, setTableInstance] = useState(null);
  const [storeGroupTableInstance, setStoreGroupTableInstance] = useState(null);
  const [dcTableInstace, setDcTableInstance] = useState(null);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [level4Options, setLevel4Options] = useState([])
  const [level5Options, setLevel5Options] = useState([])
  const [level6Options, setLevel6Options] = useState([])
  const [level7Options, setLevel7Options] = useState([])
  const [subdepartmentOptions, setSubdepartmentOptions] = useState([]);
  const [division, setDivision] = useState(null);
  const [department, setDepartment] = useState(null);
  const [storeCode, setStoreCode] = useState(getStoreCodes());
  const [storeName, setStoreName] = useState();
  const [factoryType, setFactoryType] = useState(null);
  const [factoryTypeOptions, setFactoryTypeOptions] = useState(null);
  const [storeCodeOptions, setStoreCodeOptions] = useState(getStoreCodes());
  const [storeNameOptions, setStoreNameOptions] = useState()
  const [segOptions, setSegOptions] = useState(null);
  const [seg, setSeg] = useState(null);
  const [subdepartment, setSubdepartment] = useState(null);
  const [level4, setLevel4] = useState(null);
  const [level5, setLevel5] = useState(null);
  const [level6, setLevel6] = useState(null);
  const [level7, setLevel7] = useState(null);
  const [storeTableData, setStoreTableData] = useState(null);
  const [storeTableDataCopy, setStoreTableDataCopy] = useState(null);
  const [dcTableData, setDcTableData] = useState(null);
  const [reviewBtnDisabled, setReviewBtnDisabled] = useState(true);
  const [articles, setArticles] = useState({});
  const [articleOptions, setArticleOptions] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedArticleDcData, setSelectedArticleDcData] = useState(null);
  const [selectedArticleStoreConst, setSelectedArticleStoreConst] = useState(
    null
  );
  const [editStoreIndex, setEditStoreIndex] = useState(true);
  const [disableFilters, setDisableFilters] = useState(false);
  const [refStoreDropDownOptions, setRefStoreDropDownOptions] = useState(true);
  const [storeRequest, setStoreRequest] = useState([])
  const [dcRequest, setDcRequest] = useState([])
  const [selectedArticleWithData, setSelectedArticleWithData] = useState([])
  const [saveIsDisabled, setSaveIsDisabled] = useState(false)
  const [level1Label, setLevel1Label] = useState('')
  const [level2Label, setLevel2Label] = useState('')
  const [level3Label, setLevel3Label] = useState('')
  const [level4Label, setLevel4Label] = useState('')
  const [level5Label, setLevel5Label] = useState('')
  const [level6Label, setLevel6Label] = useState('')
  const [level7Label, setLevel7Label] = useState('')
  const [myInstance, setMyInstance] = useState(null)
  const [isPercent, setIsPercent] = useState(true)
  const [isReviewChangesDisabled, setIsReviewChangesDisabled] = useState(true)
  const [errorModalMessage, setErrorModalMessage] = useState(null)
  const [createAllocationReq, setCreateAllocationReq] = useState(null)
  const [failedArticleId, setFailedArticleId] = useState(null)
  const [request, setRequest] = useState(null)
  const [initialIndex, setInitialIndex] = useState(0)
  const [allocationName, setAllocationName] = useState(null)
  const [sortBy, setSortBy] = useState([])
  const [buttonLabel, setButtonLabel] = useState("Exclude All")
  const [secondaryButtonLabel, setSecondaryButtonLabel] = useState("Cancel")
  const [showSetAll, setShowSetAll] = useState(false)
  const [showSetAllStoreDetails, setShowSetAllStoreDetails] = useState(false)
  const [storeEligibiltyGroupSA, setstoreEligibiltyGroupSA] = useState(null);
  const [inventorySourceSA, setInventorySourceSA] = useState(null);
  const [dcSA, setDcSA] = useState(null);
  const [demandTypeSA, setDemandTypeSA] = useState(null);
  const [productProfileSA, setProductProfileSA] = useState(null);
  const [userDefinedInvSA, setUserDefinedInvSA] = useState(null);
  const [plannedAPSSA, setPlannedAPSSA] = useState(null)
  const [plannedWOSSA, setPlannedWOSSA] = useState(null)
  const [apsSA, setapsSA] = useState(null)
  const [wosSA, setwosSA] = useState(null)
  const [setAllLoading, setSetAllLoading] = useState(false)
  const inputRef = useRef(null);
  const adaVisualRef = useRef(null);
  const [isForecastrRevBtnDisable, setForecastRevDisableState] = useState(true);
  const [plannedAPSDisabled, setPlannedAPSDisabled] = useState(false)

  // const tableRef = useRef(null);
  const [isShowDrafts, setShowDrafts] = useState(false)
  const [selectedArticlesDraftData, setSelectedArticlesDraftData] = useState([])
  const [currentDraftallocationCode, setCurrentDraftallocationCode] = useState("")
  const [isNewDraftSaved, setIsNewDraftSaved] = useState(false)
  const [isContinueAllocation, setIsContinueAllocation] = useState(false)
  const [sspForDraft, setSspForDraft] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [initialSelectedRows, setInitialSelectedRows] = useState({})
  const [intitalSelectedStoreRows, setIntitalSelectedStoreRows] = useState({})
  const [draftInstance, setDraftInstance] = useState({})
  const [shouldDisplayFilter, setShouldDisplayFilter] = useState(true)
  const [hideDcStoreSection, setHideDcStoreSection] = useState(false)
  const [hideMainTable, setHideMainTable] = useState(false)
  const [loaderdts, setLoader] = useState(false)
  const [isFirstDraftsCall, setIsFirstDraftsCall] = useState(true)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [articleStoreMapping, setArticleStoreMapping] = useState({})
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [isShowStoreAlert, setShowStoreAlert] = useState(false)
  const [storeAlertMessage, setStoreAlertMessage] = useState(null)
  const [totalEstimatedDemad, setTotalEstimatedDemad] = useState(null)
  const [useSSE, setUseSSE] = useState(false)
  const [isPOASNFlow, setIsPOASNFlow] = useState(false)
  const [POASNHiddenColumns, setPOASNHiddenColumns] = useState([])
  const [poDetails, setPODetails] = useState({})
  const [adaDataReq,setAdaDataReq] =  useState(null)
  const [min_date, setStartDate] = useState(null);
  const [max_date, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isEditing, setIsEditing] = useState(false)

// state for server side pagination
  const [prevIndex,setPrevIndex] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [searchTermReq, setSearchTermReq] = useState({})
  const [sortReq, setSortReq] = useState({})
  const [isFirstCall, setisFirstCall] = useState(true)
  const [checkAll, setCheckAll] = useState([])
  const [prevCount, setPrevCount] = useState(0)
  const [checkAllSetAllReq, setCheckAllSetAllReq] = useState([])
  const [updatedArticles, setUpdatedArticles] = useState([])
  const [updatedRows, setUpdatedRows] = useState([])
  const [callCheckAllApi, setCallCheckAllApi] = useState(true)
  const [tableDataForCA, setTableDataForCA] = useState([]);
  const [createAllocationBeforeAllArticles,setCreateAllocationBeforeAllArticles] = useState(false)
  // const [checkedArticles, setCheckedArticles] = useState(["02126924","02196301"])
  const [checkedArticles, setCheckedArticles] = useState([])
  const [unCheckedArticles, setUnCheckedArticles] = useState([])
  const [checkedRows, setCheckedRows] = useState([])
  const [prevNumber, setPrevNumber] = useState(1);
  const [prevAction, setPrevAction] = useState("")
  const [prevActionForRest, setPrevActionForRest] = useState("")
  const [checkAllApisWithSearchTerm, setCheckAllApisWithSearchTerm] = useState([])
  const [articlesWithZeroInv, setArticleWithZeroInv] = useState([])
  const [articlesWithZeroInvAsUserReserved, setArticleWithZeroInvAsUserReserved] = useState([])
  const [articlesWithZeroInvForRest, setArticleWithZeroInvForRest] = useState([])
  const [articlesWithZeroInvAsUserReservedForRest, setArticleWithZeroInvAsUserReservedForRest] = useState([])
  const [articleWithNoPPForRest,setArticleWithNoPPForRest] = useState([])
  const [articleWithZeroWosForRest,setArticleWithZeroWosForRest] = useState([])
  const [articleWithNoSEGForRest,setArticleWithNoSEGForRest] = useState([])
  const [articleWithNoDCForRest,setArticleWithNoDCForRest] = useState([])
  const [articleWithNoFixedUserInvForRest,setArticleWithNoFixedUserInvForRest] = useState([])
  const [userEditAfterValidationOnRest,setUserEditAfterValidationOnRest] = useState(false)
  const [checkAllForRest, setCheckAllForRest] = useState([])
  const [checkedRowsForRest, setCheckedRowsForRest] = useState([])
  const [updatedArticlesForRest, setUpdatedArticlesForRest] = useState([])
  const [updatedRowsForRest, setUpdatedRowsForRest] = useState([])
  const [stylesFromDashboard,setStylesFromDashboard] = useState([])
  const [hideTotalRecords, setHideTotalRecords] = useState(true)
  const [hideTotalPages, setHideTotalPages] = useState(true)
  const [min_allocation_date, setAllocationStartDate] = useState(null);
  const [max_allocation_date, setAllocationEndDate] = useState(null);
  const [focusedInputAllocationDate, setFocusedInputAllocationDate] = useState(null);
  const [dcDataLoading, setDCDataLoading] = useState(false);

  const [showCount, setShowCount] = useState(0)
  const [isSaveDraft, setIsSaveDraft] = useState(false)
  const [isBackFromFinalize, setBackFromFinalize] = useState(false)

  useEffect(() => {
    if(props.out_of_data || props?.location?.state?.styles) {
      setHideTotalPages(false);
      setHideTotalRecords(false);
    }
    else{
      setHideTotalPages(true);
      setHideTotalRecords(true);
    }
  },[props.out_of_data])

  useEffect(() => {
    const SSE_CLIENTS = ["ashley", "coach", "coach_ch", "coach_jp"]
    if(SSE_CLIENTS.includes(process.env.REACT_APP_CLIENT)) {
      setUseSSE(true)
    }
  },[process.env.REACT_APP_CLIENT])

  // const [searchTerm, setSearchTerm] = useState("")

  let l_noOfStores
  const getNoofStores = (p_storeGroupCode) => {
    l_noOfStores = []
    for (let i in storeGroupStoreResult) {
      if (p_storeGroupCode.includes(Number(i))) {
        l_noOfStores.push(storeGroupStoreResult[i])
      }
    }
    l_noOfStores = [... new Set(l_noOfStores.flat(Infinity))]
    return l_noOfStores.length;
  }

  let getInvComponent = (p_req) => {
    let invCompList
    if(isAshley()) {
      if(props?.location?.state?.isPOASNFlow) {
        invCompList = ['oh_map', 'rq_map']
      }
      else{
        invCompList = ['oh_map', 'rq_map', 'au_map', 'po_map']
      }
    }
    else {
      invCompList = ['oh_map', 'it_map', 'oo_map', 'rq_map', 'au_map']
    }
    const finalMapping = {}
    while (invCompList.length) {
      let invComp = invCompList[invCompList.length - 1]
      let articleSizStoreMapping = Object.fromEntries?.(Object.entries?.(p_req.data?.[invComp] || {})?.filter(([key, value]) => p_req?.size.includes(key)))
      let sum = 0, l_inventory_source = p_req?.inventory_source
      for (let size in articleSizStoreMapping) {
        for (let code of p_req.store_code) {
          if(typeof(articleSizStoreMapping?.[size]?.[code]) === "object"){
            // Addition loop is for multiple Inventory Source like oh,it  
            for (let source of l_inventory_source) {
              sum += articleSizStoreMapping?.[size]?.[code]?.[source.value] ? Number(articleSizStoreMapping[size][code][source.value]) : 0
            }
          }
          else{
            sum += articleSizStoreMapping?.[size]?.[code] ? Number(articleSizStoreMapping[size][code]) : 0
          }
        }
      }
      finalMapping[invComp] = sum;
      invCompList.pop();
    }
    return (finalMapping)
  }

  const getMinPresQty = (p_article) => {
    return storeMinStockResult.filter(val => l_noOfStores.includes(val.store_code)).filter(val => val.article == p_article).reduce((acc, curr) => {
      return acc = acc + curr.min_stock
    }, 0)
  }

  const retainCheckboxState = () => {
    let initialSelectedRows = {}
    Object.keys(tableInstance.state.selectedRowIds).forEach((item, index) => {
      initialSelectedRows[`${item}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
  }

  // below code is for optimiztion

  useEffect(() => {
    console.log(selectedArticleWithData,selectedArticle?.value,articleOptions,'selectedArticleWithData')
    if ((selectedArticle && articleOptions[0]?.value != selectedArticle?.value && !selectedArticleWithData.includes(selectedArticle?.value))) {
      props.fetchStoreTableData(storeRequest.filter(val => val.Product_Code == selectedArticle?.value));
      props.fetchDcTableData(dcRequest.filter(val => val.Product_Code == selectedArticle?.value));
      setSelectedArticleWithData(old => [...old, selectedArticle?.value])
      setSaveIsDisabled(true);
    }
  }, [selectedArticle])

  useEffect(() => {
    console.log(articleStoreMapping, intitalSelectedStoreRows, 'sdcdscs')
    console.log(selectedArticleWithData,'selectedArticleWithData')
  },[selectedArticleWithData])

  useEffect(() => {
  }, [articleStoreMapping, intitalSelectedStoreRows])

  useEffect(() => {
    if (storeTableDataCopy?.[selectedArticle?.value]) {
      let totalEstimatedDemad = 0
      storeTableDataCopy[selectedArticle?.value].forEach(val => {
        totalEstimatedDemad += Number(val.estimated_demand)
      })
      setTotalEstimatedDemad(totalEstimatedDemad)
    }
  }, [storeTableDataCopy, selectedArticle])

  // useEffect(()=> {
  //   console.log(props.min_stock_1,myInstance,'kdfjdsfjn')
  //   myInstance?.updateMyData(myInstance.rowIdx, "min_stock", props?.min_stock_1?.min_stock)
  // },[props.min_stock_1])

  // useEffect(() => {
  //   if(selectedArticleWithData.reduce((a, v) => (v == selectedArticle ? a + 1 : a), 0) == 1) {
  //     props.fetchStoreTableData(storeRequest.filter(val => val.Product_Code == selectedArticle?.value));
  //     props.fetchDcTableData(dcRequest.filter(val => val.Product_Code == selectedArticle?.value));
  //   }
  // },[selectedArticleWithData])

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if(split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",")
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        var id = p_term.split(split_type ? split_type : " ")
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {

          l_searchTermReq[p_id] = {
            "type": p_type,
            "values": id,
            "search": p_term
          }
        }
        return {
          ...l_searchTermReq
        }
      })
    }
    else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        let min = p_term[0] == "" ? null : p_term[0]
        let max = p_term[1] == "" ? null : p_term[1]
        if (min == null && max == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {
          l_searchTermReq[p_id] = {
            "type": p_type,
            "value": {
              "min": min,
              "max": max,
            }
          }
        }
        return {
          ...l_searchTermReq,
        }
      })
    }
    else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old)
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
        }
        else {
          l_searchTermReq[p_id] = {
            "type": p_type,
            "value": p_term
          }
        }
        return {
          ...l_searchTermReq,
        }
      })
    }
    isFirstCall && setisFirstCall(false)
    // setUpdatedArticles([])
    // setIsDisabled((prev) => {
    //   if (!prev) {
    //     return true;
    //   }
    // });
  }

  useEffect(() => {
    if(tableInstance) {
      let l_tableData = cloneDeep(tableInstance)
      let uniqUpdatedArticles = [...new Set(updatedArticles)]; 
      let l_updatedRows = l_tableData?.data?.filter(val => uniqUpdatedArticles.includes(val.article)) || []
      let l_finalUpdatedRows = getRecentUpdatesOnArticle(updatedRows,l_updatedRows)
      setUpdatedRows(l_finalUpdatedRows)
      if(userEditAfterValidationOnRest){
        let uniqUpdatedArticles = [...new Set(updatedArticlesForRest)]; 
        let l_updatedRows = l_tableData?.data?.filter(val => uniqUpdatedArticles.includes(val.article)) || []
        let l_finalUpdatedRows = getRecentUpdatesOnArticle(updatedRowsForRest,l_updatedRows)
        setUpdatedRowsForRest(l_finalUpdatedRows)
      }
      if((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(selectedFilters)){
        props.resetStyleIndex();
        props.resetStyleIndexForCA();
        callCheckAllApi && setCallCheckAllApi(false);
        setTableData([])
        setTableInstance(null);
        setInitialIndex(0);
        setPrevIndex(0);
        setPageIndex(0);
        setInitialSelectedRows({})
        props.fetchStrategyTableData({...selectedFilters,"styleIndex": 0,"searchColumns":searchTermReq,"sortColumn":sortReq,"set_all":checkAllSetAllReq,"article":stylesFromDashboard});
      }
    }
  },[searchTermReq,sortReq])

  useEffect(() => {
      console.log(checkAll,checkedRows,'checkAllCopy')
  },[checkAll,checkedRows])


  const updateSetAll = (value) => {
    let l_newAction = {
      "searchTermReq": searchTermReq
    }
    if(!value.checked){
      setPrevAction("checkAll")
      userEditAfterValidationOnRest && setPrevActionForRest("checkAll")
      l_newAction["checkAll"] = true

    }
    else{
      setPrevAction("unCheckAll")
      userEditAfterValidationOnRest && setPrevActionForRest("unCheckAll")
      l_newAction["unCheckAll"] = true
      let l_updatedCheckedRows =  removeCheckedArticles(checkedRows,searchTermReq)
      setCheckedRows(l_updatedCheckedRows)
      if(userEditAfterValidationOnRest){
        let l_updatedCheckedRows =  removeCheckedArticles(checkedRowsForRest,searchTermReq)
        setCheckedRowsForRest(l_updatedCheckedRows)
      }
    }
    setCheckAll((old) => [...old,l_newAction])
    if(userEditAfterValidationOnRest){
      setCheckAllForRest((old) => [...old,l_newAction])
    }
  }

  const updateCheckedRows = (value) => {
    let l_newAction = {}, l_article = value.instance.article

    if(!value.checked){
      setPrevAction("checkRow")
      userEditAfterValidationOnRest && setPrevActionForRest("checkRow")
      if(prevAction === "checkRow") {
        let checkAllCopy = [...checkAll]
        let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length -1
        let l_checekdRows = checkAllCopy[l_lastValueIndex]
        l_checekdRows["checkedRows"].push(l_article)
        checkAllCopy.pop()
        setCheckAll([...checkAllCopy,l_checekdRows]) 
      }
      else {
        l_newAction["checkedRows"] = [l_article]
        setCheckAll((old) => [...old,l_newAction])
      }
      if(userEditAfterValidationOnRest) {
        if(prevActionForRest === "checkRow"){
          let checkAllForRestCopy = [...checkAllForRest]
          let l_lastValueIndex = (checkAllForRestCopy.length - 1 === -1) ? 0 : checkAllForRestCopy.length -1
          let l_checekdRows = checkAllForRestCopy[l_lastValueIndex]
          console.log(checkAllForRestCopy,l_lastValueIndex,l_checekdRows,'l_checekdRows')
          l_checekdRows["checkedRows"].push(l_article)
          checkAllForRestCopy.pop()
          setCheckAllForRest([...checkAllForRestCopy,l_checekdRows]) 
        }
        else{
          l_newAction["checkedRows"] = [l_article]
          setCheckAllForRest((old) => [...old,l_newAction])
        }
      }
      let l_checkedRow = {[l_article]: value.instance}
      setCheckedRows((old) => {
        return {
          ...old,
          ...l_checkedRow
        }})
      if(userEditAfterValidationOnRest){
        setCheckedRowsForRest((old) => {
          return {
            ...old,
            ...l_checkedRow
          }})      
      }
    }
    else{
      setPrevAction("unCheckRow")
      userEditAfterValidationOnRest && setPrevActionForRest("unCheckRow")
      if(prevAction === "unCheckRow") {
        let checkAllCopy = [...checkAll]
        let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length -1
        let l_checekdRows = checkAllCopy[l_lastValueIndex]
        l_checekdRows["unCheckedRows"].push(l_article)
        checkAllCopy.pop()
        setCheckAll([...checkAllCopy,l_checekdRows]) 
      }
      else {
        l_newAction["unCheckedRows"] = [l_article]
        setCheckAll((old) => [...old,l_newAction])
      }
      if(userEditAfterValidationOnRest) {
        if(prevActionForRest === "unCheckRow"){
          let checkAllForRestCopy = [...checkAllForRest]
          let l_lastValueIndex = (checkAllForRestCopy.length - 1 === -1) ? 0 : checkAllForRestCopy.length -1
          let l_checekdRows = checkAllForRestCopy[l_lastValueIndex]
          l_checekdRows["unCheckedRows"].push(l_article)
          checkAllForRestCopy.pop()
          setCheckAllForRest([...checkAllForRestCopy,l_checekdRows]) 
        }
        else {
          l_newAction["unCheckedRows"] = [l_article]
          setCheckAllForRest((old) => [...old,l_newAction])
        }
      }

      let l_checkedRow = cloneDeep(checkedRows)
      delete l_checkedRow[l_article]
      setCheckedRows((old) => {
        return {
          ...l_checkedRow
        }})
      if(userEditAfterValidationOnRest){
        let l_checkedRow = cloneDeep(checkedRowsForRest)
        delete l_checkedRow[l_article]
        setCheckedRowsForRest((old) => {
          return {
            ...l_checkedRow
          }})
      }
    }
  }

  const unCheckRowsOnError = (p_articlesWithZeroInv,p_forRest = false) => {

    let l_selectedRows = (getNewArticleToBeUnchecked(tableInstance?.rowsById, p_articlesWithZeroInv, tableInstance?.state.selectedRowIds))
    tableInstance.state.selectedRowIds = l_selectedRows
    setInitialSelectedRows(l_selectedRows)
    let l_newAction = {}, l_article = p_articlesWithZeroInv
    
    if(!isEmpty(checkAllForRest)){
      let l_newAction = {}
      l_newAction["unCheckedRows"] = [...l_article]
      setCheckAllForRest((old) => [...old,l_newAction])
    }
    setPrevAction("unCheckRow")
    userEditAfterValidationOnRest && setPrevActionForRest("unCheckRow")
    if(prevAction === "unCheckRow") {
      let checkAllCopy = [...checkAll]
      let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length -1
      let l_checekdRows = checkAllCopy[l_lastValueIndex]
      l_checekdRows["unCheckedRows"].push(...l_article)
      checkAllCopy.pop()
      setCheckAll([...checkAllCopy,l_checekdRows]) 
    }
    else {
      l_newAction["unCheckedRows"] = [...l_article]
      setCheckAll((old) => [...old,l_newAction])
    }
    let l_checkedRow = cloneDeep(checkedRows)
    l_article.forEach(val => {
      delete l_checkedRow[val]
    })
    setCheckedRows((old) => {
      return {
        ...l_checkedRow
      }})
      console.log(storeRequest,dcRequest,articleOptions,articles,'cdvcdvevfr')

      if(p_forRest) {
        let l_storeRequest = cloneDeep(storeRequest)
        l_storeRequest = l_storeRequest.filter(val => !l_article.includes(val.Product_Code))


        let l_dcRequest = cloneDeep(dcRequest)
        l_dcRequest = l_dcRequest.filter(val => !l_article.includes(val.Product_Code))


        let l_articles = cloneDeep(articles)
        l_article.forEach(val => {
          delete l_articles[val]
        })

        let l_articleOptions = cloneDeep(articleOptions)
        l_articleOptions = l_articleOptions.filter(val => !l_article.includes(val.value))

        setStoreRequest(l_storeRequest)
        setDcRequest(l_dcRequest)
        setArticles(l_articles)
        setArticleOptions(l_articleOptions)
        
      }
  }

  const updatedArticlesSetter = (p_article) => {
    if(userEditAfterValidationOnRest){
      setUpdatedArticlesForRest((old) => [...old,p_article])
    }
    setUpdatedArticles((old) => [...old,p_article])
  }

  // Col defs
  const cols = [
    {
      Header: "Product Type",
      accessor: "product_type",
      sticky: "left",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.product_type?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ),
      // Filter: (instance) => <ColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      width: 110,
      disableSortBy: true
    },
    {
      Header: `${updateArticleName(false)} ID`,
      accessor: "article",
      sticky: "left",
      Filter: (instance) => <ColumnFilterServerSide placeholder={"Search in bulk by ','"} {...instance} searchTermValue= {searchTermReq?.article?.search} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"array", ",")}/>,
      Cell: ({ value, row }) =>
        (failedArticleId?.includes(value)) ? (
          <span style={{ color: "red" }} >{value}</span>
        ) : value,
      width: 150,
      disableSortBy: true,
      filter: 'bulkFilterCommaSeperated',
    },
    {
      Header: 'Style ID',
      accessor: "style_code",
      sticky: "left",
      disableSortBy: true,
      Filter: (instance) => <ColumnFilterServerSide placeholder="Search in bulk.." {...instance} searchTermValue= {searchTermReq?.style_code?.search} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"array")}/>,
    },
    {
      Header: 'Color ID',
      accessor: "color_code",
      sticky: "left",
      disableSortBy: true,
      Filter: (instance) => <ColumnFilterServerSide placeholder="Search in bulk.." {...instance} searchTermValue= {searchTermReq?.color_code?.search} changeHandler={(term,id) => fecthDataWithSearchTerm(term,id,"array")}/>,
    },
    {
      Header: "Style Description",
      accessor: "style_name",
      // sticky: "left",
      width: 250,
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ),
      disableSortBy: true
    },
    {
      Header: "Style Color",
      accessor: "color_desc",
      // sticky: "left",
      width: 180,
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ),  
      disableSortBy: true  
    },
    {
      Header: levelFiltersLabelFormatter("level1"),
      accessor: "l1_name",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l1_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true
    },
    {
      Header: levelFiltersLabelFormatter("level2"),
      accessor: "l2_name",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true
    },
    {
      Header: levelFiltersLabelFormatter("level3"),
      accessor: "l3_name",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true
    },
    {
      Header: levelFiltersLabelFormatter("level4"),
      accessor: "l4_name",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true
    },
    {
      Header: levelFiltersLabelFormatter("level5"),
      accessor: "l5_name",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l5_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true    
    },
    {
      Header: levelFiltersLabelFormatter("level6"),
      accessor: "l6_name",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l6_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true  
    },
    {
      Header: levelFiltersLabelFormatter("level7"),
      accessor: "l7_name",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l7_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true  
    },
    {
      Header: "Assortment Indicator",
      accessor: "assortment_indicator",
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.assortment_indicator?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ),
      disableSortBy: true,
    },
    {
      Header: "LW Sales Units",
      accessor: "lw_qty",
      Cell: (instance) => (
          <div>
              {instance?.row?.original.lw_qty !== null ? numberUSFormatting(instance?.row?.original.lw_qty): "-"}   
          </div>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "WTD Sales Units",
      accessor: "tw_qty",
      Cell: (instance) => (
          <div>
              {instance?.row?.original.tw_qty !== null ? numberUSFormatting(instance?.row?.original.tw_qty): "-"}   
          </div>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "Introduction Date",
      accessor: "intro_date",
      Cell: (instance) => (
          <div>
              {instance?.row?.original.intro_date !== null ? moment(instance?.row?.original?.intro_date).format("MM-DD-YYYY"): "-"}   
          </div>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
        Header: "Last Allocated",
        accessor: "max_allocation_date",
        Cell: (instance) => (
            <div>
                {instance?.row?.original.max_allocation_date !== null ? moment(instance?.row?.original?.max_allocation_date).format("MM-DD-YYYY"): "-"}   
            </div>
        ),
        disableSortBy: true,
        disableFilters: true,
    },
    {
      Header: "Factory Type",
      accessor: "factory_type",
      width:250,
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.factory_type?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ),
      Cell: (instance) => (
          <div>
              {instance?.row?.original.factory_type !== null ? instance?.row?.original?.factory_type: ""}   
          </div>
      ),
      disableSortBy: true,
  },
    {
      Header: "Demand Type",
      accessor: "Demand_Type_Filter_Default",
      Cell: (instance) => <SelectCell {...instance}
        dropdownOptions={tableData[instance.row.index]?.Demand_Type_Filter}
        changeHandler={(rowIdx, columnId, values) => {
          updatedArticlesSetter(tableData[rowIdx].article)
          // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
        }} 
      />,
      disableFilters: true,
      // Filter: (instance) => (
      //   <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.Demand_Type_Filter_Default?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      // ), 
      filter: 'filterSingleSelectCell',
      disableSortBy: true
    },
    {
      Header: "Planned APS",
      accessor: "article_aps",
      Cell: (instance) => ((instance.row.original?.Demand_Type_Filter_Default?.[0]?.value == "APS" || instance.row.original?.Demand_Type_Filter_Default?.value == "APS") ?
        <InputCell {...instance} 
        debouncedChangeHandler={(rowIdx, columnId, values) => {
          console.log(tableData[rowIdx],'kgjtyy')
          updatedArticlesSetter(tableData[rowIdx].article)
          // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
        }}
        style={{ textAlign: "center" }} min={0} type="number" /> : (instance.row.original?.article_aps != undefined ? instance.row.original?.article_aps : '')
      ),
      // Filter: (instance) => (
      //                   <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.article_aps?.value?.min} maxSearchTermValue= {searchTermReq?.article_aps?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
      //                 ),      
      //                 filter: 'between',
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Average WOS",
      accessor: "article_wos",
      // Cell: (instance) => <InputCell {...instance} 
      // debouncedChangeHandler={(rowIdx, columnId, values) => {
      //   updatedArticlesSetter(tableData[rowIdx].article)
      //   // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
      // }}
      // style={{ textAlign: "center" }} min={0} type="number" />,
      // Filter: (instance) => (
      //                   <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.article_wos?.value?.min} maxSearchTermValue= {searchTermReq?.article_wos?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
      //                 ),      
      //                 filter: 'between',
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Product Profile",
      accessor: "product_profile",
      Cell: (instance) => <div className={StrategyCSS.multiselectDiv}>
        <SelectCell
          dropdownOptions={tableData[instance.row.index]?.product_profiles}
          {...instance}
          changeHandler={(rowIdx, columnId, values) => {
            updatedArticlesSetter(tableData[rowIdx].article)
            // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
          }} />
      </div>,
      disableFilters: true,
      width: 180,
      disableSortBy: true
    },
    {
      Header: "Size Profile",
      accessor: "size_desc",
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.size_options}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              let sizes = values?.map(val => val?.value)
              const callApi = async (req, apsReq) => {
                updatedArticlesSetter(tableData[rowIdx].article)
                // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
                retainCheckboxState()
                setInitialIndex(Math.floor(rowIdx / recordsPerPage))
                try {
                  const [dcResponse, apsResponse] = await Promise.all([
                    // Api.post(GET_DC_DATA, req),
                    getInvComponent(req),
                    Api.post(GET_APS, { data: [apsReq] })
                  ]);
                  // let res = await Api.post(GET_SIZE_DC, req)
                  let data = await dcResponse
                  let apsData = await apsResponse?.data?.data?.[0]?.aps
                  let wosData = await apsResponse?.data?.data?.[0]?.wos
                  let total_inventory
                  let inventoryAvailable = 0;
                  // Because there can be multiple inventory source need to calculate the sum for all
                  tableData[instance.row.index]?.Inventory_Source_Filter?.map( invSource => {
                    inventoryAvailable += Number(data?.[`${invSource?.value}_map`])
                  })
                  if(isAshley()){
                    total_inventory = Math.max( Number(data?.oh_map) - (Number(data?.po_map) ? Number(data?.po_map) : 0) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  else {
                    total_inventory = Math.max(Number(data?.it_map) + Number(data?.oh_map) + Number(data?.oo_map) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  instance.updateMyData(rowIdx, "default_inventory_sorce_filter", tableData[instance.row.index]?.default_inventory_source)
                  instance.updateMyData(rowIdx, "it", data?.it_map ? data?.it_map : 0);
                  instance.updateMyData(rowIdx, "oh", inventoryAvailable ? inventoryAvailable : 0);
                  instance.updateMyData(rowIdx, "oo", data?.oo_map ? data?.oo_map : 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", data?.rq_map ? data?.rq_map : 0);
                  instance.updateMyData(rowIdx, "total_inventory", total_inventory ? total_inventory : 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
                  instance.updateMyData(rowIdx, "article_aps", apsData ? apsData : 0);
                  instance.updateMyData(rowIdx, "article_wos", wosData ? wosData : 0);
                  instance.updateMyData(rowIdx, "allocated_units", data?.au_map ? data?.au_map : 0);
                }
                catch (e) {
                  instance.updateMyData(rowIdx, "it", 0);
                  instance.updateMyData(rowIdx, "oh", 0);
                  instance.updateMyData(rowIdx, "oo", 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", 0);
                  instance.updateMyData(rowIdx, "total_inventory", 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
                  instance.updateMyData(rowIdx, "article_aps", 0);
                  instance.updateMyData(rowIdx, "article_wos", 0);
                  instance.updateMyData(rowIdx, "allocated_units", 0);
                }
              }
              if (sizes.length) {
                let sizeValues = values?.map(val => val.value)
                let req = {
                  "size": sizeValues,
                  "store_code": instance.row.original?.default_dcs?.map(val => val.value),
                  "inventory_source": instance.row.original?.default_inventory_sorce_filter,
                  "data": tableData[rowIdx],
                }
                let plannedApsReq = {
                  "size": sizeValues,
                  "article": [tableData[rowIdx].article],
                  "upc": getUPC(tableData[rowIdx].upcSizeMapping, sizeValues),
                  "store_group_code": instance.row.original?.default_store_eligibility_default?.map(val => val.value)
                }
                callApi(req, plannedApsReq);
              }
              else {
                instance.updateMyData(rowIdx, "it", 0);
                instance.updateMyData(rowIdx, "oh", 0);
                instance.updateMyData(rowIdx, "oo", 0);
                instance.updateMyData(rowIdx, "reserve_quantity", 0);
                instance.updateMyData(rowIdx, "total_inventory", 0);
                instance.updateMyData(rowIdx, "article_aps", 0);
                instance.updateMyData(rowIdx, "article_wos", 0);
                instance.updateMyData(rowIdx, "final_total_inventory", '-');
                instance.updateMyData(rowIdx, "edit_total_inventory", '');
                instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
                instance.updateMyData(rowIdx, "allocated_units", 0);
              }
            }}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Store Eligibility Groups",
      accessor: "default_store_eligibility_default",
      width: 250,
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.store_groups}
            // defaultOptions={tableData[instance.row.index]?.store_groups}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              updatedArticlesSetter(tableData[rowIdx].article)
              // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
              let storeGroupCodes = values?.map(val => val?.value)
              const callApi = async (req, apsReq) => {
                retainCheckboxState()
                setInitialIndex(Math.floor(rowIdx / recordsPerPage))
                try {

                  const [minStockResponse, apsResponse] = await Promise.all([
                    Api.post(GET_STORE_GROUP_MIN_STOCK, req),
                    Api.post(GET_APS, { data: [apsReq] })
                  ]);
                  let data = await minStockResponse?.data?.data?.[0]
                  let apsData = await apsResponse?.data?.data?.[0]?.aps
                  let wosData = await apsResponse?.data?.data?.[0]?.wos

                  // let res = await Api.post(GET_STORE_GROUP_MIN_STOCK, req);
                  instance.updateMyData(rowIdx, "min_stock", data?.min_stock ? data?.min_stock : 0);
                  // instance.updateMyData(rowIdx, "delivery_date", data?.transit_time ? new Date(Date.now() + data?.transit_time * 24 * 60 * 60 * 1000) : new Date(Date.now()));
                  instance.updateMyData(rowIdx, "article_aps", apsData ? apsData : 0);
                  instance.updateMyData(rowIdx, "article_wos", wosData ? wosData : 0);
                }
                catch {
                  instance.updateMyData(rowIdx, "min_stock", 0);
                  instance.updateMyData(rowIdx, "article_aps", 0);
                  instance.updateMyData(rowIdx, "article_wos", 0);
                }
              }
              if (storeGroupCodes.length) {
                let sizeValues = instance.row.original?.size_desc?.map(val => val.value)
                let req = {
                  "store_group_code": values?.map(val => val?.value),
                  "article": [tableData[rowIdx].article],
                }
                let plannedApsReq = {
                  "store_group_code": values?.map(val => val?.value),
                  "article": [tableData[rowIdx].article],
                  "size": sizeValues,
                  "upc": getUPC(tableData[rowIdx].upcSizeMapping, sizeValues),
                }
                callApi(req, plannedApsReq);
              }
              else {
                instance.updateMyData(rowIdx, "min_stock", 0);
                instance.updateMyData(rowIdx, "article_aps", 0);
                instance.updateMyData(rowIdx, "article_wos", 0);
              }
              // let total = 0;
              // values.forEach((group) => {
              //   total += tableData[rowIdx].min_stock_obj[group?.value];
              // });
              let checkedStoreGroupCode = values?.map(val => val?.value)
              let noOfStores = getNoofStores(checkedStoreGroupCode)
              // let minPresQty = getMinPresQty(tableData[rowIdx].article)
              instance.updateMyData(rowIdx, "no_of_stores", noOfStores)
              // instance.updateMyData(rowIdx, "min_stock", total);
              // instance.updateMyData(rowIdx, "min_pres_qty", minPresQty);
            }}
          />
        </div>
      ),
      // disableFilters: true
      // Filter: (instance) => <SelectColumnFilter {...instance} />,
      // filter: 'filterMultiSelectCell',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "DC",
      accessor: "default_dcs",
      width: 250,
      Cell: (instance) => (
        <div className={StrategyCSS.multiselectDiv}>
          <MultiSelectCell
            {...instance}
            dropdownOptions={tableData[instance.row.index]?.dcs}
            onBlurEvent={(rowIdx, columnId, values, article) => {
              updatedArticlesSetter(tableData[rowIdx].article)
              // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
              let dcStores = values?.map(val => val?.value)
              const callApi = async (req) => {
                // retainCheckboxState()
                // setInitialIndex(Math.floor(rowIdx / recordsPerPage))
                try {
                  // let res = await Api.post(GET_DC_DATA, req);
                  let data = getInvComponent(req)
                  let total_inventory
                  let inventoryAvailable = 0;
                  // Because there can be multiple inventory source need to calculate the sum for all
                  let inv_sources = [];
                  tableData[instance.row.index]?.default_inventory_source?.map( invSource => {
                    inv_sources.push(`${invSource?.value}_map`)
                    inventoryAvailable += Number(data?.[`${invSource?.value}_map`])
                  })
                  if(isAshley()){
                    total_inventory = Math.max( Number(data?.oh_map) - (Number(data?.po_map) ? Number(data?.po_map) : 0) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  else {
                    let sources_sum = 0;
                    inv_sources?.forEach(inv => {
                      sources_sum += Number(data?.[inv]);
                    })
                    total_inventory = Math.max(sources_sum + Number(data?.oo_map) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
                  }
                  const { ns_map, size_desc } = instance?.row?.original;
                  // const newStoreReserved = calculateNewStoreReserved(ns_map, dcStores, size_desc.map(s => s.value));
                  instance.updateMyData(rowIdx, "default_inventory_sorce_filter", tableData[instance.row.index]?.default_inventory_source)
                  instance.updateMyData(rowIdx, "it", data?.it_map ? data?.it_map : 0);
                  instance.updateMyData(rowIdx, "oh", inventoryAvailable ? inventoryAvailable : 0);
                  instance.updateMyData(rowIdx, "oo", data?.oo_map ? data?.oo_map : 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", data?.rq_map ? data?.rq_map : 0);
                  instance.updateMyData(rowIdx, "total_inventory", total_inventory ? total_inventory : 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
                  instance.updateMyData(rowIdx, "allocated_units", data?.au_map ? data?.au_map : 0);
                }
                catch (e) {
                  instance.updateMyData(rowIdx, "it", 0);
                  instance.updateMyData(rowIdx, "oh", 0);
                  instance.updateMyData(rowIdx, "oo", 0);
                  instance.updateMyData(rowIdx, "reserve_quantity", 0);
                  instance.updateMyData(rowIdx, "total_inventory", 0);
                  instance.updateMyData(rowIdx, "final_total_inventory", '-');
                  instance.updateMyData(rowIdx, "edit_total_inventory", '');
                  instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
                  instance.updateMyData(rowIdx, "allocated_units", 0);
                }
              }
              if (dcStores.length) {
                let req = {
                  "store_code": values?.map(val => val?.value),
                  "data": tableData[rowIdx],
                  "size": instance.row.original?.size_desc?.map(val => val.value),
                  "inventory_source": instance.row.original?.default_inventory_sorce_filter, 
                }
                callApi(req);
              }
              else {
                instance.updateMyData(rowIdx, "it", 0);
                instance.updateMyData(rowIdx, "oh", 0);
                instance.updateMyData(rowIdx, "oo", 0);
                instance.updateMyData(rowIdx, "reserve_quantity", 0);
                instance.updateMyData(rowIdx, "total_inventory", 0);
                instance.updateMyData(rowIdx, "final_total_inventory", '-');
                instance.updateMyData(rowIdx, "edit_total_inventory", '');
                instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
                instance.updateMyData(rowIdx, "allocated_units", 0);
              }
            }}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true
      
    },
    {
      Header: "No. Of Stores",
      accessor: "no_of_stores",
      disableFilters: true,
      disableSortBy: true
    },
    // {
    //   Header: "Phase In Date",
    //   accessor: "phase_in_date",
    //   Cell: (instance) => (
    //     <div id="phase_in_date">
    //       <DatePicker {...instance} domId={"phase_in_date"} />
    //     </div>
    //   ),
    //   disableFilters: true
    // },
    // {
    //   Header: "Phase Out Date",
    //   accessor: "phase_out_date",
    //   Cell: (instance) => <DatePicker {...instance} />,
    //   disableFilters: true
    // },
    // {
    //   Header: "Delivery Date",
    //   accessor: "delivery_date",
    //   Cell: (instance) => <DatePicker {...instance} />,
    //   disableFilters: true
    // },
    {
      Header: "Inventory Available",
      accessor: "oh",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.oh?.value?.min} maxSearchTermValue= {searchTermReq?.oh?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
        ),      
        filter: 'between',
        disableSortBy: true
    },
    // {
    //   Header: "DC inv. On Order",
    //   accessor: "oo",
    //   Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    //   filter: 'between',
    // },
    // {
    //   Header: "DC inv. in Transit",
    //   accessor: "it",
    //   Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
    //   filter: 'between',
    // },
    {
      Header: "User Reserved",
      accessor: "reserve_quantity",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.reserve_quantity?.value?.min} maxSearchTermValue= {searchTermReq?.reserve_quantity?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
      ),        
      filter: 'between',
      disableSortBy: true
    },
    {
      Header: "PO/ASN Reserved",
      accessor: "po_reserve",
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.po_reserve?.value?.min} maxSearchTermValue= {searchTermReq?.po_reserve?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
      ),        
      filter: 'between',
      disableSortBy: true
    },
    {
      Header: "New Store Reserved",
      accessor: "new_store_reserve",
      Filter: (instance) => (
        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.new_store_reserve?.value?.min} maxSearchTermValue= {searchTermReq?.new_store_reserve?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
      ),        
      filter: 'between',
      disableSortBy: true
    },
    {
      Header: "Allocated Units",
      accessor: "allocated_units",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.allocated_units?.value?.min} maxSearchTermValue= {searchTermReq?.allocated_units?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
                      ),      
      filter: 'between',
      disableSortBy: true
    },
    {
      Header: "Inv. Source",
      accessor: "default_inventory_sorce_filter",
      Cell: (instance) => <div className={StrategyCSS.multiselectDiv}>
        <MultiSelectCell
          isDisabled = {isAshley()}
          {...instance}
          dropdownOptions={tableData[instance.row.index]?.Inventory_Source_Filter}
          onBlurEvent={(rowIdx, columnId, values, article) => {           
            updatedArticlesSetter(tableData[rowIdx].article)
            const callApi = async (req, apsReq) => {
              updatedArticlesSetter(tableData[rowIdx].article)
              retainCheckboxState()
              setInitialIndex(Math.floor(rowIdx / recordsPerPage))
              try {
                const [dcResponse] = await Promise.all([
                  getInvComponent(req),
                ]);
                let data = await dcResponse
                let invSorce = 0, oh =0;
                // Because there can be multiple inventory source need to calculate the sum for all
                forEach(values, item => {
                  invSorce += Number(data?.[`${item?.value}_map`])
                  oh += Number(data?.[`${item?.value}_map`])
                })
                oh = Math.max(oh, 0)
                instance.updateMyData(rowIdx, "oh", oh)
                instance.updateMyData(rowIdx, "final_total_inventory", '-')
                instance.updateMyData(rowIdx, "edit_total_inventory", '');
                instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
                instance.updateMyData(rowIdx, "reserve_quantity", data?.rq_map ? data?.rq_map : 0);
                instance.updateMyData(rowIdx, "allocated_units", data?.au_map ? data?.au_map : 0);
                invSorce -= data?.rq_map || 0
                invSorce -= data?.au_map || 0
                invSorce = Math.max(invSorce, 0)
                instance.updateMyData(rowIdx, "total_inventory", invSorce)
              }
              catch (e) {
                instance.updateMyData(rowIdx, "reserve_quantity", 0);
                instance.updateMyData(rowIdx, "allocated_units", 0);
                instance.updateMyData(rowIdx, "total_inventory", 0);
              }
            }
            
            let sizeValues = instance.row.original?.size_desc?.map(val => val.value)
            let req = {
              "inventory_source": values,
              "size": sizeValues,
              "store_code": instance.row.original?.default_dcs?.map(val => val.value),
              "data": tableData[rowIdx],
            }
            callApi(req);
          }}
          // onDropDownChange={(rowIdx, columnId, values, article) => {
          //   let invSorce = 0;
          //   values.forEach((group) => {
          //     invSorce += Number(tableData[rowIdx][group?.value]);
          //   });
          //   invSorce -= tableData[rowIdx].reserve_quantity
          //   invSorce -= tableData[rowIdx].allocated_units
          //   invSorce = Math.max(invSorce, 0)
          //   instance.updateMyData(rowIdx, "total_inventory", invSorce)
          //   instance.updateMyData(rowIdx, "final_total_inventory", '-')
          //   instance.updateMyData(rowIdx, "edit_total_inventory", '');
          //   instance.updateMyData(rowIdx, "edit_total_inventory_units", '');
          // }}
        />
      </div>,
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Net Available Inv.",
      accessor: "total_inventory",
      Cell: (inst) => numberUSFormatting(inst?.value),
      Filter: (instance) => (
                        <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.total_inventory?.value?.min} maxSearchTermValue= {searchTermReq?.total_inventory?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
                      ),      
                      filter: 'between',
                      disableSortBy: true
    },
    {
      Header: "User defined Inv in %",
      accessor: "edit_total_inventory",
      width: 180,
      Cell: (instance) => (
        <InputCell {...instance} roundOffOnChange min={0} max={isPercent ? 100 : instance.row.original['total_inventory']} type="number"
          debouncedChangeHandler={(rowIdx, columnId, values) => { 
            updatedArticlesSetter(tableData[rowIdx].article)
            // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
          }}
          changeHandler={(rowIdx, columnId, values) => {
            let final_total_inventory = values != 0 ? Math.round(values * Number(instance.row.original['total_inventory']) / 100) : '-'
            instance.updateMyData(rowIdx, 'final_total_inventory', final_total_inventory);
            instance.updateMyData(rowIdx, 'edit_total_inventory_units','');
          }}
        />
      ),
      // Filter: (instance) => (
      //   <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.edit_total_inventory?.value?.min} maxSearchTermValue= {searchTermReq?.edit_total_inventory?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
      // ),
      // // Filter: (instance) => <NumberRangeColumnFilter {...instance} resetInitalPageIndex={(index) => { setInitialIndex(index) }} />,
      // filter: 'between',
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "User defined Inv in Units",
      accessor: "edit_total_inventory_units",
      width: 180,
      Cell: (instance) => (
        <InputCell {...instance} roundOffOnChange min={0} max={instance.row.original['total_inventory']} type="number"
          debouncedChangeHandler={(rowIdx, columnId, values) => { 
            updatedArticlesSetter(tableData[rowIdx].article)
            // setUpdatedArticles((old) => [...old,tableData[rowIdx].article])
          }}
          changeHandler={(rowIdx, columnId, values) => {
            let final_total_inventory = values != 0 ? values : '-'
            instance.updateMyData(rowIdx, 'final_total_inventory', final_total_inventory);
            instance.updateMyData(rowIdx, 'edit_total_inventory','');
          }}
        />
      ),
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "Final Net Available Inv.",
      accessor: "final_total_inventory",
      // Cell: (inst) => numberUSFormatting(inst?.value),
      width: 180,
      Filter: (instance) => (
        <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.final_total_inventory?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
      ), 
      disableSortBy: true
    },
    // {
    //   Header: "Min Presentation Quantity",
    //   accessor: "min_pres_qty",
    //   Filter: ColumnFilter
    // },
    {
      Header: "Safety Stock at Store",
      accessor: "min_stock",
      Filter: (instance) => (
                  <NumberRangeColumnFilterServerSide  {...instance} minSearchTermValue= {searchTermReq?.min_stock?.value?.min} maxSearchTermValue= {searchTermReq?.min_stock?.value?.max}  clickHandler={(min,max,id) => fecthDataWithSearchTerm([min,max],id,"range")}/>
                ),      
                filter: 'between',
                disableSortBy: true
    },
  ];

  const cellRenderer = (inst, classname, multiply = null) => {
    let storeFlagClassName = inst.row.original.delta_store_flag ? StrategyCSS.storeFlagInfoCol : ''
    if (multiply) {
      return (<div className={classnames(StrategyCSS.cell, classname, storeFlagClassName)}>{(inst?.value * multiply).toFixed(1) + ' %'}</div>)
    }
    else {
      return (<div className={classnames(StrategyCSS.cell, classname, storeFlagClassName)}>{inst?.value}</div>)
    }

  };

  const setSortByHelperFunction = (p_instance) => {
    let overall = p_instance?.allColumns.filter(val => val.id === "overall_proportion_rounded")
    if (overall.length) {
      if (!overall[0].isSorted) {
        setSortBy([])
      }
      if (overall[0].isSortedDesc == true) {
        setSortBy([{ id: 'overall_proportion_rounded', desc: true }])
      }
      if (overall[0].isSortedDesc == false) {
        setSortBy([{ id: 'overall_proportion_rounded', desc: false }])
      }
    }
  }

  const storeDetailsCols = [
    {
      Header: 'DummyID',
      accessor: 'dummyId',
      Filter: ColumnFilter,
      sticky: "left",
    },
    {
      Header: "Store Information",
      sticky: "left",
      columns: [
        {
          Header: "Store Number",
          accessor: "store_code",
          Cell: (inst) => cellRenderer(inst, StrategyCSS.storeInfoCol),
          Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
          filter: 'bulkFilterSpaceSeperated',
        },
        {
          Header: "SAP ID",
          accessor: "sap_id",
          Cell: (inst) => cellRenderer(inst, StrategyCSS.storeInfoCol),
          Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
          filter: 'bulkFilterSpaceSeperated',
        },
        {
          Header: "Store Name",
          accessor: 'store_name',
          Cell: (inst) => cellRenderer(inst, StrategyCSS.storeInfoCol),
          Filter: ColumnFilter,
          width: 180
        },
        {
          Header: "Store Grade",
          accessor: "store_grade",
          Cell: (inst) => cellRenderer(inst, StrategyCSS.storeInfoCol),
          Filter: ColumnFilter,
          filter: 'exactText',
        },
        {
          Header: "Store Index",
          accessor: "Store_Index",
          // Cell: (instance) => (
          //   <InputCell {...instance} min={1} roundOffOnChange type="number" disabled={!editStoreIndex} 
          //       changeHandler={(rowIdx, columnId, values) => {
          //         console.log(instance,rowIdx,columnId,storeTableData,selectedArticle?.value,'dbucdb')
          //       instance.updateMyData(rowIdx, 'APS/ROS', values*Number(storeTableData[selectedArticle?.value][rowIdx]['APS/ROS']));
          //     }}
          //   />
          // ),
          Filter: ColumnFilter
        },
      ],
    },
    {
      Header: "Reference Store",
      accessor: "ref_store",
      Cell: (instance) => (
        <SelectCellComponent
          defaultValue={instance.row.original?.ref_store}
          options={refStoreDropDownOptions}
          changeHandler={(rowIdx, columnId, values) => {
            if (isReviewChangesDisabled) {
              setIsReviewChangesDisabled(false)
            }
            setStoreTableDataCopy((oldData) => {
              let newData = cloneDeep(oldData);
              newData[selectedArticle?.value][rowIdx][columnId] = values;
              return newData;
            });
          }}
          {...instance} />
      ),
      disableFilters: true
    },
    {
      Header: "Product Profile Pen %",
      columns: [
        {
          Header: "Overall",
          accessor: "overall_proportion_rounded",
          Cell: (inst) => cellRenderer(inst, StrategyCSS.productProfileCol, 1),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          sortType: "basic"
        },
      ],
    },
    {
      Header: "Constraints",
      columns: [
        {
          Header: "APS/User Defined ROS",
          accessor: "APS/ROS_rounded",
          Cell: (instance) => (
            <InputCell {...instance} min={0} type="number"
              debouncedChangeHandler={(rowIdx, columnId, values) => {
                // setSortByHelperFunction(instance)
                let l_estimatedDemand = getEstimatedDemand(instance?.data[rowIdx], columnId, values)
                instance.updateMyData(rowIdx, 'estimated_demand', l_estimatedDemand);
                setStoreTableDataCopy((oldData) => {
                  console.log('ss1',oldData)
                  let newData = cloneDeep(oldData);
                  newData[selectedArticle?.value][rowIdx][columnId] = values;
                  newData[selectedArticle?.value][rowIdx]['APS/ROS'] = Number(values);
                  // newData[selectedArticle?.value][rowIdx]['ROS_edited_flag'] = true;
                  newData[selectedArticle?.value][rowIdx]['estimated_demand'] = l_estimatedDemand;
                  return newData;
                });
              }} />
          ),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          // disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: "Min",
          accessor: "min_stock",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Max",
          accessor: "max_stock",
          Cell: (inst) => numberUSFormatting(inst?.value),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "WOS",
          accessor: "WOS_rounded",
          // id: "wos",
          Cell: (instance) => <InputCell roundOffOnChange min={0} {...instance} type="number"
            debouncedChangeHandler={(rowIdx, columnId, values) => {
              //   setSortByHelperFunction(instance)
              let l_estimatedDemand = getEstimatedDemand(instance?.data[rowIdx], columnId, values)
              instance.updateMyData(rowIdx, 'estimated_demand', l_estimatedDemand);
              setStoreTableDataCopy((oldData) => {
                let newData = cloneDeep(oldData);
                newData[selectedArticle?.value][rowIdx][columnId] = values;
                newData[selectedArticle?.value][rowIdx]['WOS'] = Number(values);
                newData[selectedArticle?.value][rowIdx]['estimated_demand'] = l_estimatedDemand;
                return newData;
              });
            }}
          />,
          Filter: NumberRangeColumnFilter,
          filter: 'between',
          // disableFilters: true,
          disableSortBy: true,
        },
        {
          Header: "Estimated Demand",
          accessor: "estimated_demand",
          Cell: (inst) => numberUSFormatting(inst?.value),
          Filter: ColumnFilter,
          disableSortBy: true,
        },
        {
          Header: "Transit Time",
          accessor: (data) => data["transit_time"].toFixed(1),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Store On Hand",
          accessor: "onhand",
          id: "oh",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Store On Order",
          accessor: "onorder",
          id: "oo",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Store In Transit",
          accessor: "intransit",
          id: "it",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "OH+IT+OO",
          accessor: (data) => (data["intransit"] + data["onhand"] + data["onorder"]),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: "Store On Hold",
          accessor: "onhold",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        }
      ],
    },
  ];

  const selectedArticleStoreTableData = () => {
    if (storeTableData[selectedArticle?.value]) {
      storeTableData[selectedArticle?.value][0].size_desc.forEach((d) => {
        storeDetailsCols[3].columns.push({
          Header: d,
          accessor: data => data[d],
          Cell: (inst) => cellRenderer(inst, StrategyCSS.productProfileCol, 1),
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        });
      });
      const data = storeTableData[selectedArticle?.value]?.map((d) => {
        d.size_desc.forEach((s, idx) => {
          d[s] = (d.size_level_proportion_rounded[idx])
          // * 100).toFixed(1);
        });
        return d;
      });
      if (!isEqual(selectedArticleStoreConst, data)) {
        console.log(data, articleStoreMapping[selectedArticle?.value], storeCode, 'd,jvbidfhvu')
        if (!isEmpty(storeCode))
          setSortBy([{ id: 'dummyId', desc: true }])
        else
          setSortBy([{ id: 'overall_proportion_rounded', desc: true }])

        if (!isEmpty(storeCode) && isEmpty(articleStoreMapping[selectedArticle?.value])) {
          let initialSelectedRows = {}
          let storeCodes = storeCode.map(val => val.value).filter(val => val != "*")
          data.forEach((item, index) => {
            if (storeCodes.includes(item.store_code)) {
              initialSelectedRows[index] = true
              data[index].dummyId = 1
            }
          })
          setIntitalSelectedStoreRows(initialSelectedRows)
        }
        else {
          if(isEmpty(articleStoreMapping[selectedArticle?.value])) {
            if(!isContinueAllocation){
              let l_initialSelectedRows = {}
              data.forEach((item, index) => {
                l_initialSelectedRows[index] = true
              })
              setIntitalSelectedStoreRows(l_initialSelectedRows)
            }
            else{
              setIntitalSelectedStoreRows({})
            }
          }
          else {
            setIntitalSelectedStoreRows(articleStoreMapping[selectedArticle?.value])
          }
          
        }
        // newUpdatedData = data?.map((d)=>({
        //   ...d,
        //   ROS_edited_flag:false
        // }));
        setSelectedArticleStoreConst(() => data);
        const refStores = data?.map((d) => ({
          label: d.store_code,
          value: d.store_code,
        }));
        setRefStoreDropDownOptions(() => refStores);
      }
      return true;
    }
    return false;
  };

  const dcTableCols = [
    {
      Header: "Inv. Source Code",
      accessor: "dc_code",
    },
    {
      Header: "Inv. Source Name",
      accessor: "dc_name",
      width: 200,
    },
    {
      Header: "Inv. Source",
      accessor: "inventory_source",
      Cell: (inst) => getInventorySourceName(inst?.value),
    },
    {
      Header: "Available Inv.",
      columns: [],
    },
    // {
    //   Header: "Single On Hand",
    //   columns: [],
    // },
    // {
    //   Header: "Single On Order",
    //   columns: [],
    // },
    // {
    //   Header: "Single In Transit",
    //   columns: [],
    // },
    {
      Header: "Quantity Reserved",
      columns: [],
    },
    {
      Header: "Allocated Units",
      columns: [],
    },
  ];

  const selectedArticleDcTableData = () => {
    if (dcTableData[selectedArticle?.value]) {
      let shouldDisable = storeRequest?.filter(val => val.Product_Code == selectedArticle?.value)?.[0]?.hasEdited
      // if(shouldDisable)
      //   setSaveIsDisabled(true)
      // else
      //   setSaveIsDisabled(false)
      dcTableData[selectedArticle?.value].sizes.forEach((d) => {
        dcTableCols[3].columns.push({
          Header: d,
          accessor: (data) => data[`Total_Available_Inventory_${d}`],
          Cell: (inst) => numberUSFormatting(inst?.value),
          width: 150,
        });

        dcTableCols[4].columns.push({
          Header: d + " ",
          width: 150,
          disableSortBy: true,
          accessor: (data) => data[d + " "] ? data[d + " "] : data[`Reserve_Quantity_${d}`],
          Cell: (instance) => {
            const row = instance.row.original;
            return row.size.includes(d) ? (
              <InputCell
                blurHandler={() => setSaveIsDisabled(false)}
                {...instance}
                disabled={shouldDisable}
                min={0}
                type="number"
                max={instance.row.original[`Total_Available_Inventory_${d}`]}
              />
            ) : (
              ""
            );
          },
        });
        
        dcTableCols[5].columns.push({
          Header: d + '  ',
          accessor: (data) => data[`Allocated_Reserve_Quantity_${d}`],
          Cell: (inst) => numberUSFormatting(inst?.value),
          width: 150,
        });
        // dcTableCols[2].columns.push({
        //   Header: d,
        //   accessor: `onorder_${d}`,
        // });
        // dcTableCols[3].columns.push({
        //   Header: d,
        //   accessor: `intransit_${d}`,
        // });
      });
      const data = dcTableData[selectedArticle?.value]?.data?.map((d) => {
        d.size.forEach((s, idx) => {
          d[`Total_Available_Inventory_${s}`] = d.Total_Available_Inventory[idx];
          d[`Reserve_Quantity_${s}`] = d.Reserve_Quantity[idx];
          d[`Allocated_Reserve_Quantity_${s}`] = d.Allocated_Reserve_Quantity[idx];
          // d[`onhand_${s}`] = d.onhand[idx];
          // d[`onorder_${s}`] = d.onorder[idx];
          // d[`intransit_${s}`] = d.intransit[idx];
        });
        return d;
      });
      if (!isEqual(selectedArticleDcData, data)) {
        setTimeout(() => {
          setDCDataLoading(false);
          setSelectedArticleDcData(() => data);
        }, 0);
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isEmpty(props?.location?.state?.filters)) {
      let filters = props?.location?.state?.filters
      let styles = props?.location?.state?.styles || []
      let dcs = props?.location?.state?.dc || []
      if(props?.location?.state?.isPOASNFlow) {
        setIsPOASNFlow(true)
        setPODetails(props?.location?.state?.poDetails)
        setPOASNHiddenColumns(["default_dcs"])
      }
      setLevel1Label(props?.location?.state?.filters?.level1Label ? props?.location?.state?.filters?.level1Label[0] : levelFiltersLabelFormatter("level1"))
      setLevel2Label(props?.location?.state?.filters?.level2Label ? props?.location?.state?.filters?.level2Label[0] : levelFiltersLabelFormatter("level2"))
      setLevel3Label(props?.location?.state?.filters?.level3Label ? props?.location?.state?.filters?.level3Label[0] : levelFiltersLabelFormatter("level3"))
      setLevel5Label(props?.location?.state?.filters?.level5Label ? props?.location?.state?.filters?.level5Label[0] : levelFiltersLabelFormatter("level5"))
      setLevel4Label(props?.location?.state?.filters?.level4Label ? props?.location?.state?.filters?.level4Label[0] : levelFiltersLabelFormatter("level4"))
      setLevel6Label(props?.location?.state?.filters?.level6Label ? props?.location?.state?.filters?.level6Label[0] : levelFiltersLabelFormatter("level6"))
      setLevel7Label(props?.location?.state?.filters?.level7Label ? props?.location?.state?.filters?.level7Label[0] : levelFiltersLabelFormatter("level7"))
      setDivisionOptions(filters.l1_name)
      setDepartmentOptions(filters?.l2_name?.length && filters.l2_name)
      setLevel4Options(filters?.l4_name?.length && filters.l4_name)
      setLevel5Options(filters?.l5_name?.length && filters.l5_name)
      setLevel6Options(filters?.l6_name?.length && filters.l6_name)
      setLevel7Options(filters?.l7_name?.length && filters.l7_name)
      setSubdepartmentOptions(filters?.l3_name?.length && filters.l3_name)
      setDivision(filters.l1_name)
      setDepartment(filters?.l2_name?.length && filters.l2_name[0])
      setSubdepartment(filters?.l3_name?.length && filters.l3_name[0])
      setLevel4(filters?.l4_name?.length && filters.l4_name[0])
      setLevel5(filters?.l5_name?.length && filters.l5_name[0])
      setLevel6(filters?.l6_name?.length && filters.l6_name[0])
      setLevel7(filters?.l7_name?.length && filters.l7_name[0])
      setDisableFilters(true);
      setStylesFromDashboard(styles)
      setLoader(true)
      let filtersObj = { level1: filters.l1_name }
      if (filters?.l2_name?.length && filters.l2_name[0]) {
        filtersObj["level2"] = filters?.l2_name?.length && filters.l2_name[0]
      }
      if (filters?.l3_name?.length && filters.l3_name[0]) {
        filtersObj["level3"] = filters?.l3_name?.length && filters.l3_name[0]
      }
      if (filters?.l4_name?.length && filters.l4_name[0]) {
        filtersObj["level4"] = filters?.l4_name?.length && filters.l4_name[0]
      }
      if (filters?.l5_name?.length && filters.l5_name[0]) {
        filtersObj["level5"] = filters?.l5_name?.length && filters.l5_name[0]
      }
      if (filters?.l6_name?.length && filters.l6_name[0]) {
        filtersObj["level6"] = filters?.l6_name?.length && filters.l6_name[0]
      }
      if (filters?.l7_name?.length && filters.l7_name[0]) {
        filtersObj["level7"] = filters?.l7_name?.length && filters.l7_name[0]
      }
      if (filters?.start_date?.length && filters.start_date[0]) {
        filtersObj["start_date"] = filters?.start_date?.length && filters.start_date[0]
      }
      if (filters?.end_date?.length && filters.end_date[0]) {
        filtersObj["end_date"] = filters?.end_date?.length && filters.end_date[0]
      }
      filtersObj["dc"] = dcs

      setSelectedFilters(filtersObj)
    }
    else {
      getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, {onl1Change:onDivisionChange,onl2Change:onDepartmentChange})
      props.fetchFilterData({ key: "divisions" });
    }
    return () => {
      props.resetFiltersOptions([]);
    }
  }, []);

  useEffect(() => {
    props.divisions.length && setDivisionOptions(() => props.divisions);
    props.storeCode.length && setStoreCodeOptions(() => props.storeCode);
    props.storeName.length && setStoreNameOptions(() => props.storeName);
    props.seg?.length && setSegOptions(() => props?.seg);
    props.factoryType.length && setFactoryTypeOptions(()=> props.factoryType);
    props.departments.length && setDepartmentOptions(() => props.departments);
    props.subdepartments.length && setSubdepartmentOptions(() => props.subdepartments);
    props.level4.length && setLevel4Options(() => props.level4);
    props.level5?.length && setLevel5Options(() => props.level5);
    props.level6?.length && setLevel6Options(() => props.level6);
    props.level7?.length && setLevel7Options(() => props.level7);
  }, [props.divisions, props.departments, props.subdepartments, props.level4, props.level5, props.level6 ,props.level7, props.storeCode, props.seg,props.filterType]);

  useEffect(() => {
    // if(props.error) {
    //   setLoader(false)
    // }
    if (props.tableData && loaderdts) {
      setLoader(false)
    }
    if (isContinueAllocation && sspForDraft) {
      let l_newAction = {}
      let l_article = draftInstance?.data?.reviewTableData?.map(val=> val.article)
      l_newAction["checkedRows"] = l_article
      setCheckAll((old) => [...old,l_newAction])
      let reviewTableData = cloneDeep(draftInstance?.data?.reviewTableData || [])
      let articleIds = reviewTableData.map(item => item.article)
      props.tableData.map(item => {
        if (!(articleIds.includes(item.article))) {
          reviewTableData.push(item)
        }
      })

      setTableData(() => reviewTableData);
      setTimeout(() => {
        populateStoreDetailsDropdown(true, false, draftInstance)
      }, 1000)
      // setTimeout(() => {
      setIsLoading(false)
      // }, 1000)
      setStoreGroupStoreResult(() => props.storeGroupStoreResult)
      setStoreMinStockResult(() => props.storeMinStockResult)
      setSspForDraft(false)
    }
    else {
      // !isEmpty(searchTermReq)  && setUpdatedArticles([])
      if (props.tableData?.length > 0 || !isEmpty(updatedRows)) {
        setPrevCount(props.tableData.length)
        let l_updatedArticles = updatedRows.map(val => val?.article)
        // let l_manualCheckedArticles = tableInstance?.selectedFlatRows?.map?.(val => val?.original?.article)
        // let l_checkedArticles = [...checkedArticles,...l_manualCheckedArticles]
        console.log(tableInstance,'cvefer')
        let l_newData = getNewData({"p_existingArticles":l_updatedArticles,"p_newData":[...tableInstance?.data,...props.tableData],"p_updatedRows":updatedRows,"p_searchTermReq":searchTermReq,
        // "p_checkedArticles":l_checkedArticles,"p_unCheckedArticles":unCheckedArticles,
        "p_checkAll":checkAll,"p_newBatchData":props.tableData})
        console.log(l_newData?.finalCheckedRows,unCheckedArticles,'jygytrrtd')
        setInitialSelectedRows(l_newData?.finalCheckedRows)
        if(tableInstance && tableInstance.data){
          setTableData([...l_newData?.finalData]);
          setStoreGroupStoreResult((old) => {
            return {...old, ...props.storeGroupStoreResult}
          })
          setStoreMinStockResult((old) => {
            return {...old, ...props.storeMinStockResult}})
        }
        else{
          setTableData([...l_newData?.finalData]);
          setStoreGroupStoreResult((old) => {
            return {...old, ...props.storeGroupStoreResult}
          })
          setStoreMinStockResult((old) => {
            return {...old, ...props.storeMinStockResult}
          })
        }
      }
      // setTableData(() => props?.tableData)
    }
    // setStoreGroupStoreResult(() => props.storeGroupStoreResult)
    // setStoreMinStockResult(() => props.storeMinStockResult)
  }, [props.tableData, props.storeGroupStoreResult, props.storeMinStockResult, 
    // props.error
  ]);

  useEffect(() => {
    if(props.error) {
      setLoader(false)
    }
  },[props.error])


  useEffect(() => {
    if (!isEmpty(props.storeTableData)) {
      setStoreTableData((old) => {
        return { ...old, ...props.storeTableData }
      });
      setStoreTableDataCopy((old) => {
        return { ...old, ...props.storeTableData }
      });
    }
    else {
      setStoreTableData((old) => {
        return old
      })
      setStoreTableDataCopy((old) => {
        return old
      })
    }
  }, [props.storeTableData])

 
  useEffect(() => {
    if (props.dcTableData) {
      setDcTableData((old) => {
        return { ...old, ...props.dcTableData }
      });
    }
    else {
      setDcTableData((old) => {
        return old
      })
    }
  }, [props.dcTableData])

  useEffect(() => {
    if (props.saveError) {
      Notify.error("Error in saving reserve quantity!!")
      setSaveIsDisabled(false)
    }
    if (props.saveSuccess) {
      props.fetchDcTableData(dcRequest.filter(val => val["Product_Code"] == selectedArticle?.value))
      // console.log(selectedArticle?.value,dcRequest,'sbdhbsj')
      Notify.success("Saved Successfully!!")
      setSaveIsDisabled(false)
    }
  }, [props.saveError, props.saveSuccess])

  useEffect(() => {
    if (props.createAllocationError) {
      setErrorModalMessage(props.createAllocationError?.message)
      if (!isEmpty(props.createAllocationError?.data)) {
        setFailedArticleId(props.createAllocationError?.data)
        setButtonLabel("Exclude All")
      }
      else {
        setFailedArticleId(null)
        setButtonLabel("Ok")
      }
    }

    // below code is for Ashleys with Gurobi Integration

    if(useSSE) {
      if (props.createAllocationSuccess && props.createAllocationData) {
        // eventSource = new EventSource("http://localhost:8000/api/simulation/allocate-stream?uuid=1000");
        eventSource = new EventSource(`${SSE_CREATE_ALLOCATON}?uuid_str=${props.createAllocationData}`)
        eventSource.onmessage = (event) => {
          const result = JSON.parse(event.data).data;
          console.log("SSE received:", result);
          console.log("SSE onmessage callback")
          if (result.status && result.allocation_code) {
            // props.deleteDraft({ allocation_id: currentDraftallocationCode })
            setIsNewDraftSaved(true)
            setShowCount(prev => prev + 1)
            // Notify.success("Success in creating allocation!!")
            setTimeout(() => {
              props.history.push(FINALIZE_PAGE, {"planDetails" : { allocationCode: result.allocation_code, allocationName: inputRef?.current?.value || result.allocation_code, isPOASN: isPOASNFlow, draftId: currentDraftallocationCode }});
              // window.location.reload()
              reloadPageWithUpdateToken()
            }, 2000);
            eventSource.close();
          }
          if(!result.status) {
            props.setLoaderToFalse()
            Notify.error(result?.message ||  ERROR_MSG)
            eventSource.close();
          }
          // call function with allocation id the navigate to finalize screen
        }
  
        eventSource.onerror = (event) => {
          console.log(event,event.target.readyState, "SSE onerror callback")
          if (event.target.readyState === EventSource.CLOSED) {
            console.log('SSE closed (' + event.target.readyState + ')')
          }
          eventSource.close();
          props.setLoaderToFalse()
          Notify.error( ERROR_MSG)
        }
  
        eventSource.onopen = (event) => {
          console.log("SSE connection opened")
        }
      }
    }
    else {
      // below code is for Puma and other clients without Gurobi Integration
        if (props.createAllocationSuccess && props.createAllocationData) {
          // props.deleteDraft({ allocation_id: currentDraftallocationCode })
          setIsNewDraftSaved(true)
          Notify.success("Success in creating allocation!!")
          setTimeout(() => {
            props.history.push(FINALIZE_PAGE, {"planDetails" : { allocationCode: props.createAllocationData, allocationName: inputRef?.current?.value || props.createAllocationData, isPOASN: isPOASNFlow, draftId: currentDraftallocationCode }});
          }, 3000);
        }
    }
  }, [props.createAllocationError, props.createAllocationSuccess, props.createAllocationData, useSSE])

  useEffect(() => {
    if(showCount === 1) {
      Notify.success("Success in creating allocation!!")
    }
  }, [showCount])

  useEffect(() => {
    if (props.allocationCode && props.allocationCodeSuccess) {
      setTimeout(() => {
        props.history.push(FINALIZE_PAGE, {"planDetails" : { allocationCode: props.allocationCode, allocationName: inputRef?.current?.value || props.allocationCode, isPOASN: isPOASNFlow }});
      }, 2000);
    }
    if (props.allocationCodeError) {
      Notify.error("Error in saving allocation!!")
    }
  }, [props.allocationCode, props.allocationCodeSuccess, props.allocationCodeError]);


  useEffect(() => {

    return () => {
      props.resetAll()
    }
  }, [])


  const onDivisionChange = (val,p_userPreference) => {
    // showDrafts(false)
    let payload = {};
    if (val) {
      payload = { level1: [val], key: "departments" };
    }
    setDivision(val);
    resetRestFilters(0)
    // API call
    if(p_userPreference?.user_preference) { 
      props.fetchFilterData({ level1: [val], key:'l10'});
    }
    else{
      props.fetchFilterData( payload );
    }
  };

  const onDepartmentChange = (value, event, that) => {
    if (Array.isArray(value)) {
      let valuesArray = handleSelectAll(value, event, that)
      setDepartment(valuesArray[1])
      setRequest(old => {
        return {
          ...old,
          'department': valuesArray[0]
        }
      })
      let req_val = intersectionWith(valuesArray[0], departmentOptions, isEqual);
      // setDepartment(valuesArray[0]);
      resetRestFilters(1)
      // API call
      if(event?.user_preference) { 
        props.fetchFilterData({ level1: [event?.l1_name], level2: value ,key:"subdepartments"});
      }
      else{
        props.fetchFilterData({ level1: [division], level2: req_val, key: "subdepartments" })
      }
      // if (valuesArray[0].length) {
      //   props.fetchFilterData({
      //     level1: [division],
      //     level2: req_val,
      //     key: "subdepartments",
      //   });
      // }
    }
    else {
      setDepartment(value)
      if(event?.user_preference) { 
        props.fetchFilterData({ level1: [event?.l1_name], level2: [value], key:'subdepartments'});
      }
      else{
        props.fetchFilterData({ level1: [division], level2: [value] ,  key: "subdepartments" })
      }
      resetRestFilters(1)
      setRequest(old => {
        return {
          ...old,
          'department': value,
        }
      })
    }
  };

  const onSubdepartmentChange = (value, event, that) => {
    if (Array.isArray(value)) {
      let valuesArray = handleSelectAll(value, event, that)
      setSubdepartment(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'subDepartment': valuesArray[0]
        }
      })
      let req_val = intersectionWith(valuesArray[0], subdepartmentOptions, isEqual);
      resetRestFilters(2)
      if (valuesArray[0].length) {
        props.fetchFilterData({
          level1: [division],
          level2: request?.department,
          level3: req_val,
          key: "level4",
        });
      }
    }
    else {
      setSubdepartment(value)
      if(event?.user_preference) { 
        props.fetchFilterData({ level1: [event?.l1_name], level2: [event?.l2_name], level3: [value], key:'level4'});
      }
      else{
        props.fetchFilterData({ level1: [division], level2: request?.department, level3: [value] ,  key: "level4" })
      }
      resetRestFilters(2)
      setRequest(old => {
        return {
          ...old,
          'subDepartment': value,
        }
      })
    }
  };

  const onLevel4Change = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    // let value = intersectionWith(val, level4Options, isEqual);
    setLevel4(() => {
      setTableInstance(null);
      return valuesArray[1];
    });
    setRequest(old => {
      return {
        ...old,
        'level4': valuesArray[0]
      }
    })
    let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
    resetRestFilters(3)
    if (valuesArray[0].length) {
      props.fetchFilterData({
        level1: [division],
        level2: request?.department,
        level3: request?.subDepartment,
        level4: req_val,
        key: "level5",
      });
    }
  }

  const onLevel5Change = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    // let value = intersectionWith(val, level4Options, isEqual);
    setLevel5(() => {
      setTableInstance(null);
      return valuesArray[1];
    });
    setRequest(old => {
      return {
        ...old,
        'level5': valuesArray[0]
      }
    })
    resetRestFilters(4);
    let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
    props.fetchFilterData({
      level1: [division],
      level2: request?.department,
      level3: request?.subDepartment,
      level4: request?.level4,
      level5: req_val,
      key: "level6",
    });
  }

  const onLevel6Change = (value,event,that)=>{
    let valuesArray = handleSelectAll(value, event, that)
    setLevel6(() => {
      setTableInstance(null);
      return valuesArray[1];
    });
    setRequest(old => {
      return {
        ...old,
        'level6': valuesArray[0]
      }
    })
    resetRestFilters();
    let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
    props.fetchFilterData({
      level1: [division],
      level2: request?.department,
      level3: request?.subDepartment,
      level4: request?.level4,
      level5: request?.level5,
      level6: req_val,
      key: "level7",
    });
  }

  const onLevel7Change = (value,event,that)=>{
    let valuesArray = handleSelectAll(value, event, that)
    setLevel7(() => {
      setTableInstance(null);
      return valuesArray[1];
    });
    setRequest(old => {
      return {
        ...old,
        'level7': valuesArray[0]
      }
    })
  }

  const onStoreNameChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    setStoreName(() => {
      setTableInstance(null);
      return (!isEmpty(valuesArray[1]) ? valuesArray[1] : null);
    });
    setRequest(old => {
      return {
        ...old,
        'store_name': valuesArray[0]
      }
    })
    let req_val = intersectionWith(valuesArray[0], storeNameOptions, isEqual);
    props.fetchFilterData({
      level1: [division],
      level2: request?.department,
      level3: request?.subDepartment,
      level4: request?.level4,
      level5: request?.level5,
      level6: request?.level6,
      level7: request?.level7,
      store_name: req_val,
      key: "store_code",
    });
    resetRestFilters(5);
  }

  const onStoreCodeChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    setStoreCode(() => {
      setTableInstance(null);
      return (!isEmpty(valuesArray[1]) ? valuesArray[1] : null);
    });
    setRequest(old => {
      return {
        ...old,
        'store_code': valuesArray[0]
      }
    })
  }

  const onSEGChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    setSeg(() => {
      setTableInstance(null);
      return (!isEmpty(valuesArray[1]) ? valuesArray[1] : null);
    });
    setRequest(old => {
      return {
        ...old,
        'store_groups': valuesArray[0]
      }
    })
  }

  const onFactoryTypeChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that)
    console.log(valuesArray, 'fvrvfdcs')
    setFactoryType(() => {
      setTableInstance(null);
      return (!isEmpty(valuesArray[1]) ? valuesArray[1] : null);
    });
    setRequest(old => {
      return {
        ...old,
        'factoryType': valuesArray[0]
      }
    })
  }


  const applyFilters = () => {
    setisFirstCall(true);
    props.resetStyleIndex();
    props.resetStyleIndexForCA()
    setTableInstance(null);
    setTableData([]);
    setSearchTermReq({})
    setSortReq({})
    setCheckAllSetAllReq([])
    setUpdatedArticles([])
    setUpdatedArticlesForRest([])
    setUpdatedRows([])
    setUpdatedRowsForRest([])
    callCheckAllApi && setCallCheckAllApi(false);
    createAllocationBeforeAllArticles &&  setCreateAllocationBeforeAllArticles(false);
    setCheckAllApisWithSearchTerm([]);
    setCheckedArticles([]);
    setUnCheckedArticles([]);
    setCheckedRows([]);
    setCheckedRowsForRest([]);
    setCheckAll([]);
    setCheckAllForRest([]);
    setPrevAction("");
    setPrevActionForRest("")
    setUserEditAfterValidationOnRest(false);
    setArticles({})
    setPrevIndex(0);
    setPageIndex(0);
    setErrorModalMessage(null)
    setShowSetAll(false)
    setInitialIndex(0)
    setArticleStoreMapping({})
    setDcTableData([])
    setStoreTableData([])
    setHideDcStoreSection(true)
    if (getDefaultFiltersCondition({l1_name: division, l2_name: department, l3_name: subdepartment})) 
        // && request?.department?.length && request?.subDepartment?.length
    {
      setArticleWithZeroInv([])
      setArticleWithZeroInvAsUserReserved([])
      setArticleWithZeroInvForRest([])
      setArticleWithZeroInvAsUserReservedForRest([])
      setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id")}, getLevelFiltersForUserPreference({l1_name:division,l2_name:department,l3_name:subdepartment}))
      // setAllocationName(null)
      setCurrentDraftallocationCode("")
      setIsContinueAllocation(false)
      setSspForDraft(false)
      setIsNewDraftSaved(false)
      setIsSaveDraft(false)
      setInitialSelectedRows({})
      setPrevCount(0)
      setShowDrafts(false)
      setSelectedFilters({
        level1: [division],
        level2: request?.department,
        level3: request?.subDepartment,
        level4: request?.level4,
        level5: request?.level5,
        level6: request?.level6,
        level7: request?.level7,
        dc: [],
        store_groups: request?.store_groups,
        start_date: min_date ? moment(min_date).format('YYYY-MM-DD') : null,
        end_date: max_date ? moment(max_date).format('YYYY-MM-DD') : null,
        start_date_last_allocated: min_allocation_date ? moment(min_allocation_date).format('YYYY-MM-DD') : null,
        end_date_last_allocated: max_allocation_date ? moment(max_allocation_date).format('YYYY-MM-DD') : null,
        factory_type: request?.factoryType
      })
      if(min_date!==null && max_date===null){
        Notify.error("End Date is Mandatory!!")
      }
      else if(min_date==null && max_date!==null)
      {
          Notify.error("Start Date is Mandatory!!");
      }
      if(min_allocation_date !== null && max_allocation_date === null && !props.isViewAllocation)
      {
        Notify.error("Allocation End Date is Mandatory!!");
      }
     else if(min_allocation_date == null && max_allocation_date !== null && !props.isViewAllocation)
      {
        Notify.error("Allocation Start Date is Mandatory!!");
      }
      else{
      props.fetchStrategyTableData({
        level1: [division],
        level2: request?.department,
        level3: request?.subDepartment,
        level4: request?.level4,
        level5: request?.level5,
        level6: request?.level6,
        level7: request?.level7,
        dc: [],
        store_groups: request?.store_groups,
        start_date: min_date ? moment(min_date).format('YYYY-MM-DD') : null,
        end_date: max_date ? moment(max_date).format('YYYY-MM-DD') : null,
        start_date_last_allocated: min_allocation_date ? moment(min_allocation_date).format('YYYY-MM-DD') : null,
        end_date_last_allocated: max_allocation_date ? moment(max_allocation_date).format('YYYY-MM-DD') : null,
        product_type: request?.product_type,
        "article":stylesFromDashboard,
        "styleIndex": 0,
        start_date: min_date && moment(min_date).format('YYYY-MM-DD') ,
        end_date: max_date && moment(max_date).format('YYYY-MM-DD') ,
        factory_type: request?.factoryType
      });
    }
    }
    else {
      Notify.error("Please select all mandatory options!!")
    }
  };



  const setAllHandler = (p_instance, p_table) => {
    let msg;
    if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
      if (p_table === "main_table") {
        setShowSetAll(true);
        shouldDisabled('Demand_Type_Filter_Default')
      }
      else {
        setShowSetAllStoreDetails(true);
        setapsSA(null)
        setwosSA(null)
      }
    }
    else {
      if (p_table === "main_table")
        msg = "product"
      else
        msg = "store"
      Notify.error(`Please select atleast 1 ${msg} to use Set All functionality!!`)
    }
  }

  const validationForRestOfTheData = () => {
    if (!isEmpty(articlesWithZeroInvForRest)) {
      setErrorModalMessage(`Total Available Inventory for ${articlesWithZeroInvForRest} article(s) is zero as it's already allocated!!`)
      setCreateAllocationBeforeAllArticles(false)
      setSecondaryButtonLabel("")
      return true;
    }
    if (!isEmpty(articleWithNoPPForRest)) {
      setErrorModalMessage(`Product Profile for ${articleWithNoPPForRest} article(s) is not Mapped!!`)
      setCreateAllocationBeforeAllArticles(false)
      return true;
    }
    if (!isEmpty(articleWithZeroWosForRest)) {
      setErrorModalMessage(`Average WOS for ${articleWithZeroWosForRest} article(s) is Zero!!`)
      setCreateAllocationBeforeAllArticles(false)
      return true;
    }
    if (!isEmpty(articleWithNoSEGForRest)) {
      setErrorModalMessage(`Store Eligibility for ${articleWithNoSEGForRest} article(s) is not Mapped!!`)
      setCreateAllocationBeforeAllArticles(false)
      return true;
    }
    if (!isEmpty(articleWithNoDCForRest)) {
      setErrorModalMessage(`DC for ${articleWithNoDCForRest} article(s) is not Mapped!!`)
      setCreateAllocationBeforeAllArticles(false)
      return true;
    }
    if (!isEmpty(articleWithNoFixedUserInvForRest)) {
      setErrorModalMessage(`Please Enter User Defined Inv % for ${articleWithNoFixedUserInvForRest} article(s) !!`)
      setCreateAllocationBeforeAllArticles(false)
      return true;
    }
    if (!isEmpty(articlesWithZeroInvAsUserReservedForRest)) {
      setErrorModalMessage(`Total Available Inventory for ${articlesWithZeroInvAsUserReservedForRest} article(s) is zero as user has reserved, Press Continue to edit user reserve or exclude the article!!`)
      // setSecondaryButtonLabel("Continue")
      setCreateAllocationBeforeAllArticles(false)
      return true;
    }
    return false;
  }

  const validationForAvailableData = (p_selectedArticles, p_articlesWithZeroInvAsUserReserved=null) => {
    let isTotInvZeroAsAllocated = false;
    let isTotInvZeroAsUserReserved = false;
    let isWosZero = false;
    let isNoStoreGroup = false;
    let isNoDC = false;
    let isNoFixedInv = false;
    let isProductProfileNotMapped = false;
    let selectedArticleIdsWithTotInvZeroAsReserved = []
    let selectedArticleIdsWithTotInvZeroAsUserReserved = []
    let selectedArticleIdsWithNoPP = []
    let selectedArticleIdsWithZeroWOS = []
    let selectedArticleIdsWithNoSG = []
    let selectedArticleIdsWithNoDC = []
    let selectedArticlesWithNoFixedInv = []

    Object.keys(p_selectedArticles)?.map((o) => {
      let selectedArticle = p_selectedArticles[o]
      if (selectedArticle.total_inventory == 0 && !p_articlesWithZeroInvAsUserReserved) {
        if(Number(selectedArticle.reserve_quantity) !== 0) {
          isTotInvZeroAsUserReserved = true;
          selectedArticleIdsWithTotInvZeroAsUserReserved.push(selectedArticle.article)
        }
        else {
          isTotInvZeroAsAllocated = true;
          selectedArticleIdsWithTotInvZeroAsReserved.push(selectedArticle.article)
        }
      }
      if (isEmpty(selectedArticle.product_profile)) {
        isProductProfileNotMapped = true;
        selectedArticleIdsWithNoPP.push(selectedArticle.article)
      }
      if (selectedArticle.article_wos == 0) {
        isWosZero = true;
        selectedArticleIdsWithZeroWOS.push(selectedArticle.article)
      }
      if (!selectedArticle.default_store_eligibility_default.length) {
        isNoStoreGroup = true;
        selectedArticleIdsWithNoSG.push(selectedArticle.article)
      }
      if (!selectedArticle.default_dcs.length) {
        isNoDC = true;
        selectedArticleIdsWithNoDC.push(selectedArticle.article)
      }
      if((selectedArticle.Demand_Type_Filter_Default?.[0]?.value === "Fixed" || selectedArticle.Demand_Type_Filter_Default?.value === "Fixed") && !Number(selectedArticle.edit_total_inventory_units) && !Number(selectedArticle.edit_total_inventory)) {
        isNoFixedInv = true;
        selectedArticlesWithNoFixedInv.push(selectedArticle.article)
      }
    })
    if (isTotInvZeroAsAllocated) {
      setErrorModalMessage(`Total Available Inventory for ${selectedArticleIdsWithTotInvZeroAsReserved} article(s) is zero as it's already allocated!!`)
      setArticleWithZeroInv(selectedArticleIdsWithTotInvZeroAsReserved);
      return true;
    }
    if (isProductProfileNotMapped) {
      setErrorModalMessage(`Product Profile for ${selectedArticleIdsWithNoPP} article(s) is not Mapped!!`)
      setArticleWithZeroInv(selectedArticleIdsWithNoPP);
      return true;
    }
    if (isWosZero) {
      setErrorModalMessage(`Average WOS for ${selectedArticleIdsWithZeroWOS} article(s) is Zero!!`)
      setArticleWithZeroInv(selectedArticleIdsWithZeroWOS);
      return true;
    }
    if(isNoStoreGroup) {
      setErrorModalMessage(`Store Eligibility for ${selectedArticleIdsWithNoSG} article(s) is not Mapped!!`)
       setArticleWithZeroInv(selectedArticleIdsWithNoSG);
      return true;
    }
    if(isNoDC) {
      setErrorModalMessage(`DC for ${selectedArticleIdsWithNoDC} article(s) is not Mapped!!`)
       setArticleWithZeroInv(selectedArticleIdsWithNoDC);
      return true;
    }
    if(isNoFixedInv) {
      setErrorModalMessage(`Please Enter User Defined Inv % for ${selectedArticlesWithNoFixedInv} article(s) !!`)
       setArticleWithZeroInv(selectedArticlesWithNoFixedInv);
      return true;
    }
    if (isTotInvZeroAsUserReserved) {
      setArticleWithZeroInv([])
      setErrorModalMessage(`Total Available Inventory for ${selectedArticleIdsWithTotInvZeroAsUserReserved} article(s) is zero as user has reserved, Press Continue to edit user reserve or exclude the article!!`)
      setArticleWithZeroInvAsUserReserved(selectedArticleIdsWithTotInvZeroAsUserReserved);
      setSecondaryButtonLabel("Continue")
      return true;
    }
    return false;
  }


  useEffect(() => {
    console.log(checkAllApisWithSearchTerm,props.out_of_dataForCA, props.style_indexForCA, props.errorForCA,'djcbkcjdbkbc')
    if(!isEmpty(checkAllApisWithSearchTerm) && !props.out_of_dataForCA && !props.errorForCA && props.style_indexForCA) {
      shouldFetchRestOfTheArticles(props?.style_indexForCA,checkAllApisWithSearchTerm)
    }
    if(!isEmpty(checkAllApisWithSearchTerm) && props.out_of_dataForCA) {
      let l_checkAllApiWithSearchTerm = [...checkAllApisWithSearchTerm]
      l_checkAllApiWithSearchTerm.shift()
      setCheckAllApisWithSearchTerm(l_checkAllApiWithSearchTerm)
      shouldFetchRestOfTheArticles(props?.style_indexForCA,l_checkAllApiWithSearchTerm)
    }
    if(props.errorForCA) {
      Notify.error("Error in fetching next set of articles!!")
      props.resetStyleIndexForCA();
      !isEmpty(checkAllApisWithSearchTerm) && setCheckAllApisWithSearchTerm([]);
      createAllocationBeforeAllArticles &&  setCreateAllocationBeforeAllArticles(false);
    }
  },[checkAllApisWithSearchTerm, props.out_of_dataForCA, props.style_indexForCA, props.errorForCA])
  
  useEffect(() => {
    if(callCheckAllApi && props.out_of_dataForCA) {
      setCallCheckAllApi(false)
    }
  },[callCheckAllApi,props.out_of_dataForCA])

  useEffect(() => {
    if(isEmpty(checkAllApisWithSearchTerm) && createAllocationBeforeAllArticles) {
      console.log(articlesWithZeroInvAsUserReservedForRest,articlesWithZeroInvForRest,articleWithNoPPForRest,articleWithZeroWosForRest,'articleWithZeroWosForRest')  
      let isValidationError = validationForRestOfTheData(); 
      if(isValidationError) 
        return;
      else
      createAllocation();
    }
    else if(isEmpty(checkAllApisWithSearchTerm) && isSaveDraft && props.draftNameSuccess) {
      saveDraft(true, true)
    }
  },[checkAllApisWithSearchTerm,createAllocationBeforeAllArticles,tableData])

  useEffect(() => {
    console.log(props.dataForCA,'jhvfc')
    if (props.dataForCA?.length > 0) {
      if(tableInstance && tableInstance.data){
        setTableDataForCA((old) => {
          return [...old,...props.dataForCA]
        })
        let newArticleOptions = [], selectedArticles = {}
        let l_finalCheckedArticles = getFinalCheckedRowsForCAData(checkAll,props.dataForCA,updatedRows,articles)
        console.log(l_finalCheckedArticles,'cdcdfcd')
        for (const [key, value] of Object.entries(l_finalCheckedArticles)) {
          newArticleOptions.push({
            label: key,
            value: key
          })
          selectedArticles[key] = value        
        }
        // l_finalCheckedArticles?.forEach(val => {
        //   newArticleOptions.push({
        //     label: val.article,
        //     value: val.article
        //   })
        //   selectedArticles[val.article] = val
        // })
        setArticleOptions((old) => {
          return [...old,...newArticleOptions]
        })
        setArticles((old) => {
          return {
            ...old,
            ...selectedArticles
          }
        })
        const result = getStoreAndDcRequestForNewSetOfArticles(selectedArticles,storeCode)
        setStoreRequest((old) => {
          return [...old,...result.request]
        })
        setDcRequest((old) => {
          return [...old,...result.dcRequest]
        })

        console.log(l_finalCheckedArticles,'jhgchgf')
        let tableData = cloneDeep(l_finalCheckedArticles)
        setSelectedArticlesDraftData((old) => { return [...old, ...Object.values(l_finalCheckedArticles)] })
        let isTotInvZeroAsAllocated = false;
        let isTotInvZeroAsUserReserved = false;
        let isWosZero = false;
        let isProductProfileNotMapped = false;
        let isNoStoreGroup = false;
        let isNoDC = false;
        let isNoFixedInv = false;
        let selectedArticleIdsWithTotInvZeroAsReserved = []
        let selectedArticleIdsWithTotInvZeroAsUserReserved = []
        let selectedArticleIdsWithNoPP = []
        let selectedArticleIdsWithZeroWOS = []
        let selectedArticleIdsWithNoSG = []
        let selectedArticleIdsWithNoDC = []
        let selectedArticlesWithNoFixedInv = []
    
        Object.keys(tableData)?.map((o) => {
          let selectedArticle = tableData[o]
          if (selectedArticle.total_inventory == 0 ) {
            if(Number(selectedArticle.reserve_quantity) !== 0) {
              isTotInvZeroAsUserReserved = true;
              selectedArticleIdsWithTotInvZeroAsUserReserved.push(selectedArticle.article)
            }
            else {
              isTotInvZeroAsAllocated = true;
              selectedArticleIdsWithTotInvZeroAsReserved.push(selectedArticle.article)
            }
          }
          if (isEmpty(selectedArticle.product_profile)) {
            isProductProfileNotMapped = true;
            selectedArticleIdsWithNoPP.push(selectedArticle.article)
          }
          if (selectedArticle.article_wos == 0) {
            isWosZero = true;
            selectedArticleIdsWithZeroWOS.push(selectedArticle.article)
          }
          if (!selectedArticle.default_store_eligibility_default.length) {
            isNoStoreGroup = true;
            selectedArticleIdsWithNoSG.push(selectedArticle.article)
          }
          if (!selectedArticle.default_dcs.length) {
            isNoDC = true;
            selectedArticleIdsWithNoDC.push(selectedArticle.article)
          }
          if((selectedArticle.Demand_Type_Filter_Default?.[0]?.value === "Fixed" || selectedArticle.Demand_Type_Filter_Default?.value === "Fixed") && !Number(selectedArticle.edit_total_inventory)) {
            isNoFixedInv = true;
            selectedArticlesWithNoFixedInv.push(selectedArticle.article)
          }
        })
        if (isTotInvZeroAsAllocated) {
          // setErrorModalMessage(`Total Available Inventory for ${selectedArticleIdsWithTotInvZeroAsReserved} article(s) is zero as it's already allocated!!`)
          setArticleWithZeroInvForRest((old) => [...old,...selectedArticleIdsWithTotInvZeroAsReserved]);
          // return;
        }
        if (isProductProfileNotMapped) {
          // setErrorModalMessage(`Product Profile for ${selectedArticleIdsWithNoPP} article(s) is not Mapped!!`)
          setArticleWithNoPPForRest((old) => [...old,...selectedArticleIdsWithNoPP]);
          // return;
        }
        if (isWosZero) {
          // setErrorModalMessage(`Average WOS for ${selectedArticleIdsWithZeroWOS} article(s) is Zero!!`)
          setArticleWithZeroWosForRest((old) => [...old,...selectedArticleIdsWithZeroWOS]);
          // return;
        }
        if (isNoStoreGroup) {
          setArticleWithNoSEGForRest((old) => [...old,...selectedArticleIdsWithNoSG]);
        }
        if (isNoDC) {
          setArticleWithNoDCForRest((old) => [...old,...selectedArticleIdsWithNoDC]);
        }
        if (isNoFixedInv) {
          setArticleWithNoFixedUserInvForRest((old) => [...old,...selectedArticlesWithNoFixedInv]);
        }
        if (isTotInvZeroAsUserReserved) {
          // setErrorModalMessage(`Total Available Inventory for ${selectedArticleIdsWithTotInvZeroAsUserReserved} article(s) is zero as user has reserved, Press Continue to edit user reserve or exclude the article!!`)
          setArticleWithZeroInvAsUserReservedForRest((old) => [...old,...selectedArticleIdsWithTotInvZeroAsUserReserved]);
          setSecondaryButtonLabel("Continue")
          // return;
        }

        console.log(result,storeRequest,'sdcdscscds')
      }
    }
  },[props.dataForCA])

  useEffect(() => {
      console.log(tableDataForCA,articleOptions,articles,storeRequest,dcRequest,updatedRows,'nhgdgrxt')
  },[tableDataForCA,articleOptions,articles,storeRequest,dcRequest,updatedRows])


  const shouldFetchRestOfTheArticles = (p_style_indexForCA = null, p_checkAllReq) => {
    if(!isEmpty(p_checkAllReq)){
      let l_checkAllRequest = p_checkAllReq[0]
      props.fetchStrategyTableDataForCheckAll({
        ...selectedFilters,
        "article":stylesFromDashboard,
        product_type: request?.product_type,
        "styleIndex": p_style_indexForCA ,
        "searchColumns":l_checkAllRequest?.searchTermReq,
        "sortColumn":sortReq,
        "set_all":checkAllSetAllReq,
      });
    }
  }

  const shouldShowAlert = (isInitialDraftCall, isCallApi, instance) => {
    if(userEditAfterValidationOnRest){
      let l_updatedRowsObject = {}, newArticleOptions = []
      let l_checkedRows = {
        ...articles,
        ...checkedRowsForRest
      }
      let l_checkedRowsArticles = Object.keys(l_checkedRows)
      let l_updatedRows = tableInstance.data.filter(val => l_checkedRowsArticles.includes(val.article))
      let l_updatedRowsForRest = [...l_updatedRows,...updatedRowsForRest]
      l_updatedRowsForRest.forEach(val => {
        l_updatedRowsObject[val.article] = val
      }) 

      let l_checkedUpdatedRowsObject = Object.keys(l_updatedRowsObject).filter(c => Object.keys(l_checkedRows).indexOf(c) !== -1).reduce((a,b) => {let b1={}; b1[b]=l_updatedRowsObject[b]; return {...a, ...b1}}, {});

      let l_checkedArticleFromOtherBatch = {
        ...l_checkedRows,
        ...l_checkedUpdatedRowsObject
      }

      console.log(updatedArticlesForRest,selectedArticleWithData,storeTableDataCopy,storeTableData,'dummyselectedArticleWithData')
      let l_finalSelectedArticlesArrayAfterEdit = Object.values(l_checkedArticleFromOtherBatch)
      let l_finalSelectedArticlesAfterEdit = getFinalCheckedArticles(checkAllForRest,l_finalSelectedArticlesArrayAfterEdit)
      let l_finalSelectedArticlesObjectAfterEditCopy = cloneDeep(l_checkedArticleFromOtherBatch)
      let l_finalSelectedArticlesObjectAfterEdit = {}
      l_finalSelectedArticlesAfterEdit.forEach(val => {
        l_finalSelectedArticlesObjectAfterEdit[val] = (l_finalSelectedArticlesObjectAfterEditCopy[val]) 
      })

      let isValidationError = validationForAvailableData(l_finalSelectedArticlesObjectAfterEdit)
      if(isValidationError) 
      return;
      else{
              for (const [key, value] of Object.entries(l_finalSelectedArticlesObjectAfterEdit)) {
                newArticleOptions.push({
                  label: key,
                  value: key
                })
              }
        
              setArticleOptions((old) => {
                return [...newArticleOptions]
              })
              setArticles((old) => {
                return {
                  ...l_finalSelectedArticlesObjectAfterEdit
                }
              })
              const result = getStoreAndDcRequestForNewSetOfArticles(l_finalSelectedArticlesObjectAfterEdit,storeCode)
              setStoreRequest((old) => {
                return [...result.request]
              })
              setDcRequest((old) => {
                return [...result.dcRequest]
              })
              setSelectedArticle(() => newArticleOptions[0]);
              setUserEditAfterValidationOnRest(false)

              let removeArticlesWithStoreData =  getArrayDifference(selectedArticleWithData,updatedArticlesForRest)
              setSelectedArticleWithData(removeArticlesWithStoreData)

              setArticleWithNoPPForRest([])
              setArticleWithZeroInvForRest([])
              setArticleWithZeroWosForRest([])
              setArticleWithNoSEGForRest([])
              setArticleWithNoDCForRest([])
              setArticleWithNoFixedUserInvForRest([])
              setArticleWithZeroInvAsUserReservedForRest([])
              setCheckAllForRest([])
              setCheckedRowsForRest([])
              setUpdatedArticlesForRest([])
              setUpdatedRowsForRest([])
        
              props.fetchStoreTableData(result.request.filter(val => val.Product_Code == newArticleOptions[0]?.value));
              props.fetchDcTableData(result.dcRequest.filter(val => val.Product_Code == newArticleOptions[0]?.value));
              console.log(storeTableData,storeTableDataCopy,'cdscdesdv')

      }

    }
    else{
      if (storeCode) {
        let selectedArticles = {};
        Object.keys(tableInstance.state.selectedRowIds).forEach((rowId) => {
          selectedArticles[tableInstance.preFilteredRowsById[rowId].original.article] =
            tableInstance.preFilteredRowsById[rowId].original;
        });
  
        let l_storeGroups = []
  
        Object.keys(selectedArticles)?.forEach((o) => {
          l_storeGroups.push(...selectedArticles[o].default_store_eligibility_default)
        })
  
        let l_distinctStoreGroups = [...new Map(l_storeGroups.map(item => [item['value'], item])).values()]
        let l_messageObject = {}
        let l_showAlert = false;
        let l_storeMapping, l_storeCodes, l_excludedStores
        l_distinctStoreGroups.forEach(val => {
          l_storeMapping = storeGroupStoreResult[val?.value]
          l_storeCodes = storeCode.map(val => val.value).filter(val => val != "*")
          l_excludedStores = l_storeCodes.filter(x => !l_storeMapping?.includes(x));
          if (l_excludedStores.length) {
            l_messageObject[val?.label] = l_excludedStores
            l_showAlert = true
          }
        })
        if (l_showAlert) {
          let msg = []
          msg.push(<h4 style={{ margin: '5px 0 1.5rem' }}>The selected stores are excluded from the following store groups:</h4>)
          for (let i in l_messageObject) {
            msg.push(<li><span><b>{`${i} : # ${l_messageObject[i]}`}</b></span></li>)
          }
          msg.push(<h4 style={{ margin: '1.5rem 0 5px' }}> Are you sure you wish to continue?</h4>)
          setShowStoreAlert(true)
          setStoreAlertMessage(msg)
        }
        else {
          populateStoreDetailsDropdown(isInitialDraftCall, isCallApi, instance)
        }
      }
      else
        populateStoreDetailsDropdown(isInitialDraftCall, isCallApi, instance)
    }
  }

  const populateStoreDetailsDropdown = (isInitialDraftCall, isCallApi, instance, p_articlesWithZeroInvAsUserReserved=null) => {
    setFailedArticleId(null)
    setButtonLabel("Exclude All")
    setSecondaryButtonLabel("Cancel")
    setTotalEstimatedDemad(null)
    setErrorModalMessage(null)
    setStoreTableData(instance?.data?.DetailsTableData?.storeTableData || [])
    setStoreTableDataCopy(instance?.data?.DetailsTableData?.storeTableData || [])
    setDcTableData(instance?.data?.DetailsTableData?.dcTableData || [])
    setSelectedArticleStoreConst(null);
    setSelectedArticleDcData(null);
    if (isCallApi) {
      setSelectedArticleWithData([])
    }
    else {
      setSelectedArticleWithData(Object.keys(instance?.data?.DetailsTableData?.storeTableData));
    }
    setReviewBtnDisabled(true)
    setIsReviewChangesDisabled(true)
    let l_checkedUnchecked = checkAll.filter(val => val.checkAll || val.unCheckAll)
    let l_checkAll = [] 
    for(let i=0; i< l_checkedUnchecked.length; i++){
        if(l_checkedUnchecked[i].checkAll){
          l_checkAll.push(l_checkedUnchecked[i])
        }
        if(l_checkedUnchecked[i].unCheckAll){
            l_checkAll = []
        }
    }
    let checked = l_checkAll.filter(val => val.checkAll)
    let unchecked = l_checkAll.filter(val => val.unCheckAll)
    for(let i =0; i< checked.length; i++){
      for(let j=0; j<unchecked.length; j++){
          if((isEqual(checked[i].searchTermReq,unchecked[j].searchTermReq))){
            checked.splice(i,1)
          }
      }
    }
    if (Object.keys(tableInstance.state.selectedRowIds).length > 0 || !isEmpty(checked) || !isEmpty(checkedRows)) {
      const l_selectedArticles = {};
      let articleOptions = [], articles = [], l_updatedRowsObject = {}
      Object.keys(tableInstance.state.selectedRowIds).forEach((rowId) => {
        l_selectedArticles[tableInstance.preFilteredRowsById[rowId].original.article] =
          tableInstance.preFilteredRowsById[rowId].original;
        // articleOptions.push({
        //   label: tableInstance.preFilteredRowsById[rowId].original.article,
        //   value: tableInstance.preFilteredRowsById[rowId].original.article,
        // });
        articles.push(tableInstance.preFilteredRowsById[rowId].original.article)
      });
      console.log(checkedRows,updatedRows,l_selectedArticles,'cdecdec')     
      updatedRows.forEach(val => {
        l_updatedRowsObject[val.article] = val
      }) 

      let l_checkedUpdatedRowsObject = Object.keys(l_updatedRowsObject).filter(c => Object.keys(checkedRows).indexOf(c) !== -1).reduce((a,b) => {let b1={}; b1[b]=l_updatedRowsObject[b]; return {...a, ...b1}}, {});

      let l_checkedArticleFromOtherBatch = {
        ...checkedRows,
        ...l_checkedUpdatedRowsObject
      }

      let l_selectedArticlesFromAllBatch = {
        ...l_checkedArticleFromOtherBatch,
        ...l_selectedArticles,
      }

      let selectedArticles = {
        ...l_selectedArticlesFromAllBatch,
      }

      Object.keys(selectedArticles).forEach(val => {
        articleOptions.push({
          label: val,
          value: val
        })
      })

      setSelectedArticlesDraftData(Object.values(selectedArticles))

      let isValidationError = validationForAvailableData(selectedArticles,p_articlesWithZeroInvAsUserReserved)
      if(isValidationError) 
      return;
      else{
        // let isTotInvZeroAsAllocated = false;
        // let isTotInvZeroAsUserReserved = false;
        // let isWosZero = false;
        // let isProductProfileNotMapped = false;
        // let selectedArticleIdsWithTotInvZeroAsReserved = []
        // let selectedArticleIdsWithTotInvZeroAsUserReserved = []
        // let selectedArticleIdsWithNoPP = []
        // let selectedArticleIdsWithZeroWOS = []
  
        // Object.keys(selectedArticles)?.map((o) => {
        //   let selectedArticle = selectedArticles[o]
        //   if (selectedArticle.total_inventory == 0 && !p_articlesWithZeroInvAsUserReserved) {
        //     if(Number(selectedArticle.reserve_quantity) !== 0) {
        //       isTotInvZeroAsUserReserved = true;
        //       selectedArticleIdsWithTotInvZeroAsUserReserved.push(selectedArticle.article)
        //     }
        //     else {
        //       isTotInvZeroAsAllocated = true;
        //       selectedArticleIdsWithTotInvZeroAsReserved.push(selectedArticle.article)
        //     }
        //   }
        //   // if (selectedArticle.total_inventory == 0 && (((Number(selectedArticle.oh) + Number(selectedArticle.oo) + Number(selectedArticle.it)) === Number(selectedArticle.allocated_units))  || Number(selectedArticle.allocated_units) !== 0)) {
        //   //   isTotInvZeroAsUserReserved = true;
        //   //   selectedArticleIdsWithTotInvZeroAsUserReserved.push(selectedArticle.article)
        //   // }
        //   if (isEmpty(selectedArticle.product_profile)) {
        //     isProductProfileNotMapped = true;
        //     selectedArticleIdsWithNoPP.push(selectedArticle.article)
        //   }
        //   if (selectedArticle.article_wos == 0) {
        //     isWosZero = true;
        //     selectedArticleIdsWithZeroWOS.push(selectedArticle.article)
        //   }
        // })
        // if (isTotInvZeroAsAllocated) {
        //   setErrorModalMessage(`Total Available Inventory for ${selectedArticleIdsWithTotInvZeroAsReserved} article(s) is zero as it's already allocated!!`)
        //   setArticleWithZeroInv(selectedArticleIdsWithTotInvZeroAsReserved);
        //   // let l_selectedRows = (getNewArticleToBeUnchecked(tableInstance?.rowsById, selectedArticleIdsWithTotInvZeroAsReserved, tableInstance?.state.selectedRowIds))
        //   // tableInstance.state.selectedRowIds = l_selectedRows
        //   // unCheckRowsOnError(selectedArticleIdsWithTotInvZeroAsReserved)
        //   // setInitialSelectedRows(l_selectedRows)
        //   return;
        // }
        // if (isProductProfileNotMapped) {
        //   setErrorModalMessage(`Product Profile for ${selectedArticleIdsWithNoPP} article(s) is not Mapped!!`)
        //   setArticleWithZeroInv(selectedArticleIdsWithNoPP);
        //   // let l_selectedRows = (getNewArticleToBeUnchecked(tableInstance?.rowsById, selectedArticleIdsWithNoPP, tableInstance?.state.selectedRowIds))
        //   // tableInstance.state.selectedRowIds = l_selectedRows
        //   return;
        // }
        // if (isWosZero) {
        //   setErrorModalMessage(`Average WOS for ${selectedArticleIdsWithZeroWOS} article(s) is Zero!!`)
        //   setArticleWithZeroInv(selectedArticleIdsWithZeroWOS);
        //   // let l_selectedRows = (getNewArticleToBeUnchecked(tableInstance?.rowsById, selectedArticleIdsWithZeroWOS, tableInstance?.state.selectedRowIds))
        //   // tableInstance.state.selectedRowIds = l_selectedRows
        //   return;
        // }
        // if (isTotInvZeroAsUserReserved) {
        //   setErrorModalMessage(`Total Available Inventory for ${selectedArticleIdsWithTotInvZeroAsUserReserved} article(s) is zero as user has reserved, Press Continue to edit user reserve or exclude the article!!`)
        //   setArticleWithZeroInvAsUserReserved(selectedArticleIdsWithTotInvZeroAsUserReserved);
        //   setSecondaryButtonLabel("Continue")
        //   // let l_selectedRows = (getNewArticleToBeUnchecked(tableInstance?.rowsById, selectedArticleIdsWithTotInvZeroAsReserved, tableInstance?.state.selectedRowIds))
        //   // tableInstance.state.selectedRowIds = l_selectedRows
        //   // unCheckRowsOnError(selectedArticleIdsWithTotInvZeroAsReserved)
        //   // setInitialSelectedRows(l_selectedRows)
        //   return;
        // }
        if (isInitialDraftCall) {
          let draftsArticleOptions = instance?.data.reviewTableData
            .map(item => ({ label: item.article, value: item.article }))
  
          if (draftsArticleOptions[0].value != Object.keys(instance?.data?.DetailsTableData?.storeTableData)[0]) {
            let index = draftsArticleOptions?.findIndex(op => op.value == Object.keys(instance?.data?.DetailsTableData?.storeTableData)[0])
            let first = draftsArticleOptions[0]
            draftsArticleOptions[0] = draftsArticleOptions[index]
            draftsArticleOptions[index] = first
            articleOptions = draftsArticleOptions
          }
        }
        setReviewBtnDisabled(false);
        setArticles(() => selectedArticles);
        setArticleOptions(() => articleOptions);
        setSelectedArticle(() => articleOptions[0]);
  
        const dcRequest = [];
  
        const request = Object.keys(selectedArticles)?.map((o) => {
          const size_lst = selectedArticles[o].size_desc?.map((o) => o?.value);
          const dc_codes = selectedArticles[o].default_dcs?.map((o) => o?.value);
          const selectedStores = storeCode?.map((o) => o?.value).filter(val => val != "*")
  
          let demandType, productProfileCode, inventorySource
          if (Array.isArray(selectedArticles[o].Demand_Type_Filter_Default)) {
            demandType = selectedArticles[o].Demand_Type_Filter_Default[0]?.value
          }
          else {
            demandType = selectedArticles[o].Demand_Type_Filter_Default?.value
          }
  
          if (Array.isArray(selectedArticles[o].product_profile)) {
            productProfileCode = selectedArticles[o].product_profile[0]?.value
          }
          else {
            productProfileCode = selectedArticles[o].product_profile?.value
          }
  
          inventorySource = selectedArticles[o]?.default_inventory_sorce_filter?.map(val => val?.value)
          let inventoryMapping = {
            "oo": "onorder",
            "oh": "onhand",
            "it": "intransit",
          }

          let selectedInvsource = []
          inventorySource?.forEach((val, index) => {
            if(inventoryMapping[val]) {
              selectedInvsource.push(inventoryMapping[val])
            }          
          })

          let dcReqObj = {
            Size_List: size_lst,
            Product_Code: selectedArticles[o].article,
            Inventory_Source: selectedInvsource,
          }
          if(isPOASNFlow) {
            dcReqObj.source = tableData[0]?.Inventory_Source_Filter[0]?.label
            // dcReqObj.po_ids = poDetails.po_id
            // dcReqObj.asn_id = poDetails.asn_id
  
            if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
              dcReqObj.po_ids = poDetails.po_id
            }
            if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
              dcReqObj.asn_ids = poDetails.asn_id
              dcReqObj.po_ids = poDetails.po_id
            }
            if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
              dcReqObj.po_ids = poDetails.po_id
            }
          }
          else {
            dcReqObj.DC_Codes = dc_codes
          }
          dcRequest.push(dcReqObj);

          let requestObj =  {
            Product_Profile_Code: productProfileCode,
            Product_Code: selectedArticles[o].article,
            Store_Group_Code: selectedArticles[o].default_store_eligibility_default?.map(
              (o) => o?.value
            ),
            Size_List: size_lst,
            Demand_Type: isNaN(selectedArticles[o].final_total_inventory) || selectedArticles[o].final_total_inventory == 0 ? demandType : "Fixed",
            // Delivery_Date: moment(selectedArticles[o]["delivery_date"]).format("YYYY-MM-DD"),
            Inventory_Source: selectedInvsource,
            Original_Total_Inventory: Number(selectedArticles[o].total_inventory),
            Planned_APS: Number(selectedArticles[o].article_aps),
            Planned_WOS: Number(selectedArticles[o].article_wos),
            User_Defined_Inventory: Number(selectedArticles[o].final_total_inventory),
            hasEdited: isNaN(selectedArticles[o].final_total_inventory) ? false : true,
            User_Selected_Stores: selectedStores,
          };
          if(isPOASNFlow) {
            requestObj.source = tableData[0]?.Inventory_Source_Filter[0]?.value;
            // requestObj.po_ids = poDetails.po_id
            // requestObj.asn_id = poDetails.asn_id
            if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
              requestObj.po_ids = poDetails.po_id
            }
            if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
              requestObj.asn_ids = poDetails.asn_id
              requestObj.po_ids = poDetails.po_id
            }
            if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
              requestObj.po_ids = poDetails.po_id
            }
          }
          else {
            requestObj.DC_Codes = dc_codes
          }
          return requestObj
        });
        setStoreRequest(request)
        setDcRequest(dcRequest)
  
        if (!isInitialDraftCall) {
          console.log(checked,'iugyjhytfrcd')
          if(!isEmpty(checked)) {
            shouldFetchRestOfTheArticles(0,checked);
          }
          setCheckAllApisWithSearchTerm(checked)
          // checkAll  && setCallCheckAllApi(true
          // setTableDataForCA(tableInstance?.data)
          props.fetchStoreTableData(request.filter(val => val.Product_Code == articleOptions[0]?.value));
          props.fetchDcTableData(dcRequest.filter(val => val.Product_Code == articleOptions[0]?.value));
        }

      }
      // props.fetchStoreTableData(request);
      // props.fetchDcTableData(dcRequest);
    }
    else {
      Notify.error("Please select atleast 1 product to review!!")
    }
  };

  /**
   * Review Forecast handler function to render Ada Section 
   */
  const handleReviewForecast = () => {
    const { selectedRowIds } = tableInstance.state;
    const { preFilteredRowsById } = tableInstance;
    const { setAdaAllocationRequest, setAdaCurrentDate } = props;
    // const currentDate = moment().format("YYYYWW");
    const currentDate = moment().format("WW"); 
        const currentWeek = (26+parseInt(currentDate))%52
        const currentWeekYear = moment().format("YYYY")
        const data = currentWeekYear.concat(currentWeek)
    setAdaCurrentDate(data);
    const currentDateKey = moment().format("YYYY-MM-DD")
    if (tableInstance && Object.keys(selectedRowIds).length > 0) {
      const selectedArticlesReview = {};
      let articleOptions = [];
      Object.keys(tableInstance.state.selectedRowIds).forEach((rowId) => {
        selectedArticlesReview[tableInstance.preFilteredRowsById[rowId].original.article] =
          tableInstance.preFilteredRowsById[rowId].original;
        articleOptions.push({
          label: tableInstance.preFilteredRowsById[rowId].original.article,
          value: tableInstance.preFilteredRowsById[rowId].original.article,
         });
      });
      const requestData = Object.keys(selectedArticlesReview)?.map((o) => {
        const size_lst = selectedArticlesReview[o].size_desc?.map((o) => o?.value);
        const dc_codes = selectedArticlesReview[o].default_dcs?.map((o) => o?.value);
        const selectedStores = storeCode?.map((o) => o?.value).filter(val => val != "*")
        const user_ID = Number(localStorage.getItem("user_id"))

        let demandType, productProfileCode, inventorySource
        if (Array.isArray(selectedArticlesReview[o].Demand_Type_Filter_Default)) {
          demandType = selectedArticlesReview[o].Demand_Type_Filter_Default[0]?.value
        }
        else {
          demandType = selectedArticlesReview[o].Demand_Type_Filter_Default?.value
        }

        if (Array.isArray(selectedArticlesReview[o].product_profile)) {
          productProfileCode = selectedArticlesReview[o].product_profile[0]?.value
        }
        else {
          productProfileCode = selectedArticlesReview[o].product_profile?.value
        }

        inventorySource = selectedArticlesReview[o]?.default_inventory_sorce_filter?.map(val => val?.value)
        let inventoryMapping = {
          "oo": "onorder",
          "oh": "onhand",
          "it": "intransit"
        }
        let selectedInvsource = []
        inventorySource?.forEach((val, index) => {
          if(inventoryMapping[val]) {
            selectedInvsource.push(inventoryMapping[val])
          }
        })
        let dcReqObj = {
          Size_List: size_lst,
          Product_Code: selectedArticlesReview[o].article,
          Inventory_Source: selectedInvsource,
        }
        if(isPOASNFlow) {
          dcReqObj.source = tableData[0]?.Inventory_Source_Filter[0]?.value;
          // dcReqObj.po_ids = poDetails.po_id
          // dcReqObj.asn_id = poDetails.asn_id

          if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
            dcReqObj.po_ids = poDetails.po_id
          }
          if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
            dcReqObj.asn_ids = poDetails.asn_id
            dcReqObj.po_ids = poDetails.po_id
          }
          if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
            dcReqObj.po_ids = poDetails.po_id
          }
        }
        else {
          dcReqObj.DC_Codes = dc_codes
        }
        dcRequest.push(dcReqObj);


        let requestObj =  {
          Product_Profile_Code: productProfileCode,
          Product_Code: selectedArticlesReview[o].article,
          Store_Group_Code: selectedArticlesReview[o].default_store_eligibility_default?.map(
            (o) => o?.value
          ),
          Size_List: size_lst,
          Demand_Type: isNaN(selectedArticlesReview[o].final_total_inventory) || selectedArticlesReview[o].final_total_inventory == 0 ? demandType : "Fixed",
          // Delivery_Date: moment(selectedArticlesReview[o]["delivery_date"]).format("YYYY-MM-DD"),
          Inventory_Source: selectedInvsource,
          Original_Total_Inventory: Number(selectedArticlesReview[o].total_inventory),
          Planned_APS: Number(selectedArticlesReview[o].article_aps),
          Planned_WOS: Number(selectedArticlesReview[o].article_wos),
          User_Defined_Inventory: Number(selectedArticlesReview[o].final_total_inventory),
          hasEdited: isNaN(selectedArticlesReview[o].final_total_inventory) ? false : true,
          User_Selected_Stores: selectedStores,
          User_ID:user_ID
        };
        if(isPOASNFlow) {
          requestObj.source = tableData[0]?.Inventory_Source_Filter[0]?.value;
          // requestObj.po_ids = poDetails.po_id
          // requestObj.asn_id = poDetails.asn_id
          if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
            requestObj.po_ids = poDetails.po_id
          }
          if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
            requestObj.asn_ids = poDetails.asn_id
            requestObj.po_ids = poDetails.po_id
          }
          if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
            requestObj.po_ids = poDetails.po_id
          }
        }
        else {
          requestObj.DC_Codes = dc_codes
        }
        return requestObj
      });
      props.reviewAda(requestData)
      setReviewBtnDisabled(true);
  
      const articles = {};
      const selectedArticles = [];
      const selectedStoreIds = [];
      Object.keys(selectedRowIds).forEach((rowId) => {
        articles[preFilteredRowsById[rowId].original.article] = {
          store: preFilteredRowsById[rowId].original.store_groups.map(i =>
            storeGroupStoreResult[i.value]).flat(1),
        }

        selectedArticles.push(preFilteredRowsById[rowId].original.article);
        selectedStoreIds.push(preFilteredRowsById[rowId].original.store_groups.map(i =>
          storeGroupStoreResult[i.value]).flat(1));
      });

      const { startFiscalYearWeek, endFiscalYearWeek } = props;
      console.log('filter',request,subdepartment)
      let req = {
        adaReq: {
          start_fw: startFiscalYearWeek,
          end_fw: endFiscalYearWeek,
          current_fw: data,
          current_date:currentDateKey,
          articles: {
            ...articles,
          },
          user_id:Number(localStorage.getItem("user_id")),
          l1_name: [division["value"]],
          l2_name: [department?.value],
          l3_name: request?.subDepartment?.map((val)=>val.value),
          l4_name: request?.level4?.map((val)=>val.value),
          l5_name: request?.level5?.map((val)=>val.value),
          l6_name:request?.level6?.map((val)=>val.value),
          l7_name:request?.level7?.map((val)=>val.value)
        },
        selectedArticles: [...selectedArticles],
        selectedStoreIds: [...selectedStoreIds.flat(1)],
        level1: [division["value"]],
        level2: [department?.value],
        level3: request?.subDepartment?.map((val)=>val.value),
        level4: request?.level4?.map((val)=>val.value),
        level5: request?.level5?.map((val)=>val.value),
        level6: request?.level6?.map((val)=>val.value),
        level7: request?.level7?.map((val)=>val.value),
      };
      
      setAdaDataReq(req)
     
    } else {
      Notify.error(StrategyConsts.REVIEW_FORECAST_NOTIFIER);
    }
  }

  useEffect(()=>{
    const { setAdaAllocationRequest} = props;
   if(props.reviewAdaSuccess===true)
   {
    setForecastRevDisableState(false);
    setAdaAllocationRequest(adaDataReq);
    scrollIntoView(adaVisualRef);
   }
  },[props.reviewAdaSuccess])

  const resetRestFilters = (p_index) => {
    setFactoryType(null)
    if (p_index == 0) {
      department && setDepartment(null);
      setDepartmentOptions([])
      setRequest(old => {
        return {
          ...old,
          'department': null
        }
      })
    }
    if (p_index == 1 || p_index == 0) {
      subdepartment && setSubdepartment(null);
      setSubdepartmentOptions([])
      setRequest(old => {
        return {
          ...old,
          'subDepartment': null
        }
      })
    }
    if (p_index == 2 || p_index == 1 || p_index == 0) {
      level4 && setLevel4(null)
      setLevel4Options([])
      setRequest(old => {
        return {
          ...old,
          'level4': null
        }
      })
    }
    if (p_index == 3 || p_index == 2 || p_index == 1 || p_index == 0) {
      level5 && setLevel5(null);
      setLevel5Options([])
      setRequest(old => {
        return {
          ...old,
          'level5': null
        }
      })
    }
    if (p_index == 4 || p_index == 3 || p_index == 2 || p_index == 1 || p_index == 0) {
      level6 && setLevel6(null);
      setLevel6Options([])
      setRequest(old => {
        return {
          ...old,
          'level6': null
        }
      })
    }

    level7 && setLevel7(null);
    setLevel7Options([])
    setRequest(old => {
      return {
        ...old,
        'level7': null
      }
    })

    !isNull(myInstance) && setMyInstance(null)
    !isNull(selectedArticleStoreConst) && setSelectedArticleStoreConst(null);
    !isNull(selectedArticleDcData) && setSelectedArticleDcData(null);
    !reviewBtnDisabled && setReviewBtnDisabled(true)
    setForecastRevDisableState(true);
    !isEmpty(selectedArticleWithData) && setSelectedArticleWithData([]);
  }

  const reviewChangesHandler = () => {
    const store_list = [];
    const ref_store_list = [];
    const store_idx = [];
    const ros_list = [];
    const wos_list = [];
    const delta_store_flag = [];
    let storeGroupTableInstanceCopy = { ...storeGroupTableInstance }
    storeTableDataCopy[selectedArticle?.value]?.forEach((d) => {
      store_list.push(d.store_code);
      ref_store_list.push(d.ref_store);
      store_idx.push(parseInt(d.Store_Index));
      ros_list.push((d['APS/ROS']));
      wos_list.push((d['WOS']));
      delta_store_flag.push((d['delta_store_flag']))
    });
    const selectedStores = storeCode?.map((o) => o?.value).filter(val => val != "*")

    const request = Object.keys(articles).filter(val => val == selectedArticle?.value)?.map((o) => {

      let demandType, productProfileCode
      if (Array.isArray(articles[o].Demand_Type_Filter_Default)) {
        demandType = articles[o].Demand_Type_Filter_Default[0]?.value
      }
      else {
        demandType = articles[o].Demand_Type_Filter_Default?.value
      }

      if (Array.isArray(articles[o].product_profile)) {
        productProfileCode = articles[o].product_profile[0]?.value
      }
      else {
        productProfileCode = articles[o].product_profile?.value
      }

      if (
        selectedArticle?.value === storeGroupTableInstance.data[0].product_code
      ) {
        return {
          Product_Profile_Code: productProfileCode,
          Planned_APS: articles[o].article_aps,
          Planned_WOS: articles[o].article_wos,
          Store_Group_Code: articles[o].default_store_eligibility_default?.map((o) => o?.value),
          Product_Code: storeGroupTableInstance.data[0].product_code,
          Size_List: articles[o].size_desc?.map((o) => o?.value),
          DC_Codes: articles[o].default_dcs?.map((o) => o?.value),
          Demand_Type: demandType,
          Store_List: store_list,
          Reference_Store_List: ref_store_list,
          Store_Index: store_idx,
          ROS_List: ros_list,
          WOS_List: wos_list,
          delta_store_flag: delta_store_flag,
          User_Selected_Stores: selectedStores,
        };
      }
      return {
        Product_Profile_Code: productProfileCode,
        Planned_APS: articles[o].article_aps,
        Planned_WOS: articles[o].article_wos,
        Product_Code: articles[o].article,
        Store_Group_Code: articles[o].default_store_eligibility_default?.map((o) => o?.value),
        Size_List: articles[o].size_desc?.map((o) => o?.value),
        DC_Codes: articles[o].default_dcs?.map((o) => o?.value),
        Demand_Type: demandType,
        delta_store_flag: delta_store_flag,
        User_Selected_Stores: selectedStores,
      };
    });
    setIsReviewChangesDisabled(true)
    console.log('ss12345',request)
    props.fetchStoreTableData(request);
  };

  const createAllocation = () => {
    setShowCount(0)
    let req = []
    // if (!inputRef?.current?.value) {
    //   Notify.error("Please Enter Allocation Plan Name!!")
    //   inputRef?.current?.focus()
    // }
    // else {
      window.scrollTo(0, window.innerHeight / 2);
      if(!isEmpty(checkAllApisWithSearchTerm)) {
        setCreateAllocationBeforeAllArticles(true);
        console.log('getting data')
      }
      else {
        let isValidationError = validationForRestOfTheData(); 
        if(isValidationError) 
          return;
        else {
          setCreateAllocationBeforeAllArticles(false);
          // setArticleWithZeroWosForRest([]);
          // setArticleWithZeroInvForRest([]);
          req = storeRequest?.map(val => {
            if (selectedArticleWithData.includes(val.Product_Code) || val.Product_Code == articleOptions[0]?.value) {
              if (val.Product_Code == storeGroupTableInstance.data?.[0]?.product_code && isEmpty(storeGroupTableInstance?.state?.selectedRowIds)) {
                return {
                  ...val,
                  "User_Name": localStorage?.getItem("email"),
                  "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
                  ["Modified_Flag"]: true,
                  "Store_List": storeTableDataCopy[val.Product_Code]?.map(val => val["store_code"]),
                  "APS_ROS": storeTableDataCopy[val.Product_Code]?.map(val => val["APS/ROS"]),
                  "Min_Stock": storeTableDataCopy[val.Product_Code]?.map(val => val["min_stock"]),
                  "WOS": storeTableDataCopy[val.Product_Code]?.map(val => val["WOS"]),
                  "onhand": storeTableDataCopy[val.Product_Code]?.map(val => val["onhand"]),
                  "intransit": storeTableDataCopy[val.Product_Code]?.map(val => val["intransit"]),
                  "onorder": storeTableDataCopy[val.Product_Code]?.map(val => val["onorder"]),
                  "Transit_Time": storeTableDataCopy[val.Product_Code]?.map(val => val["transit_time"]),
                  "Allocation_Name": inputRef?.current?.value,
                  "Allocation_Code": '',
                  "Department": division?.value ? division?.value : division?.[0]?.value,
                  "Store_Size_List": storeTableDataCopy[val.Product_Code]?.map(val => {
                    return {
                      "Store": val["store_code"],
                      "Store_Level_Propotion": val["overall_proportion"],
                      "Size": val["size_desc"],
                      "Size_Level_Proportion": val["size_level_proportion"]
                    }
                  })
                }
              }
              else {
                let filterArray = {}
                if (val.Product_Code == storeGroupTableInstance.data?.[0]?.product_code) {
                  filterArray = storeGroupTableInstance?.state?.selectedRowIds
                }
                else if (isEmpty(articleStoreMapping?.[val.Product_Code])) {
                  let length = storeTableDataCopy[val.Product_Code]?.length
                  filterArray = [...Array(length).keys()].reduce((x, y) => (x[y] = 1, x), {})
                }
                else {
                  filterArray = articleStoreMapping?.[val.Product_Code]
                }
                return {
                  ...val,
                  "User_Name": localStorage?.getItem("email"),
                  "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
                  ["Modified_Flag"]: true,
                  "Store_List": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["store_code"]),
                  "APS_ROS": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["APS/ROS"]),
                  "Min_Stock": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["min_stock"]),
                  "WOS": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["WOS"]),
                  "onhand": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["onhand"]),
                  "intransit": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["intransit"]),
                  "onorder": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["onorder"]),
                  "Transit_Time": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => val["transit_time"]),
                  "Allocation_Name": inputRef?.current?.value,
                  "Allocation_Code": '',
                  "Department": division?.value ? division?.value : division?.[0]?.value,
                  "Store_Size_List": storeTableDataCopy[val.Product_Code]?.filter((value, index) => filterArray?.hasOwnProperty(index))?.map(val => {
                    return {
                      "Store": val["store_code"],
                      "Store_Level_Propotion": val["overall_proportion"],
                      "Size": val["size_desc"],
                      "Size_Level_Proportion": val["size_level_proportion"]
                    }
                  })
                }
              }
            }
            else {
              return {
                ...val,
                ["Modified_Flag"]: false,
                "Allocation_Name": inputRef?.current?.value,
                "Allocation_Code": '',
                "Department": division?.value ? division?.value : division?.[0]?.value,
                "User_Name": localStorage?.getItem("email"),
                "User_ID": Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : "",
                // "po_ids": poDetails?.po_id,
                // "asn_ids": poDetails?.asn_id,
                "source": poDetails?.inventory_source && tableData[0]?.Inventory_Source_Filter[0]?.value
              }
            }
          })
          if(poDetails?.inventory_source === "PO" && poDetails?.status === "Pending" ) {
            req.po_ids = poDetails.po_id
          }
          if(poDetails?.inventory_source === "ASN" && poDetails?.status === "Pending" ){
            req.asn_ids = poDetails.asn_id
            req.po_ids = poDetails.po_id
          }
          if(poDetails?.status === "Error" || poDetails?.status === "Mismatch"){
            req.po_ids = poDetails.po_id
          }

          setCreateAllocationReq(req)
          saveDraft()
          props.createAllocation(req,useSSE)
          // props.editDataAda(req)
        }
      }
    // }
      setErrorModalMessage(null)
  };


  const saveQtyChanges = () => {
    setSaveIsDisabled(true)
    let req = dcTableInstace.data?.map((val) => {
      return {
        "article": val.product_code,
        "size": val.size,
        "store_code": val.dc_code,
        "reserve_quantity": val.size?.map(value => val[value + ' '] ? val[value + ' '] : val['Reserve_Quantity_' + value]),
        "inventory_source": val.inventory_source,
        // "reserve_quantity": val.size?.map(value => val['Reserve_Quantity_'+value]?val['Reserve_Quantity_'+value]:0)
      }
    });
    props.updateResQty(req)
  }

  const handleSetAllOk = () => {
    setInitialIndex(0)
    // setTableData(tableInstance?.data)
    if(storeEligibiltyGroupSA || dcSA || inventorySourceSA || productProfileSA || demandTypeSA || userDefinedInvSA !== null || plannedAPSSA !== null || plannedWOSSA !== null)  {
      let checkAllCopy = [...checkAll]
      let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length -1   
      let l_checkAll =checkAllCopy[l_lastValueIndex]   
      if(l_checkAll?.checkAll) {
        let setAllReq = getSetAllReqForNextSetOfData({searchTermReq,sortReq, storeEligibiltyGroupSA,dcSA,inventorySourceSA,productProfileSA,demandTypeSA,userDefinedInvSA,plannedAPSSA,plannedWOSSA})     
        setCheckAllSetAllReq((old) => {
          if(!isEmpty(old)){
            return [
              ...old,
              setAllReq
            ]
          }
          else{
            return[setAllReq]
          }
        })
      }
      else {
        let articles = tableInstance?.selectedFlatRows?.map(val => val?.original?.article)
        if(userEditAfterValidationOnRest){
          setUpdatedArticlesForRest((old) => [...old,...articles])
        }
        setUpdatedArticles((old) => [...old,...articles])
      }
    }

    const updateTableData = (res) => {
      setTableData((oldData) => {
        let newData = cloneDeep(oldData);
        setAllRows.forEach(setAllRow => {
          if (!isEmpty(storeEligibiltyGroupSA)) {
            newData[setAllRow]['default_store_eligibility_default'] = storeEligibiltyGroupSA
            newData[setAllRow]["no_of_stores"] = noOfStores
          }
          if (!isEmpty(storeEligibiltyGroupSA) && res?.[0]?.data?.status) {
            newData[setAllRow]["min_stock"] = !isEmpty(res?.[0]?.data?.data) ? res?.[0]?.data?.data?.filter(val => val.article == newData[setAllRow]['article'])[0].min_stock : 0
            // newData[setAllRow]["delivery_date"] = !isEmpty(res?.[0]?.data?.data) ? new Date(Date.now() + res?.[0]?.data?.data?.filter(val => val.article == newData[setAllRow]['article'])[0].transit_time * 24 * 60 * 60 * 1000) : new Date(Date.now())
          }
          if (!isEmpty(storeEligibiltyGroupSA) && !res?.[0]?.data?.status) {
            newData[setAllRow]["min_stock"] = 0
            // newData[setAllRow]["delivery_date"] = new Date(Date.now())
          }

          if (!isEmpty(storeEligibiltyGroupSA) && res?.[1]?.data?.status) {
            let data = res?.[1]?.data?.data
            if (isEmpty(plannedAPSSA) && plannedAPSSA !== 0) {
              newData[setAllRow]["article_aps"] = !isEmpty(data) ? data?.filter(val => val.article == newData[setAllRow]['article'])[0].aps : 0
            }
            newData[setAllRow]["article_wos"] = !isEmpty(data) ? data?.filter(val => val.article == newData[setAllRow]['article'])[0].wos : 0
          }

          if (!isEmpty(dcSA)) {
            let data = getInvComponent({
              "store_code": dcSA?.map(val => val?.value),
              "data": tableData[setAllRow],
              "size": tableData[setAllRow]?.size_desc?.map(val => val.value),
              "inventory_source": tableData[setAllRow]?.default_inventory_sorce_filter,
            })
            let total_inventory
            if(isAshley()){
              total_inventory = Math.max( Number(data?.oh_map) - (Number(data?.po_map) ? Number(data?.po_map) : 0) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
            }
            else {
              total_inventory = Math.max(Number(data?.it_map) + Number(data?.oh_map) + Number(data?.oo_map) - (Number(data?.rq_map) ? Number(data?.rq_map) : 0) - (Number(data?.au_map) ? Number(data?.au_map) : 0), 0)
            }
            newData[setAllRow]["default_inventory_sorce_filter"] = tableData[setAllRow]?.Inventory_Source_Filter
            newData[setAllRow]["it"] = data?.it_map ? data?.it_map : 0
            newData[setAllRow]["oh"] = data?.oh_map ? data?.oh_map : 0
            newData[setAllRow]["oo"] = data?.oo_map ? data?.oo_map : 0
            newData[setAllRow]["reserve_quantity"] = data?.rq_map ? data?.rq_map : 0
            newData[setAllRow]["total_inventory"] = total_inventory ? total_inventory : 0
            newData[setAllRow]["final_total_inventory"] = '-'
            newData[setAllRow]["edit_total_inventory"] = ''
            newData[setAllRow]["edit_total_inventory_units"] = ''
            newData[setAllRow]["allocated_units"] = data?.au_map ? data?.au_map : 0
          }

          if (!isEmpty(inventorySourceSA)) {
            newData[setAllRow]["default_inventory_sorce_filter"] = inventorySourceSA
            
            let data =  getInvComponent({
              "inventory_source": inventorySourceSA,
              "size": tableData[setAllRow]?.size_desc?.map(val => val.value),
              "store_code": tableData[setAllRow]?.default_dcs?.map(val => val.value),
              "data": tableData[setAllRow],
            })
            let invSorce = 0, oh =0;
             // Because there can be multiple inventory source need to calculate the sum for all
             forEach(inventorySourceSA, item => {
              invSorce += Number(data?.[`${item?.value}_map`])
              oh += Number(data?.[`${item?.value}_map`])
            })
            oh = Math.max(oh, 0)
            newData[setAllRow]["oh"] = oh
            newData[setAllRow]["final_total_inventory"] = '-'
            newData[setAllRow]["edit_total_inventory"] = ''
            newData[setAllRow]["edit_total_inventory_units"] = ''
          }
          if (!isEmpty(dcSA)) {
            newData[setAllRow]["default_dcs"] = dcSA
          }
          !isEmpty(demandTypeSA) && (newData[setAllRow]["Demand_Type_Filter_Default"] = demandTypeSA)
          !isEmpty(productProfileSA) && (productProfileSA?.label == "IA Recommended" ? newData[setAllRow]["product_profile"] = oldData[setAllRow]["product_profiles"].filter(pp => pp.label === "IA Recommended" )[0] : newData[setAllRow]["product_profile"] = productProfileSA)
          if (userDefinedInvSA) {
            newData[setAllRow]['edit_total_inventory'] = userDefinedInvSA
            let final_total_inventory = userDefinedInvSA != 0 ? Math.round(userDefinedInvSA * Number(newData[setAllRow]['total_inventory']) / 100) : '-'
            newData[setAllRow]['final_total_inventory'] = final_total_inventory;
            newData[setAllRow]["edit_total_inventory_units"] = ''
          }
          if (plannedAPSSA || plannedAPSSA === 0) {
            newData[setAllRow]['article_aps'] = plannedAPSSA
          }
          if (plannedWOSSA || plannedWOSSA === 0) {
            newData[setAllRow]['article_wos'] = plannedWOSSA
          }
        })
        return newData;
      });
    }
    setSetAllLoading(true)
    let setAllRows = Object.keys(tableInstance.state.selectedRowIds)
    let initialSelectedRows = {}
    setAllRows.forEach((item, index) => {
      initialSelectedRows[`${item}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
    let articles = setAllRows?.map(val => {
      return tableInstance?.data?.[val]?.article
    })
    let selectedRows = setAllRows?.map(val => {
      return tableInstance?.data?.[val]
    })
    let noOfStores = 0;
    if (!isEmpty(storeEligibiltyGroupSA) || !isEmpty(dcSA)) {
      let checkedStoreGroupCode = storeEligibiltyGroupSA?.map(val => val?.value)
      !isEmpty(storeEligibiltyGroupSA) && (noOfStores = getNoofStores(checkedStoreGroupCode))

      const callApi = async (req, apsReq) => {
        try {
          const result = [!isEmpty(storeEligibiltyGroupSA) ? await Api.post(GET_STORE_GROUP_MIN_STOCK, req.storeReq) : 0,
          (!isEmpty(storeEligibiltyGroupSA) && ((isEmpty(plannedAPSSA) && plannedAPSSA !== 0) || (isEmpty(plannedWOSSA) && plannedWOSSA !== 0))) ? await Api.post(GET_APS, { "data": apsReq }) : 0];
          if ((result[0] != 0 && !result[0]?.data?.status) || (result[1] != 0 && !result[1]?.data?.status)) {
            Notify.error("Error in Set All operation!!")
          }
          else {
            Notify.success("Changes are applied for selected articles!!")
          }
          updateTableData(result);
        }
        catch (err) {
          Notify.error("Error in Set All operation!!")
          updateTableData(null);
        }
      }
      let req = {
        storeReq: {
          "store_group_code": storeEligibiltyGroupSA?.map(val => val?.value),
          "article": articles
        },
        dcReq: {
          "article": articles,
          "store_code": dcSA?.map(val => val?.value)
        },
      }

      let apsReq = selectedRows.map(val => {
        return {
          "article": val.article,
          "store_group_code": storeEligibiltyGroupSA?.map(val => val?.value),
          "size": val.size_desc.map(val => val.value),
          "upc": getUPC(val.upcSizeMapping, val.size_desc.map(val => val.value))
        }
      })
      callApi(req, apsReq);
    }
    else {
      setSetAllLoading(false)
      updateTableData(null);
    }

    setShowSetAll(false);
  }

  const handleSetAllStoreDetailsOk = () => {
    if (apsSA || wosSA) {
      let setAllRows = Object.keys(storeGroupTableInstance.state.selectedRowIds)
      setStoreTableData((oldData) => {
        let newData = cloneDeep(oldData);
        let l_available, l_required, l_row, l_estimatedDemand
        setAllRows.forEach(setAllRow => {
          l_row = newData[selectedArticle?.value][setAllRow]
          l_available = Number(l_row['onhand']) + Number(l_row['onorder']) + Number(l_row['intransit'])
          l_required = (apsSA ? apsSA : l_row['APS/ROS_rounded']) * (wosSA ? wosSA : l_row['WOS_rounded'])
          l_estimatedDemand = zeroDecimal(Math.max(0, Math.max(Number(l_required), l_row['min_stock']) - l_available))
          l_row['estimated_demand'] = l_estimatedDemand;
          if (apsSA) {
            l_row['APS/ROS_rounded'] = apsSA
            l_row['APS/ROS'] = apsSA
          }
          if (wosSA) {
            l_row['WOS_rounded'] = wosSA
            l_row['WOS'] = wosSA
          }
        })
        return newData;
      });


      setStoreTableDataCopy((oldData) => {
        let newData = cloneDeep(oldData);
        let l_available, l_required, l_row, l_estimatedDemand
        setAllRows.forEach(setAllRow => {
          l_row = newData[selectedArticle?.value][setAllRow]
          l_available = Number(l_row['onhand']) + Number(l_row['onorder']) + Number(l_row['intransit'])
          l_required = (apsSA ? apsSA : l_row['APS/ROS_rounded']) * (wosSA ? wosSA : l_row['WOS_rounded'])
          l_estimatedDemand = zeroDecimal(Math.max(0, Math.max(Number(l_required), l_row['min_stock']) - l_available))
          l_row['estimated_demand'] = l_estimatedDemand;
          if (apsSA) {
            l_row['APS/ROS_rounded'] = apsSA
            l_row['APS/ROS'] = apsSA
          }
          if (wosSA) {
            l_row['WOS_rounded'] = wosSA
            l_row['WOS'] = wosSA
          }
        })
        return newData;
      });
    }
    return;
  }

  useEffect(() => {
    if (setAllLoading) {
      setSetAllLoading(false)
    }
  }, [tableData])


  const handleSetAllStoreDetailsCancel = () => {
    setShowSetAllStoreDetails(false)
  }

  const handleSetAllCancel = () => {
    setShowSetAll(false);
  }

  const handleClose = () => {
    showSetAll && setShowSetAll(false);
    showSetAllStoreDetails && setShowSetAllStoreDetails(false)
    setErrorModalMessage(null)
  }

  const handleOk = () => {
    console.log(articlesWithZeroInv,articlesWithZeroInvAsUserReserved,articlesWithZeroInvAsUserReservedForRest,articlesWithZeroInvForRest,'dickbnduhckdbcj')
    if(failedArticleId) {
      let newReq = createAllocationReq.filter(val => !failedArticleId.includes(val.Product_Code))
      setSelectedArticlesDraftData((old) => old.filter(val => !failedArticleId.includes(val.Product_Code)))
      props.createAllocation(newReq,useSSE)
    }
    else if(!isEmpty(articlesWithZeroInv)){
      unCheckRowsOnError(articlesWithZeroInv)
      setArticleWithZeroInv([])
    }
    else if(!isEmpty(articlesWithZeroInvAsUserReserved)){
      unCheckRowsOnError(articlesWithZeroInvAsUserReserved)
      setArticleWithZeroInvAsUserReserved([])
    }
    else if(!isEmpty(articlesWithZeroInvForRest)){
      unCheckRowsOnError(articlesWithZeroInvForRest,true);
      setArticleWithZeroInvForRest([]);
    }
    else if(!isEmpty(articleWithNoPPForRest)){
      unCheckRowsOnError(articleWithNoPPForRest,true)
      setArticleWithNoPPForRest([]);
    }
    else if(!isEmpty(articleWithZeroWosForRest)){
      unCheckRowsOnError(articleWithZeroWosForRest,true)
      setArticleWithZeroWosForRest([]);
    }
    else if(!isEmpty(articleWithNoSEGForRest)){
      unCheckRowsOnError(articleWithNoSEGForRest,true)
      setArticleWithNoSEGForRest([]);
    }
    else if(!isEmpty(articleWithNoDCForRest)){
      unCheckRowsOnError(articleWithNoDCForRest,true)
      setArticleWithNoDCForRest([]);
    }
    else if(!isEmpty(articleWithNoFixedUserInvForRest)){
      unCheckRowsOnError(articleWithNoFixedUserInvForRest,true)
      setArticleWithNoFixedUserInvForRest([]);
    }
    else if(!isEmpty(articlesWithZeroInvAsUserReservedForRest)){
      unCheckRowsOnError(articlesWithZeroInvAsUserReservedForRest,true)
      setArticleWithZeroInvAsUserReservedForRest([]);
    }
  }

  const handleCancel = () => {
    setErrorModalMessage(null)
    if(!isEmpty(articlesWithZeroInvAsUserReserved)){
      setArticleWithZeroInvAsUserReserved([])
      populateStoreDetailsDropdown(false,true,null,articlesWithZeroInvAsUserReserved)
    }
    else if(!isEmpty(articlesWithZeroInvForRest) || !isEmpty(articleWithNoPPForRest) || !isEmpty(articleWithZeroWosForRest) || !isEmpty(articleWithNoSEGForRest) || !isEmpty(articleWithNoDCForRest) || !isEmpty(articleWithNoFixedUserInvForRest)){
      let l_articles = Object.keys(articles), l_newAction = {}
      l_newAction["checkedRows"] = l_articles
      setCheckAllForRest([l_newAction])
      setUserEditAfterValidationOnRest(true)
    }
  }

  useEffect(() => {
    console.log(selectedArticleStoreConst, 'sdkcdus')
    console.log(checkAllForRest,'iugyjtcry')
  }, [checkAllForRest])

  useEffect(() => {
    console.log(selectedArticleStoreConst, 'sdkcdus')
  }, [selectedArticleStoreConst])

  const allocationNameChangeHandler = (val) => {
    setAllocationName(val)
  }

  const showDrafts = () => {
    setShowSetAll(false)
    setShowSetAllStoreDetails(false)
    setHideDcStoreSection(false)
    setHideMainTable(false)
    resetRestFilters(0)
    setDivision(null);
    setTableData([])
    if (isShowDrafts) {
      setShouldDisplayFilter(true)
      setAllocationName('')
      inputRef.current.value = ''
      setIsContinueAllocation(false)
      setCurrentDraftallocationCode("")
      setIsFirstDraftsCall(false)
    }
    else {
      setShouldDisplayFilter(false)
    }
    setShowDrafts(!isShowDrafts)

  }

  const setDraftFilters = (filters, filterOptions) => {
    setDivision(filters.level1[0])
    setDepartment(filters?.level2?.length && filters.level2)
    setSubdepartment(filters?.level3?.length && filters.level3)
    setLevel4(filters?.level4?.length && filters.level4)
    setLevel5(filters?.level5?.length && filters.level5)
    setLevel6(filters?.level6?.length && filters.level6)
    setLevel7(filters?.level7?.length && filters.level7)
    setSeg(filters?.store_groups?.length && filters?.store_groups)
    setStoreCode(filters?.storeCode?.length && filters?.storeCode)
    filterOptions.divisionOptions?.length && setDivisionOptions(filterOptions.divisionOptions)
    filterOptions.departmentOptions?.length && setDepartmentOptions(filterOptions.departmentOptions)
    filterOptions.subdepartmentOptions?.length && setSubdepartmentOptions(filterOptions.subdepartmentOptions)
    filterOptions.level4Options?.length && setLevel4Options(filterOptions.level4Options)
    filterOptions.level5Options?.length && setLevel5Options(filterOptions.level5Options)
    filterOptions.level6Options?.length && setLevel6Options(filterOptions.level6Options)
    
    setRequest({
      division: filters.level1[0],
      department: filters?.level2,
      subDepartment: filters?.level3,
      level4: filters?.level4,
      level5: filters?.level5,
      level6: filters?.level6,
      level7: filters?.level7,
      store_groups: filters?.store_groups
    })
    filters?.start_date && setDates(moment(filters?.start_date),moment(filters?.end_date))
    filters?.start_date_last_allocated && setAllocationDates(moment(filters?.start_date_last_allocated), moment(filters?.end_date_last_allocated))
  }

  const saveDraft = (isNewDraft, isSaveDraft) => {
    // setIsLoading(false)
    // if (!inputRef?.current?.value) {
    //   Notify.error("Please Enter Allocation Plan Name!!")
    //   inputRef?.current?.focus()
    // }
    // else {
      let user_id = Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : ""
      let allocation_id
      if (!currentDraftallocationCode || isNewDraft) {
        let level1Value = Array.isArray(division) ? division[0].value.slice(0, 3) : division.value.slice(0, 3)
        allocation_id = `${level1Value}_${user_id}_${moment().unix()}`
      }
      let draftFilters = {}
      Object.keys(selectedFilters).forEach(key => {
        if (selectedFilters[key]) {
          draftFilters[key] = selectedFilters[key]
        }
      })
      // let storeTableDraftData = cloneDeep(storeTableDataCopy)
      // let selectedStores = storeGroupTableInstance?.selectedFlatRows?.map(item => item.original)
      // if(selectedStores?.length) {
      //   for(let key in storeTableDraftData) {
      //     storeTableDraftData[key] = selectedStores
      //   }
      // }
      let draftData = {
        data: {
          reviewTableData: selectedArticlesDraftData,
          DetailsTableData: { storeTableData: storeTableDataCopy, dcTableData },
          filters: {...draftFilters, storeCode},
          filterOptions: {
            divisionOptions,
            departmentOptions,
            level4Options,
            level5Options,
            level6Options,
            subdepartmentOptions
          }
        },
        user_id,
        allocation_id: currentDraftallocationCode && !isNewDraft ? currentDraftallocationCode : allocation_id,
        allocation_name: inputRef?.current?.value ? inputRef?.current?.value : (currentDraftallocationCode && !isNewDraft) ? currentDraftallocationCode : allocation_id
      }
      if (!currentDraftallocationCode || isNewDraft) {
        setCurrentDraftallocationCode(allocation_id)
      }
      if(!isSaveDraft) {
        draftData["user_created"] = false
      }
      let data = cloneDeep(draftData)
      props.saveDraft(data)
    // }
  }

  const validatePlanName = () => {
    setIsSaveDraft(true)
    setArticleStoreMapping({})
    if(props?.location?.state?.isBackFromFinalize) {
      setCurrentDraftallocationCode("")
      saveDraft(true, true)
    }
    else if (isContinueAllocation) {
      saveDraft(false, true)
    }
    else {
      if (!inputRef?.current?.value) {
        Notify.error("Please Enter Allocation Plan Name!!")
        inputRef?.current?.focus()
      }
      else {
        setIsLoading(true)
        if (inputRef?.current?.value) {
          props.validatePlanName({ planname: inputRef?.current?.value })
        }
        else {
        //   saveDraft(true, true)
        // }
        if(isEmpty(checkAllApisWithSearchTerm)){
          saveDraft(true, true)
        }
      }
    }
  }

  }

  useEffect(() => {
    if (props.draftNameError) {
      setErrorModalMessage(props.draftNameError)
      setButtonLabel("Ok")
    }
    else if (props.draftNameSuccess && isEmpty(checkAllApisWithSearchTerm)) {
      saveDraft(true, true)
    }
  }, [props.draftNameError, props.draftNameSuccess])

  useEffect(() => {
    setIsLoading(false)
    if (props.saveDraftData?.status && isSaveDraft) {
      setAllocationName('')
      inputRef.current.value = ''
      setIsNewDraftSaved(true)
      Notify.success(props.saveDraftData?.message + "!!")
      // setShowDrafts(true)
      // setShouldDisplayFilter(false)
      // setHideDcStoreSection(true)
      // setHideMainTable(true)
      // setTableData([])
      // setDcTableData([])
      // setStoreTableData([])
      setTimeout(() => {
        setIsSaveDraft(false)
      }, 3000)
    }
    else if (!props.saveDraftData?.status && props?.saveDraftData?.message && isSaveDraft) {
      Notify.error(props?.saveDraftData?.message)
      setTimeout(() => {
        setIsSaveDraft(false)
      }, 3000)
    }

    // setTimeout(() => {
    //   props.resetSaveDraftData()
    // }, 3000)
  }, [props.saveDraftData])

  const continueAllocation = (instance) => {
    setisFirstCall(true);
    props.resetStyleIndex();
    props.resetStyleIndexForCA()
    setTableInstance(null);
    setTableData([]);
    setSearchTermReq({})
    setSortReq({})
    setCheckAllSetAllReq([])
    setUpdatedArticles([])
    setUpdatedArticlesForRest([])
    setUpdatedRows([])
    setUpdatedRowsForRest([])
    callCheckAllApi && setCallCheckAllApi(false);
    createAllocationBeforeAllArticles &&  setCreateAllocationBeforeAllArticles(false);
    setCheckAllApisWithSearchTerm([]);
    setCheckedArticles([]);
    setUnCheckedArticles([]);
    setCheckedRows([]);
    setCheckedRowsForRest([]);
    setCheckAll([]);
    setCheckAllForRest([]);
    setPrevAction("");
    setPrevActionForRest("")
    setUserEditAfterValidationOnRest(false);
    setArticles({})
    setPrevIndex(0);
    setPageIndex(0);
    setErrorModalMessage(null)
    setShowSetAll(false)
    setInitialIndex(0)
    // setArticleStoreMapping({})
    // setDcTableData([])
    // setStoreTableData([])
      setArticleWithZeroInv([])
      setArticleWithZeroInvAsUserReserved([])
      setArticleWithZeroInvForRest([])
      setArticleWithZeroInvAsUserReservedForRest([])
      setPrevCount(0)
    setDraftFilters(instance.data.filters, instance.data.filterOptions)
    setSelectedFilters(instance.data.filters)
    setHideDcStoreSection(false)
    setHideMainTable(false)
    setDraftInstance(instance)
    setIsContinueAllocation(true)
    setSspForDraft(true)
    props.fetchStrategyTableData({...instance.data.filters,"styleIndex":0});
    setIsLoading(true)
    let initialSelectedRows = {}

    instance.data.reviewTableData.forEach((item, index) => {
      initialSelectedRows[`${index}`] = true
    })
    setInitialSelectedRows(initialSelectedRows)
    // setArticleOptions(() => options);
    // setSelectedArticle(() => options[0]);
    setCurrentDraftallocationCode(instance.allocation_code)
    setAllocationName(instance.allocation_code !== instance.allocation_name ? instance.allocation_name : "")
    inputRef.current.value = instance.allocation_code !== instance.allocation_name ? instance.allocation_name : ""
    // setTableData(instance.data.reviewTableData)

    setSelectedArticlesDraftData(instance.data.reviewTableData)

    // setArticles(() => options);
  }
  const onDemandTypeSAChange = (val) => {
    if (val.value === "IA") {
      setPlannedAPSDisabled(true)
      setPlannedAPSSA(null)
    }
    else {
      setPlannedAPSDisabled(false)
    }
    setDemandTypeSA(val);
  };

  const onProductProfileSAChange = (val) => {
    setProductProfileSA(val);
  };

  const onStoreEligibiltyGroupSAChange = (val) => {
    let value = intersectionWith(val, tableData?.[0]?.store_groups, isEqual);
    setstoreEligibiltyGroupSA(value)
  }
  const onInventorySourceSAChange = (val) => {
    // let value = intersectionWith(val, tableData?.[0]?.Inventory_Source_Filter, isEqual);
    // setInventorySourceSA(value)
    setInventorySourceSA(val)
  }
  const onDcSAChange = (val) => {
    let value = intersectionWith(val, tableData?.[0]?.dcs, isEqual);
    setDcSA(value)
  }

  const onUserDefinedInvSAChange = (val) => {
    setUserDefinedInvSA(val)
  }

  const onPlannedAPSSAChange = (val) => {
    setPlannedAPSSA(val)
  }

  const onPlannedWOSSAChange = (val) => {
    setPlannedWOSSA(val)
  }

  const getIntersectionOptions = (p_filter) => {
    let setAllRows = tableInstance?.state?.selectedRowIds && Object.keys(tableInstance?.state?.selectedRowIds)?.map(val => {
      return tableData?.[val]?.[p_filter]
    })
    console.log(p_filter,tableInstance,tableData,'CDSCDSCDS')

    // Using _.intersectionWith() method
    let newArray = setAllRows?.length && intersectionWith(
      ...setAllRows, isEqual);
    //pusing IA recommended pp explicitely as it has unique id's for each articles
    if (p_filter == 'product_profiles')
      Array.isArray(newArray) && (Object.keys(tableInstance?.state?.selectedRowIds).length != 1) && newArray?.push({ label: 'IA Recommended', value: 'IA Recommended' })
    if (p_filter == 'Inventory_Source_Filter') {
      newArray = INVENTORY_SOURCE_FILTER_DATA;
      // Remove the PO option from the normal flow and vise versa
      if (isPOASNFlow) {
        newArray = newArray.filter( inv => inv.value === "po")
      }
      else {
        newArray = newArray.filter( inv => inv.value !== "po")
      }
    }
    return newArray;
  }
  const getInventorySourceName = (source) => {
    return source ? INVENTORY_SOURCE_FILTER_DATA?.filter(val => val.value === source)[0]?.label : null;
  }

  const shouldDisabled = (p_filter) => {
    let selectedRows = tableInstance?.state?.selectedRowIds && Object.keys(tableInstance?.state?.selectedRowIds)
    let bool = false
    if (selectedRows?.length) {
      for (let i of selectedRows) {
        let value = tableInstance?.data?.[Number(i)]?.[p_filter]
        if (Array.isArray(value)) {
          if (value[0]?.value === "IA") {
            bool = true
            break;
          }
        }
        else {
          if (value?.value === "IA") {
            bool = true
            break;
          }
        }
      }
    }
    setPlannedAPSDisabled(bool);
  }

  const prepInput = React.useMemo(() => [
    {
      id: 1,
      isRequired: false,
      label: "APS/User Defined ROS",
      name: "aps",
      className: "setAll__input__center",
      value: apsSA,
      onChange: (e) => setapsSA(e.target.value),
      input: true,
      type: "number",
      min: 0,
    },
    {
      id: 2,
      isRequired: false,
      label: "WOS",
      name: "wos",
      className: "setAll__input__center",
      value: wosSA,
      onChange: (e) => setwosSA(e.target.value),
      input: true,
      type: "number",
      min: 0,
    },
  ], [apsSA, wosSA])

  const prepFilter = React.useMemo(() => [
    {
      id: 1,
      isRequired: false,
      label: "Store eligibility Group",
      name: "storeEligibilityGroup",
      className: "setAll__filter",
      dropdownValue: storeEligibiltyGroupSA,
      onDropDownChange: onStoreEligibiltyGroupSAChange,
      placeHolder: `Select Store eligibility Group`,
      optionsArray: getIntersectionOptions('store_groups'),
      isMultiSelect: true,
      hideSelectAll: true,
    },
    {
      id: 2,
      isRequired: false,
      label: "DC",
      name: "dc",
      className: "setAll__filter",
      dropdownValue: dcSA,
      onDropDownChange: onDcSAChange,
      placeHolder: `Select DC`,
      optionsArray: getIntersectionOptions('dcs'),
      isMultiSelect: true,
      hideSelectAll: true,
      isDisabled: isPOASNFlow
    },
    {
      id: 3,
      label: "Inv. Source",
      name: "invSource",
      className: "setAll__filter",
      dropdownValue: inventorySourceSA,
      onDropDownChange: onInventorySourceSAChange,
      placeHolder: `Select Inv. Source`,
      optionsArray: getIntersectionOptions('Inventory_Source_Filter'),
      isMultiSelect: true,
      hideSelectAll: true,
    },
    {
      id: 5,
      label: "Product Profile",
      name: "productProfile",
      className: "setAll__filter",
      value: productProfileSA,
      onChange: onProductProfileSAChange,
      placeHolder: `Select Product Profile`,
      options: getIntersectionOptions('product_profiles'),
    },
    {
      id: 4,
      label: "Demand Type",
      name: "demandType",
      className: "setAll__filter",
      value: demandTypeSA,
      onChange: onDemandTypeSAChange,
      placeHolder: `Select Demand Type`,
      options: getIntersectionOptions('Demand_Type_Filter'),
    },
    {
      id: 6,
      isRequired: false,
      label: "User-Defined Inv in %",
      name: "userDefinedInv",
      className: "setAll__input",
      value: userDefinedInvSA,
      onChange: (e) => {
        let val = e.target.value
        if (val < 0) {
          val = 0
        }
        if (val > 100) {
          val = 100
        }
        onUserDefinedInvSAChange(Math.round(val))
      },
      input: true,
      type: "number",
      min: 0,
      max: 100
    },
    {
      id: 7,
      isRequired: false,
      label: "Planned APS",
      name: "plannedAPS",
      className: "setAll__input",
      value: plannedAPSSA,
      onChange: (e) => {
        let val = e.target.value
        if (val < 0) {
          val = 0
        }
        onPlannedAPSSAChange(Math.round(val))
      },
      disabled: plannedAPSDisabled,
      input: true,
      type: "number",
      min: 0
    },
    // {
    //   id: 8,
    //   isRequired: false,
    //   label: "Average WOS",
    //   name: "plannedWOS",
    //   className: "setAll__input",
    //   value: plannedWOSSA,
    //   onChange: (e) => {
    //     let val = e.target.value
    //     if (val < 0) {
    //       val = 0
    //     }
    //     onPlannedWOSSAChange(val)
    //   },
    //   input: true,
    //   type: "number",
    //   min: 0
    // },
  ], [storeEligibiltyGroupSA, inventorySourceSA, plannedAPSDisabled, dcSA, demandTypeSA, productProfileSA, userDefinedInvSA, plannedAPSSA, plannedWOSSA, tableInstance?.state?.selectedRowIds]);

  const handleAlertOk = () => {
    reviewChangesHandler()
    setIsShowAlert(false)
  }

  const handleAlertCancel = () => {
    setIsShowAlert(false)
  }

  const handleAlertClose = () => {
    setIsShowAlert(false)
  }

  const resetAlert = () => {
    setShowStoreAlert(false)
    setStoreAlertMessage(null)
  }

  const handleStoreAlertOk = () => {
    populateStoreDetailsDropdown(false, true, null);
    resetAlert()
  }

  const handleStoreAlertCancel = () => {
    resetAlert()
  }

  const handleStoreAlertClose = () => {
    resetAlert()
  }

  //Function to Notify on review and save operations
  const { isReviewReq, isReviewSuccess, isReviewFailed, isSaveReq, isSaveSuccess, isSaveFailed, isAdaAllocationModeSelected } = props;
  useEffect(() => {
    if (isAdaAllocationModeSelected) {
      if (!isReviewReq && !isReviewFailed && isReviewSuccess && !isSaveReq && !isSaveFailed) {
        Notify.success('Review Successful!!');
      } else if (!isReviewReq && isReviewFailed && !isReviewSuccess && !isSaveReq && !isSaveFailed) {
        Notify.error('Review Failed!!');
      } else if (!isSaveReq && isSaveSuccess && !isSaveFailed) {
        Notify.success('Save Successful!!');
      } else if (!isSaveReq && !isSaveSuccess && isSaveFailed) {
        Notify.error('Save Failed!!');
      }
    } else {
      //No code here
    }
  }, [isReviewSuccess, isReviewFailed, isSaveSuccess, isSaveFailed]);

  const getLoaderMessage = () => {
    let loadingMsg = '';
    isReviewReq ? loadingMsg = 'Reviewing...'
      : (isSaveReq ? loadingMsg = 'Saving...' : loadingMsg = 'Loading...');
    return loadingMsg;
  }

  const setDates = (p_startDate, p_endDate, p_static) => {
    // if(isEdit){
    setIsEditing(true)
    // props.onChanged('levelFilter')
    // }
    setStartDate(p_startDate);
    setEndDate(p_endDate);
  }

  const setAllocationDates = (p_startDate, p_endDate, p_static) => {
    setIsEditing(true)
    setAllocationStartDate(p_startDate);
    setAllocationEndDate(p_endDate);
  }

  const isOutsideRange = day => !day;

  const fetchData = (index) => {
    if((prevIndex >= index  || index < pageIndex) || props.out_of_data)
    return;
    retainCheckboxState()
    setPrevIndex(index)
    setInitialIndex(Math.floor(pageIndex/recordsPerPage))
    props.fetchStrategyTableData({
      ...selectedFilters,
      "article":stylesFromDashboard,
      "styleIndex": props.styleIndex,
      "searchColumns":searchTermReq,
      "sortColumn":sortReq,
      "set_all":checkAllSetAllReq,
      start_date: min_date ? moment(min_date).format('YYYY-MM-DD') : null,
      end_date: max_date ? moment(max_date).format('YYYY-MM-DD') : null,
    });
  }

  const fecthDataWithSorting = (p_sort) => {
    if(!isEmpty(sortReq))
        isFirstCall && setisFirstCall(false)
    setSortReq(p_sort)
  }

  useEffect(() => {
    setPageIndex(props.pageIndex)
  },[ props.pageIndex])
  

  //To handle coming back from finalize
  useEffect(() => {
    if(props?.location?.state?.isBackFromFinalize) {
      setBackFromFinalize(true)
      props.getDrafts({
        "allocation_code": props?.location?.state?.allocationCode, 
        "user_id": localStorage.getItem("user_id"),
        "user_created": false
      })
    }
  }, [props?.location?.state?.isBackFromFinalize]) 

  useEffect(() => {
    if(isBackFromFinalize && props.draftsData?.length) {
      continueAllocation(props.draftsData.filter(item => item.allocation_code == props?.location?.state?.allocationCode)[0])
      setBackFromFinalize(false)
    }
  }, [props.draftsData])

  return (
    <main className={StrategyCSS.strategyContainer}>
      <PageLoader loader={props.storeDataLoading || isLoading || createAllocationBeforeAllArticles || props.draftsDataLoading || props.saveDraftLoading} gridLoader={true}>
      {/* <Notification /> */}
      {isShowAlert && <ConfirmationAlert
        modalStyle={{ width: '45rem' }}
        message="APS values will be overwritten, Are you sure you want to continue?"
        handleOk={handleAlertOk}
        handleCancel={handleAlertCancel}
        handleClose={() => handleAlertClose()} />}
      {isShowStoreAlert && storeAlertMessage && <ConfirmationAlert
        message={storeAlertMessage}
        handleOk={() => handleStoreAlertOk()}
        handleCancel={() => handleStoreAlertCancel()}
        handleClose={() => handleStoreAlertClose()} />}
      {showSetAll && <SetAllModal modalBodyStyle={{ height: '45rem' }} filter={prepFilter} handleOk={handleSetAllOk} handleCancel={handleSetAllCancel} handleClose={() => handleClose()} />}
      {errorModalMessage && <ErrorModal message={errorModalMessage} buttonLabel={buttonLabel} secondaryButtonLabel={secondaryButtonLabel} handleCancel={() => handleCancel()} handleOk={() => handleOk()} handleClose={() => handleClose()} />}
      {showSetAllStoreDetails && <SetAllModal filter={prepInput} handleOk={handleSetAllStoreDetailsOk} handleCancel={handleSetAllStoreDetailsCancel} handleClose={() => handleClose()} />}
      <div className="container__header">
        <h1 className="fnt-lg fnt-bold">Create New Allocation</h1>
      </div>
      <section className={classnames("row", StrategyCSS.card)}>
        <div className="col-md-3">
          <label className="fnt-md fnt-bold fnt-bold">Allocation Plan Name</label>
          <input
            className="form-input form-control w-auto"
            type={"text"}
            ref={inputRef}
            placeholder="Allocation Plan Name"
          />
        </div>
       {!isPOASNFlow  && (<div className="col-md-3">
          <button
            onClick={showDrafts}
            className={classNames("btn", "btn-progress", "filter-constraints-mode",
              isShowDrafts ? "active" : "")}
          >
            <span style={{ fontWeight: 'bolder', fontSize: '1.2rem' }}>{isShowDrafts ? "Close" : "Show"} WIP Allocations</span>
          </button>
        </div>)}
      </section>
      {
        isShowDrafts &&

        <section
          className={classnames(StrategyCSS.tableSection, StrategyCSS.card)}
          id="strategy-page-table"
        >
          <div className={StrategyCSS.table}>
            <Drafts continueAllocation={continueAllocation} isNewDraftSaved={isNewDraftSaved} setIsNewDraftSaved={setIsNewDraftSaved}
              isFirstDraftsCall={isFirstDraftsCall} />
          </div>
        </section>
      }
      {shouldDisplayFilter && <section className={classnames("row", StrategyCSS.card)}>
        <div className="col-md-3 required mb-2">
          <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1 || level1Label}</label>
          <Select
            name="department"
            value={division}
            onChange={onDivisionChange}
            isDisabled={disableFilters}
            placeHolder={props.filterLabels?.level1}
            options={divisionOptions?.length ? divisionOptions : []}
          />
        </div>
        <div className={`col-md-3 ${isMandatory("l2_name")} mb-2`}>
          <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level2 || level2Label}</label>
          <MultiSelect
            name="level2"
            dropdownValue={department}
            isDisabled={disableFilters || !division}
            onDropDownChange={onDepartmentChange}
            placeHolder={`Select ${props.filterLabels?.level2 ? props.filterLabels?.level2 : ""}`}
            optionsArray={!isEmpty(departmentOptions) ? departmentOptions : []}
          />
        </div>
        {
          !shouldHideForCurrentClient("l3_name") &&
          <>
        <div className={`col-md-3 ${isMandatory("l3_name")} mb-2`}>
        <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level3 || level3Label}</label>
          <MultiSelect
            name="level3"
            dropdownValue={subdepartment}
            isDisabled={disableFilters}
            onDropDownChange={onSubdepartmentChange}
            placeHolder={`Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`}
            optionsArray={!isEmpty(subdepartmentOptions)? subdepartmentOptions: []}
          />
        </div>
        <div className="col-md-3 fill">
          <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level4 || level4Label}</label>
          <MultiSelect
            name="level4"
            dropdownValue={level4}
            isDisabled={disableFilters}
            onDropDownChange={onLevel4Change}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(level4Options)
                ? level4Options
                : []
            }
          />
        </div>
        {
          !shouldHideForCurrentClient("l5_name") &&
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level5 || level5Label}</label>
            <MultiSelect
              name="level5"
              dropdownValue={level5}
              isDisabled={disableFilters}
              onDropDownChange={onLevel5Change}
              placeHolder={'Select '}
              optionsArray={
                !isEmpty(level5Options)
                  ? level5Options
                  : []
              }
              
            />
          </div>
        }
        {
          !shouldHideForCurrentClient("l6_name") &&
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level6 || level6Label}</label>
            <MultiSelect
              name="level6"
              dropdownValue={level6}
              isDisabled={disableFilters}
              onDropDownChange={onLevel6Change}
              placeHolder={'Select '}
              optionsArray={
                !isEmpty(level6Options)
                  ? level6Options
                  : []
              }
              
            />
          </div>
        }
        {
          !shouldHideForCurrentClient("l7_name") &&
          <div className="col-md-3 fill">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level7 || level7Label}</label>
            <MultiSelect
              name="level7"
              dropdownValue={level7}
              isDisabled={disableFilters}
              onDropDownChange={onLevel7Change}
              placeHolder={'Select '}
              optionsArray={
                !isEmpty(level7Options)
                  ? level7Options
                  : []
              }
              
            />
          </div>
        }
        </>
}

        {/* <div className="col-md-3">
          <label className="fnt-md fnt-bold fnt-bold">Store Name</label>
          <MultiSelect
            name="storeId"
            dropdownValue={storeName}
            isDisabled={disableFilters}
            onDropDownChange={onStoreNameChange}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(storeNameOptions) ? storeNameOptions : []
            }
          />
        </div> */}

        <div className="col-md-3">
          <label className="fnt-md fnt-bold fnt-bold">Store Number</label>
          <MultiSelect
            name="storeId"
            dropdownValue={storeCode}
            isDisabled={disableFilters}
            onDropDownChange={onStoreCodeChange}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(storeCodeOptions) ? storeCodeOptions : []
            }
            isBulkSearch
          />
        </div>

        <div className="col-md-3" style={{marginTop:"9px"}}>
          <label className="fnt-md fnt-bold fnt-bold">Factory Type</label>
          <MultiSelect
            name="factoryType"
            dropdownValue={factoryType}
            isDisabled={disableFilters}
            onDropDownChange={onFactoryTypeChange}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(factoryTypeOptions) ? factoryTypeOptions : []
            }
          />
        </div>
         <div className="col-md-3"  style={{marginTop:"9px"}}>
          <label className="fnt-md fnt-bold fnt-bold">Store Eligibility Groups</label>
          <MultiSelect
            name="seg"
            dropdownValue={seg}
            isDisabled={disableFilters}
            onDropDownChange={onSEGChange}
            placeHolder={'Select '}
            optionsArray={
              !isEmpty(segOptions) ? segOptions : []
            }
          />
        </div>
         <div className="col-md-3" style={{marginTop:"9px"}}>
          <label className="fnt-md fnt-bold fnt-bold ">Introduction Date</label>
          <DateRangePicker
            startDate={min_date}
            startDateId="s_id"
            endDate={max_date}
            endDateId="e_id"
            onDatesChange={({ startDate, endDate }) => {
              setDates(startDate, endDate, "Static");
            }}
            focusedInput={focusedInput}
            onFocusChange={(e) => setFocusedInput(e)}
            displayFormat="MM-DD-YYYY"
            isOutsideRange={isOutsideRange}
            showClearDates
            withPortal
            numberOfMonths={3}
          />
        </div>
        <div className="col-md-3" style={{marginTop:"9px"}}>
          <label className="fnt-md fnt-bold fnt-bold no-wrap">
            Last Allocation (Dates Not Within)
          </label>
          <DateRangePicker
            startDate={min_allocation_date}
            startDateId="sa_id"
            endDate={max_allocation_date}
            endDateId="ea_id"
            onDatesChange={({ startDate, endDate }) => {
              setAllocationDates(startDate, endDate, "Static");
            }}
            focusedInput={focusedInputAllocationDate}
            onFocusChange={(e) => setFocusedInputAllocationDate(e)}
            displayFormat="MM-DD-YYYY"
            isOutsideRange={disableFutureDates}
            showClearDates
            withPortal
            numberOfMonths={3}
            minimumNights={0}
          />
        </div> 
        <div className="col-md-3 mt-2">
          <button
            disabled={disableFilters}
            onClick={applyFilters}
            className="btn btn-primary filter-constraints-mode"
            title="Apply filters"
          >
            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
          </button>
          <button
            disabled={disableFilters}
            onClick={() => {
              setInitialIndex(0)
              setRequest(null);
              setDivision(null);
              setStoreCode(null);
              setSeg(null);
              setFactoryType(null);
              setDepartment(null);
              setSubdepartment(null);
              setLevel4(null);
              setLevel5(null);
              setLevel6(null);
              setLevel7(null);
              setStartDate(null);
              setEndDate(null);
              setAllocationStartDate(null);
              setAllocationEndDate(null);
              setDepartmentOptions([]);
              setLevel4Options([]);
              setLevel5Options([]);
              setFactoryTypeOptions([]);
              setSubdepartmentOptions([]);
              setSelectedArticleStoreConst(null);
              setSelectedArticleDcData(null);
              setReviewBtnDisabled(true)
              setForecastRevDisableState(true);
              setSelectedArticleWithData([]);
              setMyInstance(null)
              setErrorModalMessage(null)
              setShowSetAll(false)
              setShowDrafts(false)
              setIsContinueAllocation(false)
              setSspForDraft(false)
              setHideDcStoreSection(false)
              setHideMainTable(false)
              setArticleStoreMapping({})
              setAllocationName('');
              setUpdatedArticles([]);
              setUpdatedArticlesForRest([]);
              setUpdatedRows([])
              setUpdatedRowsForRest([])
              callCheckAllApi && setCallCheckAllApi(false);
              setCheckedArticles([]);
              setUnCheckedArticles([]);
              setCheckedRows([]);
              setCheckedRowsForRest([]);
              setPrevAction("")
              setPrevActionForRest("")
              setCheckAll([]);
              setCheckAllForRest([]);
              setUserEditAfterValidationOnRest(false);
              setArticles({})
              setTableData([])
              setTableInstance(null)
              setCreateAllocationBeforeAllArticles(false);
              setCheckAllApisWithSearchTerm([]);
              setArticleWithZeroInv([])
              setArticleWithZeroInvAsUserReserved([])
              setArticleWithZeroInvForRest([])
              setArticleWithZeroInvAsUserReservedForRest([])
              inputRef.current.value = ''
              props.resetFiltersOptions([]);
            }}
            className="btn undo-constraints-mode"
            title="Reset filters"
          >
            <i className="fa fa-undo mr-2"></i>Reset
          </button>
        </div>
        <div style={{ marginTop: "3rem" }} className="col-md-3">
          <div className="required fnt-md pt-2" style={{ color: "red" }}>
            <label></label>Fields are mandatory
          </div>
        </div>
      </section>}
      {<section
        className={classnames(StrategyCSS.tableSection, StrategyCSS.card)}
        id="strategy-page-table"
      >
        <div className={StrategyCSS.table}>
          <PageLoader loader={loaderdts} gridLoader={true} />
          {tableData && (
            <>
              <PageLoader loader={props.loading || setAllLoading || isLoading} gridLoader={true}>
                {(props.error) ? (<div className="error">{ERROR_MSG}</div>) :
                  <ReactTableWithPlugins
                    getInstanceOnMount={(inst) => {
                      // updateSetAll(inst);
                      setTableInstance(() => inst);
                    }}
                    columns={cols}
                    data={tableData}
                    initialPageIndex={initialIndex}
                    initialHiddenColumns={["min_stock", ...POASNHiddenColumns]}
                    renderMarkup="TableMarkup"
                    keyRT="StrategyPageTable"
                    shouldPagination
                    totalRecordsLen={tableData.length}
                    totalCount={isEmpty(searchTermReq) && props?.location?.state?.styles?.length || 0}
                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    initialSelectedRows={initialSelectedRows}
                    // tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    // headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                    tableId="create_allocation_1"
                    fetchData={(data) => !isContinueAllocation && fetchData(data)}
                    getManualSortByOptions={(instance) => {
                      fecthDataWithSorting(instance)
                    }}
                    manualSortBy={true}
                    pageSize={recordsPerPage}
                    checkAllCallBack = {(val) => updateSetAll(val)}
                    checkCallBack = {(val) => updateCheckedRows(val)}
                    hideTotalRecords = {!isContinueAllocation && hideTotalRecords}
                    hideTotalPages = {!isContinueAllocation && hideTotalPages}  
                  />}
              </PageLoader>
              <div style={{ textAlign: "center", margin: '2rem' }}>
                <button
                  className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                  onClick={() => {
                    setHideDcStoreSection(false)
                    setArticleStoreMapping({})
                    shouldShowAlert(false, isContinueAllocation ? false : true, isContinueAllocation ? draftInstance : null);
                    setForecastRevDisableState(true);
                  }}
                >
                  {StrategyConsts.REVIEW_STOREDC_BUTTON}
                </button>
                {/* {
                  hideToProd() &&
                  <button
                    className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                    onClick={() => {
                      handleReviewForecast();
                    }}
                  >
                    {StrategyConsts.REVIEW_FORECAST_BUTTON}
                  </button>
                } */}
                <button
                  className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                  onClick={() => {
                    setAllHandler(tableInstance, 'main_table');
                    setstoreEligibiltyGroupSA(null);
                    setInventorySourceSA(null);
                    setDcSA(null)
                    setDemandTypeSA(null);
                    setProductProfileSA(null);
                    setUserDefinedInvSA(null);
                    setPlannedAPSSA(null);
                    setPlannedWOSSA(null);
                    setTableData(tableInstance?.data)
                  }}
                >
                  Set All
                </button>
              </div>
            </>
          )}
        </div>
      </section>}
      {/* DC and Store details */}
      {(!reviewBtnDisabled || isContinueAllocation) && (!hideDcStoreSection) && !userEditAfterValidationOnRest &&(
        <section
          className={classnames(StrategyCSS.dcStoreDetails, StrategyCSS.card)}
        >
          <div className={StrategyCSS.storeAndDC}>
            <div  className="article__list__single__select">
              <SingleSelect
                value={selectedArticle}
                options={articleOptions}
                onChange={(val) => {
                  // setIsReviewChangesDisabled(true)
                  setSelectedArticleDcData(null);
                  setDCDataLoading(true);
                  setArticleStoreMapping((old) => {
                    return {
                      ...old,
                      [selectedArticle?.value]: storeGroupTableInstance?.state?.selectedRowIds
                    }
                  })
                  setSelectedArticle(val);
                  setSelectedArticleStoreConst(null);
                  setRefStoreDropDownOptions(null);
                  setIsReviewChangesDisabled(true);
                  setStoreTableData(cloneDeep(storeTableDataCopy))
                  // setSortBy([{ id: 'overall_proportion_rounded', desc: true }])
                }}
              />
              {!isEmpty(checkAllApisWithSearchTerm) && (
                <i className="fa fa-spinner fa-spin" style={{fontSize:"1.5rem"}} ariaHidden="true"></i>
              )}
            </div>
            {(totalEstimatedDemad === 0 || totalEstimatedDemad) && <span className={StrategyCSS.ted__sum}>Total Estimated Demand for All Stores : {totalEstimatedDemad}</span>}
          </div>
          <Tabs defaultActiveKey="details">
            <Tab eventKey="details" title="Store Details">
                {(props.storeDataError) ? (<div className="error">{props.storeDataError}</div>) :
                  <div className={classnames(StrategyCSS.table, "mt-1")}>
                    {!isEmpty(storeTableData) &&
                      selectedArticleStoreTableData() &&
                      selectedArticleStoreConst && (
                        <div
                        >
                          <ReactTableWithPlugins
                            hideOptions={["overall_proportion_rounded", ...storeTableData[selectedArticle?.value][0].size_desc]}
                            initialHiddenColumns={["Store_Index", "dummyId"]}
                            getInstanceOnMount={(inst) => {
                              setStoreGroupTableInstance(inst);
                            }}
                            columns={storeDetailsCols}
                            sortBy={sortBy}
                            data={selectedArticleStoreConst}
                            renderMarkup="TableMarkup"
                            keyRT="StrategyPageTable"
                            features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                            shouldPagination
                            // embededScroll
                            totalRecordsLen={selectedArticleStoreConst.length}
                            initialSelectedRows={intitalSelectedStoreRows}
                            tableId="create_allocation_review"
                            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                          />
                          <div style={{ textAlign: "center", marginTop:"2rem" }}>
                            <button
                              className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                              onClick={() => setIsShowAlert(true)}
                              disabled={isReviewChangesDisabled}
                            >
                              Apply Changes
                            </button>
                            {!isPOASNFlow &&
                              <button
                                className={classnames("btn undo-constraints-mode", StrategyCSS.adjacent__buttons)}
                                onClick={validatePlanName}
                              >
                                Save as Draft
                              </button>
                            }
                            <button
                              className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                              onClick={createAllocation}
                            >
                              Create Allocation
                            </button>
                            <button
                              className={classnames("btn btn-primary", StrategyCSS.adjacent__buttons)}
                              onClick={() => setAllHandler(storeGroupTableInstance, 'store_table')}
                            >
                              Set All
                            </button>
                          </div>
                        </div>
                      )}
                  </div>}
            </Tab>
            {
              // !isPOASNFlow &&
              <Tab eventKey="dc" title="Inv. Source">
                <PageLoader loader={props.dcDataLoading || dcDataLoading} gridLoader={true}>
                  {(props.dcDataError) ? (<div className="error">{props.dcDataError}</div>) :
                    <div className={classnames(StrategyCSS.table, "mt-1")}>
                      {!isEmpty(dcTableData) &&
                        selectedArticleDcTableData() &&
                        selectedArticleDcData && (
                          <>
                            <ReactTableWithPlugins
                              hideColumnsFilter
                              getInstanceOnMount={(inst) => {
                                setDcTableInstance(inst);
                              }}
                              style={{ minWidth: '50rem', maxWidth: 'fit-content', margin: 'auto' }}
                              columns={dcTableCols}
                              data={selectedArticleDcData}
                              renderMarkup="TableMarkup"
                              // keyRT="sortAndSearch"
                              // shouldPagination
                              embededScroll
                              totalRecordsLen={selectedArticleDcData.length}
                              tableId="create_allocation_dc"
                              tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                              headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                            />
                            <div style={{ textAlign: "center", marginTop: '10px' }}>
                              <button
                                className="btn btn-primary"
                                onClick={saveQtyChanges}
                                disabled={saveIsDisabled}
                              >
                                Save Changes
                              </button>
                            </div>
                          </>
                        )}
                    </div>}
                </PageLoader>
              </Tab>
            }
          </Tabs>
        </section>
      )}

      {!isForecastrRevBtnDisable && (
        <section className={classnames(StrategyCSS.card)} id="ADA_VISUAL_SECTION">
          <PageLoader loader={props.isAdaDataLoading || props.isReviewReq || props.isSaveReq} loaderMessage={getLoaderMessage()}>
            <div ref={adaVisualRef}><AdaVisualContainer /></div>
          </PageLoader>
        </section>
      )}
    </PageLoader>

    </main>
  );
};

const mapStateToProps = ({ strategyTable, adaVisual, drafts }) => ({
  loading: strategyTable.loading,
  storeDataLoading: strategyTable.storeDataLoading,
  dcDataLoading: strategyTable.dcDataLoading,
  allocationCode: strategyTable.allocationCode,
  allocationCodeSuccess: strategyTable.allocationCodeSuccess,
  allocationCodeError: strategyTable.allocationCodeError,
  // createAllocationLoading: strategyTable.createAllocationLoading
  createAllocationError: strategyTable.createAllocationError,
  createAllocationSuccess: strategyTable.createAllocationSuccess,
  createAllocationData: strategyTable.createAllocationData,
  saveSuccess: strategyTable.saveSuccess,
  error: strategyTable.error,
  storeDataError: strategyTable.storeDataError,
  dcDataError: strategyTable.dcDataError,
  saveError: strategyTable.saveError,
  tableData: strategyTable.data,
  storeGroupStoreResult: strategyTable.storeGroupStoreResult,
  storeMinStockResult: strategyTable.storeMinStockResult,
  divisions: strategyTable.divisions,
  storeCode: strategyTable.storeCode,
  storeName: strategyTable.storeName,
  seg: strategyTable.storeGroup,
  departments: strategyTable.departments,
  subdepartments: strategyTable.subdepartments,
  level4: strategyTable.level4,
  level5: strategyTable.level5,
  level6: strategyTable.level6,
  level7: strategyTable.level7,
  factoryType: strategyTable.factoryType,
  storeTableData: (strategyTable.storeTableData),
  dcTableData: cloneDeep(strategyTable.dcTableData),
  disableFilters: strategyTable.disableFilters,
  filterLabels: strategyTable.filterlabels,
  // min_stock_1: strategyTable?.changed_min_stock,
  isAdaDataLoading: adaVisual.isAdaDataLoading,
  isAdaReqFailed: adaVisual.isAdaReqFailed,
  reviewAdaLoading : strategyTable.reviewAdaLoading,
  reviewAdaSuccess : strategyTable.reviewAdaSuccess,
  reviewAdaError : strategyTable.reviewAdaError,
  errorMsg: adaVisual.errorMsg,
  startFiscalYearWeek: adaVisual.startFiscalYearWeek,
  endFiscalYearWeek: adaVisual.endFiscalYearWeek,
  isAdaAllocationModeSelected: adaVisual.isAdaAllocationModeSelected,
  isReviewReq: adaVisual.isReviewReq,
  isReviewFailed: adaVisual.isReviewFailed,
  isReviewSuccess: adaVisual.isReviewSuccess,
  isSaveReq: adaVisual.isSaveReq,
  isSaveSuccess: adaVisual.isSaveSuccess,
  isSaveFailed: adaVisual.isSaveFailed,
  saveDraftData: strategyTable.saveDraftData,
  draftNameError: strategyTable.draftNameError,
  draftNameSuccess: strategyTable.draftNameSuccess,
  out_of_data: strategyTable.out_of_data,
  styleIndex: strategyTable.style_index,
  pageIndex: strategyTable?.pageIndex,
  dataForCA: strategyTable?.dataForCA,
  out_of_dataForCA: strategyTable?.out_of_dataForCA,
  style_indexForCA: strategyTable?.style_indexForCA,
  errorForCA: strategyTable?.errorForCA,
  // min_stock_1: strategyTable?.changed_min_stock,
  draftsData: drafts.draftsData,
  draftsDataLoading: drafts.draftsDataLoading,
  draftsDataError: drafts.draftsDataError,
  saveDraftLoading: strategyTable?.saveDraftLoading
});

const mapDispatchToProps = (dispatch) => ({
  resetAll: () => dispatch(resetAll()),
  setLoaderToFalse: () => dispatch(setLoaderToFalse()),
  // getstoregroupminstock: (payload) => dispatch(getstoregroupminstock(payload)),
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  createAllocation: (payload,isSSE) => dispatch(createAllocation(payload,isSSE)),
  editDataAda : (payload) => dispatch(editDataAda(payload)),
  reviewAda : (payload) => dispatch(reviewAda(payload)),
  updateResQty: (payload) => dispatch(updateResQty(payload)),
  save: (payload) => dispatch(save(payload)),
  fetchStrategyTableData: (payload) =>
    dispatch(fetchStrategyTableData(payload)),
  fetchStrategyTableDataForCheckAll: (payload) =>
    dispatch(fetchStrategyTableDataForCheckAll(payload)),
  fetchFilterData: (payload) => dispatch(fetchFilterData(payload)),
  fetchStoregroupMinStock: (payload) =>
    dispatch(fetchStoregroupMinStock(payload)),
  setTableInstance: (payload) => dispatch(setTableInstance(payload)),
  fetchStoreTableData: (payload) => dispatch(fetchStoreTableData(payload)),
  fetchDcTableData: (payload) => dispatch(fetchDCData(payload)),
  enableFilters: () => dispatch(enableFilters()),
  setAdaAllocationRequest: (payload) => dispatch(setAdaAllocationRequest(payload)),
  setAdaCurrentDate: (payload) => dispatch(setAdaCurrentDate(payload)),
  saveDraft: (payload) => dispatch(saveDraft(payload)),
  getDrafts: (payload) => dispatch(getDrafts(payload)),
  deleteDraft: (payload) => dispatch(deleteDraft(payload)),
  resetSaveDraftData: () => dispatch(resetSaveDraftData()),
  validatePlanName: (payload) => dispatch(validatePlanName(payload)),
  resetStyleIndex: () => dispatch(resetStyleIndex()),
  resetStyleIndexForCA: () => dispatch(resetStyleIndexForCA()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Strategy);
