import { levelFiltersLabelFormatter, updateArticleName } from "../../utils/filterLevelMapping";

export const DC_NAMES = {
    "katespade": [{name: "DC - Canada", acc: "ca7777"}, {name: "DC - JAX", acc: "us7771"}],
    "coach": [{name: "DC - Canada", acc: "ca7777"}, {name: "DC - JAX", acc: "jax"}, {name: "DC - Vegas", acc: "us7781"}],
    "coach_ch": [{name: "DC - CHN", acc: "cn10"}],
    "coach_jp": [{name: "DC - JP", acc: "jp05"}]
}

export const STORE_STOCK_TABLE_HEADERS = {
    STORE_NUMBER: "Store Number",
    SAP_ID: "SAP ID",
    STORE_NAME: "Store Name",
    LEVEL1: levelFiltersLabelFormatter("level1"),
    LEVEL2: levelFiltersLabelFormatter("level2"),
    LEVEL3: levelFiltersLabelFormatter("level3"),
    LEVEL4: levelFiltersLabelFormatter("level4"),
    LEVEL5: levelFiltersLabelFormatter("level5"),
    LEVEL6: levelFiltersLabelFormatter("level6"),
    LEVEL7: levelFiltersLabelFormatter("level7"),
    STORE_GRADE: "Store Grade",
    // REGION: "Region",
    CLIMATE: "Climate",
    STYLE_COLOR_ID: `${updateArticleName(false)} ID`,
    DESCRIPTION: "Style Description",
    STYLE_COLOR: "Style Color",
    ASSORT_INDICATOR: "Assortment Indicator",
    DOOR_INDICATOR: "Door Profile Indicator",
    OH: "On Hand",
    IT: "In Transit",
    OO: "On Order",
    TOT_INV: "Total Inventory",
    LW_UNITS: "LW Units",
    WOS:  "WOS",
    OH_SI:"OH (Size Integrity)",
    IT_SI: "IT (Size Integrity)",
    DC_SI: "DC (Size Integrity)",
    DC_TOTAL: "Total (Size Integrity)",
    // DC_INVENTORY: "DC Inventory",
    BULK_REMAINING: "Bulk Remaining",
    STYLE_COLOR_STATUS: "Article Status",
    STORE_STATUS: "Store Status",
    CLERANCE: "Clearance",
};
