import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PageLoader from '../../../../components/Loader/PageLoader'
import ReactTable from '../../../../components/Table/ReactTable'
import WithDynamicPlugins from '../../../../components/Table/WithPlugins'
import { getDailySummaryStoreView, getDailySummaryStoreDetails } from './Actions/StoreViewActions'
import "../../Reporting.css"
import { STORE_VIEW_TABLE_HEADERS } from './StoreViewConstants'
import ColumnFilter from '../../../UserManagement/ColumnFilter'
import NumberRangeColumnFilter from '../../../../components/Filters/NumberRangeColumnFilter';
import { numberUSFormatting } from '../../../../utils/formatters/valueFormatters'
import "../DailySummary.css"
import StyleColorTable from '../StyleColorTable'
import { isEmpty } from 'lodash'
import SKUStoreLevelDetails from '../SKUStoreLevelDetails'
import Card from '../../../../components/Card/Card'
import { ERROR_MSG } from '../../../../constants/globalConstant'
import ExportFile from '../../../../components/ExportFile/ExportFile'
const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);


const StoreView = (props) => {
    const { getDailySummaryStores, storesData, storesLoading, storesDataError, getStoreDetails, storeDetails, storeDetailsLoading,
        storeDetailsError, selectedFilters } = props

    const [showStoreDetails, setShowDetails] = useState(false)
    const [clickedArticle, setClickedArticle] = useState({})
    const [selectedStore, setSelectedStore] = useState(null)
    const [req, setReq] = useState({})

    useEffect(() => {
        getDailySummaryStores({request: selectedFilters})
    }, [])

    const storeColumns = React.useMemo(
        () => [
            {
                Header: STORE_VIEW_TABLE_HEADERS.STORE_NUMBER,
                accessor: "store",
                Filter: ColumnFilter,
                Cell: (instance) => (
                    <div className="clickable" onClick={() => getStoreArticles(instance.row?.original?.store)}>{instance.row?.original?.store}</div>
                ),
            },
            {
                Header: STORE_VIEW_TABLE_HEADERS.SAP_ID,
                accessor: "sap_id",
                Filter: ColumnFilter,
            },
            {
                Header: STORE_VIEW_TABLE_HEADERS.STORE_NAME,
                accessor: "store_name",
                Filter: ColumnFilter
            },
            {
                Header: STORE_VIEW_TABLE_HEADERS.UNITS_ALLOCATED,
                identify: "unitsAllocated",
            },
            {
                Header: STORE_VIEW_TABLE_HEADERS.OH,
                accessor: "oh",
                Cell: (inst) => numberUSFormatting(inst?.value),
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            },
            {
                Header: STORE_VIEW_TABLE_HEADERS.OO,
                accessor: "oo",
                Cell: (inst) => numberUSFormatting(inst?.value),
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            },
            {
                Header: STORE_VIEW_TABLE_HEADERS.IT,
                accessor: "it",
                Cell: (inst) => numberUSFormatting(inst?.value),
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            },
            // {
            //     Header: STORE_VIEW_TABLE_HEADERS.DC_AVAIL,
            //     accessor: "dc_available",
            //     Cell: (inst) => numberUSFormatting(inst?.value),
            //     Filter: NumberRangeColumnFilter,
            //     filter: 'between',
            // },
            // {
            //     Header: STORE_VIEW_TABLE_HEADERS.RESERVED_QTY,
            //     accessor: "reserve_quantity",
            //     Cell: (inst) => numberUSFormatting(inst?.value),
            //     Filter: NumberRangeColumnFilter,
            //     filter: 'between',
            // }
        ]
    )

    const getStoreArticles = (store_code) => {
        setClickedArticle({})
        setSelectedStore(store_code)
        setShowDetails(true)
        getStoreDetails({ ...selectedFilters, store: [{ value: store_code }] })
    }

    const getSizeDetails = (p_article) => {
        let l_req = { "article": [{ "value": p_article }], store: [{ "value": selectedStore }], is_store_view: true }
        setClickedArticle(l_req)
    }

    const createDCColumns = () => {
        if(storesData?.length) {
            const { dcList, dc_map } = props.dc;
            let unitsIndex = storeColumns.findIndex(col => col.identify === "unitsAllocated");
            storeColumns[unitsIndex].columns = [];

            dcList?.map(dcKey => {
                storeColumns[unitsIndex].columns.push({
                    Header: dc_map[dcKey],
                    accessor: row => row.units_allocated_dc?.[dcKey],
                    id: dcKey,
                    Cell: (inst) => numberUSFormatting(inst?.row.original?.units_allocated_dc?.[dcKey]),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                });

            })
            storeColumns[unitsIndex].columns.push({
                Header: "Total",
                accessor: "units_allocated",
                Cell: (inst) => numberUSFormatting(inst?.value),
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            });
            return true;
        }
        return false;
    }

    return (
        createDCColumns() &&
        <div>
            <PageLoader loader={storesLoading} gridLoader={true}>
                {storesDataError ? <div className="error">{storesDataError ||  ERROR_MSG}</div> : <></>}
                <div className="card__wrapper">
                    {props?.cardData?.map((val, ind) => (
                        <Card key={ind} title={val.title} value={val.value} />)
                    )}
                </div>
                    {/* {props.activeTab === "dailySummary" && props.storeTableExcelData?.length && (
                        <div className='d-flex justify-content-end'>

                            <ExportFile
                                text={"Excel"}
                                data={props.storeTableExcelData ? props.storeTableExcelData : []}
                                sheetName={"Daily Allocation Summary"}
                                fileName={`daily_summary_store_view_${new Date().getTime()}`}
                                type={1}
                                formateXLSX={true}
                            // excelColumnsToBeFormatted={
                            //   DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING
                            // }
                            />
                        </div>
                    )} */}
                <ReactTableWithPlugins
                    columns={storeColumns}
                    data={storesData ? storesData : []}
                    renderMarkup='TableMarkup'
                    keyRT="sortAndSearch"
                    shouldPagination
                    tableId="dailySummary-storeView"
                    hideColumnsFilter
                    tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}

                />

            </PageLoader>
            {
                showStoreDetails &&
                <PageLoader loader={storeDetailsLoading} gridLoader={true}>
                    {storeDetailsError ? <div className="error">{storeDetailsError ||  ERROR_MSG}</div>
                        :
                        <>
                            <div className="container__header m-0">
                                <h3 className="fnt-bold">Store - {selectedStore}</h3>
                            </div>
                            <hr />
                            <StyleColorTable dc={props.dc} data={storeDetails} getDetails={getSizeDetails} tableId={"daily-storeDetails"} />
                        </>}
                </PageLoader>
            }
            <div>

                {!isEmpty(clickedArticle) ?
                    <>
                        <div className="storesStore__heading mt-4">
                            <h3 className="fnt-md fnt-bold mr-4">Store - {selectedStore}</h3>
                            <h3 className="fnt-md fnt-bold ml-4">Style Color ID - {clickedArticle?.article[0]?.value}</h3>
                            <span></span>
                        </div>
                        <hr />
                        <SKUStoreLevelDetails dc={props.dc} req={{ ...selectedFilters, ...clickedArticle }} />
                    </> : null}
            </div>
        </div>
    )
}

const mapStateToProps = ({ dailyStoreView, dailySummary }) => ({
    storesData: dailyStoreView.storesData?.tableData,
    cardData: dailyStoreView.storesData?.cardData,
    storesLoading: dailyStoreView.storesDataLoading,
    storesError: dailyStoreView.storesDataError,
    storeDetails: dailyStoreView.storeDetails,
    storeDetailsError: dailyStoreView.storeDetailsError,
    storeDetailsLoading: dailyStoreView.storeDetailsLoading,
    storeTableExcelData: dailyStoreView.storeTableExcelData,
    dc: dailySummary.dc
});

const mapDispatchToProps = (dispatch) => ({
    getDailySummaryStores: (payload) => dispatch(getDailySummaryStoreView(payload)),
    getStoreDetails: (payload) => dispatch(getDailySummaryStoreDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreView);
