import { LEVEL_FILTER_COLUMN_NAMES, currentClientName } from "./commonUtilities"

export const levelFiltersLabelFormatter = (levelValue) => {
    //Used to handle the filter labels acorss different clients
    switch (levelValue) {
        case "level1":
            return LEVEL_FILTER_COLUMN_NAMES[currentClientName]?.[levelValue] || "Department"
        case "level2":
            return LEVEL_FILTER_COLUMN_NAMES[currentClientName]?.[levelValue] || "Gender"
        case "level3":
            return LEVEL_FILTER_COLUMN_NAMES[currentClientName]?.[levelValue]
        case "level4":
            return LEVEL_FILTER_COLUMN_NAMES[currentClientName]?.[levelValue]
        case "level5":
            return LEVEL_FILTER_COLUMN_NAMES[currentClientName]?.[levelValue]
        case "level6":
            return LEVEL_FILTER_COLUMN_NAMES[currentClientName]?.[levelValue]
        case "level7":
            return LEVEL_FILTER_COLUMN_NAMES[currentClientName]?.[levelValue]
        case "channel":
            return process.env.REACT_APP_CLIENT === "chico"
                ? "Distribution Channel"
                : "Channel";
        case "climate":
            return process.env.REACT_APP_CLIENT === "chico"
                ? "Country"
                : "Climate";
        default:
            return;
    }
};

export const updateArticleName = (isPlural) => {
    let chicoName = "Style Color"
    let pumaName = "Article"
    const COACH_CLIENTS = ["coach", "coach_ch", "coach_jp"]
    return (process.env.REACT_APP_CLIENT === "ashley" || COACH_CLIENTS.includes(process.env.REACT_APP_CLIENT))
        ? isPlural ? chicoName + "s" : chicoName
        : isPlural ? pumaName + "s" : pumaName
}

export const showAgeFilter = () => {
    return process.env.REACT_APP_CLIENT === "chico" ? false : true;
}

export const getLoginPageImage = () => {
    const COACH_CLIENTS = ["coach", "coach_ch", "coach_jp"];
    return COACH_CLIENTS.includes(process.env.REACT_APP_CLIENT) ? 'url(/Coach.jpg)' : 'url(/Login.jpg)'
}