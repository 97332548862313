export const ORDER_TYPE_OPTIONS = {
  "Retail-Store": ["YU", "YU1", "YS", "YS1", "YS4", "YS5", "YS6", "YK3"].map(
    (item) => {
      return {
        label: item,
        value: item,
      };
    }
  ),
  "Outlet-Store": ["YF", "YF1", "YF2", "YF3", "YF4", "YF5", "YF7"].map(
    (item) => {
      return {
        label: item,
        value: item,
      };
    }
  ),
};
