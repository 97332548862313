import { takeLatest, put, call, all } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes"
import { ERROR_MSG } from "../../constants/globalConstant";
import { flattenArray } from "../../utils/commonUtilities";
import moment from "moment";

function* generateExcelWorker(action) {
    const level_ln_map = {
        "level1": "l1_name",
        "level2": "l2_name",
        "level3": "l3_name",
        "level4": "l4_name",
        "level5": "l5_name",
        "level6":"l6_name",
        "level7":"l7_name",
    }

    const PARALLEL_CALLS_COUNT = action.payload.apiCount || 10;

    try {
        const { payload, api } = action;

        delete payload?.apiCount;
        const total = payload.total;
        delete payload.total
        let req = {}
        
        for (let key in payload) {
            if (payload.hasOwnProperty(key)) {
                let reqKey = level_ln_map[key] ? level_ln_map[key] : key;
                payload.hasOwnProperty(key) && (req[reqKey] = payload[key])
            }
        }
        req["row_index"] = payload.row_index
        req["row_count"] = payload.row_count

        let parallelCalls = []
        let rowIndex = payload?.row_index;
        let count = 1;
        let run = true;
        while(run) {
            if(count >= PARALLEL_CALLS_COUNT) {
                run = false;
            }
            if(rowIndex < total) {
                parallelCalls.push(call(api, {...req, row_index: rowIndex }))
            }
            rowIndex = payload.row_index + (count * payload.row_count)
            count++;
        }
        const res = yield all(parallelCalls)
        // const res = yield call(getExcessTableData, req);
        if (res[0]?.data?.status) {        
            let overallData = []
            res?.forEach(item => {
                overallData = [...overallData, ...item?.data?.data]
            })             
            overallData = overallData.map((item) => ({
              ...item,
              delivery_date: item.delivery_date
                ? moment(item.delivery_date).format("DD-MM-YYYY")
                : undefined,
              created_date: item?.created_at ? moment(item.created_at).format("DD-MM-YYYY") : undefined
            }));
            const count = res[0].data.total_count
            yield put({ type: actionTypes.GET_EXCEL_DATA_SUCCESS, excelData: overallData, totalCountExcel: count, totalCount: count, nextIndex: payload.row_index + (payload.row_count * PARALLEL_CALLS_COUNT) } );
        }
        else {
            yield put({ type: actionTypes.GET_EXCEL_DATA_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_EXCEL_DATA_ERROR, error: ERROR_MSG });
    }
}


function* generateExcelWatcher() {
    yield takeLatest(actionTypes.GET_EXCEL_DATA, generateExcelWorker)
}


export function* downloadExcelSaga() {
    yield all([
        generateExcelWatcher()
    ])
}