import Api from "../configs/api_config";
import * as apiConstants from "../constants/apiConstants";

export const createPlanApi = (params) =>
  Api.post(apiConstants.CREATE_PLAN, params);

export const editPlanApi = (params) => Api.post(apiConstants.EDIT_PLAN, params);

export const deletePlanApi = (params) =>
  Api.post(apiConstants.DELETE_PLAN, params);

export const getPlanDetailsApi = (params) =>
  Api.post(apiConstants.GET_PLAN_DETAILS, params);

export const getStoreGroupApi = (params) =>
  Api.post(apiConstants.GET_STORE_GROUP, params);

export const getStoresStoreGroupApi = (params) =>
  Api.post(apiConstants.GET_STORES_STORE_GROUP, params);

export const createStoreGroupApi = (params) =>
  Api.post(apiConstants.CREATE_STORE_GROUP, params);

export const editStoreGroupApi = (params) =>
  Api.post(apiConstants.EDIT_STORE_GROUP, params);

export const deleteStoreGroupApi = (params) =>
  Api.post(apiConstants.DELETE_STORE_GROUP, params);

export const getConstraintsFilterData = (params) =>
  Api.post(apiConstants.CONSTRAINTS_FILTER_DATA_API, params);

export const getConstraintsTableData = (params) =>
  Api.post(apiConstants.CONSTRAINTS_TABLE_DATA_API, params);

export const getPopUpData = (params) =>
  Api.post(apiConstants.CONSTRAINTS_POPUP_DATA_API, params);

export const updateTableData = (params) =>
  Api.post(apiConstants.CONSTRAINTS_UPDATE_TABLEDATA_API, params);

export const getProductProfileFilterData = (params) =>
  Api.post(apiConstants.PRODUCTPROFILE_FILTER_DATA_API, params);

export const getProductProfileData = (params) =>
  Api.post(apiConstants.PRODUCTPROFILE_TABLE_DATA_API, params);

export const getStoresData = (params) =>
  Api.post(apiConstants.STORES_TABLE_DATA_API, params);

export const getProductProfileCreateFilterData = (params) =>
  Api.post(apiConstants.PRODUCTPROFILE_CREATEFILTER_DATA_API, params);

export const getProductListContributionData = (params) =>
  Api.post(apiConstants.PRODUCTLIST_CONTRIBUTION_DATA_API, params);

export const getProfiledProductData = (params) =>
  Api.post(apiConstants.PROFILED_PRODUCT_DATA_API, params);

export const saveProductProfile = (params) =>
  Api.post(apiConstants.SAVE_PROFILED_DATA, params);

export const getSavedData = (params) =>
  Api.post(apiConstants.FETCH_SAVED_DATA, params);

export const deleteProductProile = (params) =>
  Api.post(apiConstants.DELETE_PRODUCT_PROFILE, params);

export const getDashboardFilterData = (params) =>
  Api.post(apiConstants.DASHBOARD_FILTER_DATA_API, params);

export const getChartsData = (params) =>
  Api.post(apiConstants.DASHBOARD_CHARTS_DATA_API, params);

export const getStyleInventoryData = (params) =>
  Api.post(apiConstants.DASHBOARD_STYLE_INVENTORY_DATA_API, params);

export const getRecommendeActionsData = (params) =>
  Api.post(apiConstants.GET_RECOMMENDED_ACTIONS_DATA_API, params);

export const runClusterGroupApi = (params) =>
  Api.post(apiConstants.RUN_CLUSTER_GROUP, params);

export const getSignificantScoreApi = (params) =>
  Api.post(apiConstants.GET_SIGNIFICANT_SCORE, params);

export const runIntelligentClusterApi = (params) =>
  Api.post(apiConstants.RUN_INTELLIGENT_CLUSTER, params);

export const getBucketDetailsApi = (params) =>
  Api.post(apiConstants.GET_BUCKET_DETAILS, params);

export const saveClusterBucketApi = (params) =>
  Api.post(apiConstants.SAVE_CLUSTER_BUCKET, params);

export const getSwapStoreApi = (params) =>
  Api.post(apiConstants.GET_SWAP_STORE, params);

export const saveSwapStoreApi = (params) =>
  Api.post(apiConstants.SAVE_SWAP_STORE, params);

export const getStoreApi = (params) => Api.post(apiConstants.GET_STORE, params);

export const getStoreFilterApi = (params) =>
  Api.post(apiConstants.GET_STORE_FILTER, params);

export const getStoreLevelFilterApi = (params) =>
  Api.post(apiConstants.GET_STORE_LEVEL_FILTER, params)

export const createDownload = (params, apiRoute) =>
  Api.post(apiRoute || apiConstants.GET_PLAN_DETAILS, "", params);

export const getCsvFile = (params) =>
  Api.post(apiConstants.CSV_DOWNLOADS, params);

export const getPlanFiltersApi = (params) =>
  Api.post(apiConstants.GET_PLAN_FILTERS, params);

export const fetchClusterBreakdownByPlan = (params) =>
  Api.post(apiConstants.FETCH_CLUSTER_BREAKDOWN, params);
export const performanceChartData = (params) =>
  Api.post(apiConstants.PERFORMANCE_CHART_DATA, params);

export const fetchBudgetDeptData = (params) =>
  Api.post(apiConstants.FETCH_BUDGET_DEPT_DATA, params);

export const saveBudgetDeptData = (params) =>
  Api.post(apiConstants.SAVE_BUDGET_DEPT_DATA, params);

export const optimizeClass = (params) =>
  Api.post(apiConstants.OPTIMIZE_CLASS, params);

export const optimizeCluster = (params) =>
  Api.post(apiConstants.OPTIMIZE_CLUSTER, params);

export const getOptimizedCluster = (params) =>
  Api.post(apiConstants.GET_OPTIMIZED_CLUSTER, params);
export const optimizeProductApi = (params) =>
  Api.post(apiConstants.OPTIMIZE_PRODUCT, params);

export const updateClassLevelRowAPi = (params) =>
  Api.post(apiConstants.UPDATE_CLASSLEVEL_ROW, params);

export const getClassList = (params) =>
  Api.post(apiConstants.GET_CLASS_LIST, params);

export const createNewClass = (params) =>
  Api.post(apiConstants.CREATE_NEW_CLASS, params);

export const saveBudgetClusterDataApi = (params) =>
  Api.post(apiConstants.SAVE_BUDGET_CLUSTER_DATA, params);

export const navigatePlanStepApi = (params) =>
  Api.post(apiConstants.NAVIGATE_PLAN_STEP, params);

export const fetchOptimiationConstraint = (params) =>
  Api.post(apiConstants.FETCH_OPTIMIZATION_CONSTRAINT, params);

export const fetchDepthChoice = (params) =>
  Api.post(apiConstants.FETCH_DEPTH_CHOICE, params);

export const attributeChartData = (params) =>
  Api.post(apiConstants.ATTRIBUTE_CHART_DATA, params);

export const updateOptimizeConstraint = (params) =>
  Api.post(apiConstants.UPDATE_OPTIMIZATION_CONSTRAINT, params);

export const saveDepthAndChoice = (params) =>
  Api.post(apiConstants.SAVE_DEPTH_AND_CHOICE, params);

export const checkUniqClassName = (params) =>
  Api.post(apiConstants.CHECK_UNIQ_STOREGROUPNAME, params);

export const getFiscalDate = (params) =>
  Api.post(apiConstants.GET_FISCAL_DATE, params);

export const getWedgeOptimizePlan = (params) =>
  Api.post(apiConstants.GET_WEDGE_OPTIMIZE_PLAN, params);

export const updateWedgeOptimizedPlan = (params) =>
  Api.post(apiConstants.UPDATE_WEDGE_OPTIMIZE_PLAN_DATA, params);

export const fetchDropApi = (params) =>
  Api.post(apiConstants.FETCH_DROP, params);

export const updateDropApi = (params) =>
  Api.post(apiConstants.UPDATE_DROP, params);

export const getWedgeTableData = (params) =>
  Api.post(apiConstants.GET_WEDGE_TABLE_DATA, params);

export const getWedgeBETableData = (params) =>
  Api.post(apiConstants.GET_WEDGE_TABLE_DATA_BE, params);

export const getDepthMultiplierData = (params) =>
  Api.post(apiConstants.GET_DEPTH_MULTIPLIER_DATA, params);

export const getPlanClustLevelData = (params) =>
  Api.post(apiConstants.GET_PLAN_CLUST_LEVEL, params);

export const getSizeDetailsData = (params) =>
  Api.post(apiConstants.GET_SIZE_DETAILS, params);

export const getGradeDetailsData = (params) =>
  Api.post(apiConstants.GET_GRADE_DETAILS, params);

export const updateWedgeTableData = (params) =>
  Api.post(apiConstants.UPDATE_WEDGE_TABLE, params);

export const validatePlanApi = (params) =>
  Api.post(apiConstants.VALIDATE_PLAN, params);

export const getWedgeFiltersApi = (params) =>
  Api.post(apiConstants.GET_WEDGE_FILTERS, params);

export const getSizeSimulationApi = (params) =>
  Api.post(apiConstants.SIZE_SIMULATION_URL, params);

export const getGradeSimulationApi = (params) =>
  Api.post(apiConstants.GRADE_SIMULATION_URL, params);

export const getGradeAttrApi = (params) =>
  Api.post(apiConstants.GET_GRADE_ATTR_DETAILS, params);

export const getStyleInvPopupData = (params) =>
  Api.post(apiConstants.GET_STYLE_INV_POPUP_DATA, params);

export const getUserData = (params) =>
  Api.post(apiConstants.GET_USER_DATA, params)

export const getFormData = (params) =>
  Api.post(apiConstants.GET_FORM_DATA, params)

export const createUser = (params) =>
  Api.post(apiConstants.CREATE_USER, params)

export const editUser = (params) =>
  Api.post(apiConstants.EDIT_USER, params)

export const deleteUser = (params) =>
  Api.post(apiConstants.DELETE_USER, params)

export const loginApiData = (params) =>
  Api.post(apiConstants.LOGIN_API, params)
export const JWTAfterSSOApi = (params) =>
  Api.post(apiConstants.JWT_AFTER_SSO_API, params)
export const updateTokenApi = (params) =>
  Api.post(apiConstants.UPDATE_TOKEN, params)

export const getReviewRecommendData = (params) =>
  Api.post(apiConstants.GET_REVIEW_RECOMMEND_API, params);

export const getStoreViewData = (params) =>
  Api.post(apiConstants.GET_STORE_VIEW, params)
export const getProductView = (params) =>
  Api.post(apiConstants.GET_PRODUCT_VIEW, params)
export const getProductStoreView = (params) =>
  Api.post(apiConstants.GET_PRODUCT_STORE_VIEW, params)
export const editAllocatedUnits = (params) =>
  Api.post(apiConstants.EDIT_ALLOCATED_UNITS, params)
export const allocationPoCreationBeforeFinalize = (params) =>
  Api.post(apiConstants.ALLOCATION_PO_CREATION_BEFORE_FINALIZE, params)
export const allocationPoCreation = (params, source) =>
  Api.post(apiConstants.ALLOCATION_PO_CREATION, params, source)
export const confirmDataAda = (params) =>
  Api.put(apiConstants.CONFIRM_DATA_ADA,params)  
export const pastAllocationPoCreation = (params) =>
  Api.post(apiConstants.POST_ALLOCCATION_PO, params)
export const getAllocationPlans = (params) =>
  Api.post(apiConstants.GET_ALLOCATION_PLANS, params)
export const updateResQty = (params) =>
  Api.post(apiConstants.UPD_RES_QTY, params)
export const getPackDetailsApi = (params) => 
  Api.post(apiConstants.GET_PACK_DETAILS_API, params)
export const uploadFile = (params) =>
  Api.post(apiConstants.UPLOAD_FILE, params)
export const updatePacksEachesForStoresAPI = (params) =>
  Api.post(apiConstants.UPDATE_PACKS_EACHES_FOR_STORES, params)
export const updateFinalizeInfo = (params) =>
  Api.post(apiConstants.UPDATE_FINALIZE_INFO, params)
export const getPacksForBulkUpdateAPI = (params) => 
  Api.post(apiConstants.GET_PACKS_FOR_BULK_UPDATE_API, params)
export const disableEditApi = (params) =>
  Api.post(apiConstants.DISABLE_EDIT, params)

export const getAdaVisualFilterData = (params) =>
  Api.post(apiConstants.ADAVISUAL_FILTER_DATA_API, params);
export const getAdaAllocationData = (params) =>
  Api.post(apiConstants.GET_ADA_ALLOCATION_API, params);
export const getAdaStandAloneData = (params) =>
  Api.post(apiConstants.GET_ADA_STANDALONE_API, params);
export const getAdaDriversData = (params) =>
  Api.post(apiConstants.GET_ADA_DRIVERS_API, params);
export const getAdaFilterData = (params) =>
  Api.post(apiConstants.ADAVISUAL_FILTER_DATA_API, params);
export const getAdaHierarchyData = (params) =>
  Api.post(apiConstants.GET_ADA_HIERARCHY_API, params);
export const getAdaHierarchyAllocationData = (params) =>
  Api.post(apiConstants.GET_ADA_HIERARCHY_API_ALLOCATION, params);
export const postAdaReviewData = (params) =>
  Api.post(apiConstants.ADA_REVIEW_API, params);
export const postAdaSaveData = (params) =>
  Api.post(apiConstants.ADA_REVIEW_API, params);

 export const saveChangesCaVisual = (params)=>
 Api.post(apiConstants.SAVE_CHANGES_CA_VISUAL, params) 


export const getDrafts = (params) =>
  Api.post(apiConstants.GET_DRAFTS, params)
export const saveDrafts = (params) =>
  Api.post(apiConstants.SAVE_DRAFT, params)

export const getReportingFilters = (params) =>
  Api.post(apiConstants.GET_REPORTING_FILTERS, params)
export const getReporting = (params) =>
  Api.post(apiConstants.GET_REPORTING, params)

export const getStoreStatus = (params) =>
  Api.post(apiConstants.GET_STORE_STATUS, params)

export const updateStoreStatus = (params) =>
  Api.put(apiConstants.GET_STORE_STATUS, params)


//Product configuration Start

export const getProductConfigFilters = (params) =>
  Api.post(apiConstants.GET_PRODUCT_CONFIG_FILTERS, params)

export const getProductConfiguration = (params) =>
  Api.post(apiConstants.GET_PRODUCT_CONFIGURATION, params)

export const getArticleMapping = (params) =>
  Api.post(apiConstants.GET_ARTICLE_MAPPING, params)

export const getStoreGroupMapping = (params) =>
  Api.post(apiConstants.GET_STORE_GROUP_MAPPING, params)

export const getPPMapping = (params) =>
  Api.post(apiConstants.GET_PP_MAPPING, params)

export const getDCMapping = (params) =>
  Api.post(apiConstants.GET_DC_MAPPING, params)

export const createProductConfig = (params) =>
  Api.put(apiConstants.CREATE_PRODUCT_CONFIG, params)

export const deleteProductConfig = (params) =>
  Api.delete(apiConstants.CREATE_PRODUCT_CONFIG, params)


export const getTransactionDetails = () =>
  Api.get(apiConstants.GET_TRANSACTION)

//Product configuration End



//Lost Sales Report API's
export const getLostSalesFilterData = (params) =>
  Api.post(apiConstants.GET_LOST_SALES_REPORT_FILTERS, params);

export const getLostSalesReportAll = (params) =>
  Api.post(apiConstants.GET_LOST_SALES_REPORT_ALL, params);

export const getLostSalesReportDetailsTable = (params) =>
  Api.post(apiConstants.GET_LOST_SALES_REPORT_DETAILS_TABLE, params);

export const getExcessTableData = (params) =>
  Api.post(apiConstants.GET_EXCESS_TABLE_DATA, params);

export const getExcessGraphData = (params) =>
  Api.post(apiConstants.GET_EXCESS_GRAPH_DATA, params);

export const getDeepDiveFilterData = (params) =>
  Api.post(apiConstants.GET_DEEP_DIVE_FILTERS, params);

export const getDeepDiveData = (params) =>
  Api.post(apiConstants.GET_DEEP_DIVE_DATA, params)
export const getExcessFilters = (params) =>
  Api.post(apiConstants.GET_EXCESS_FILTERS, params);

export const getDailySummaryFilterData = (params) =>
  Api.post(apiConstants.GET_DAILY_SUMMARY_FILTERS, params);

export const getDailySummaryData = (params) =>
  Api.post(apiConstants.GET_DAILY_SUMMARY_DATA, params);

export const getDailySummarySKUData = (params) =>
  Api.post(apiConstants.GET_DAILY_SUMMARY_SKU_DATA, params)

//Strategy configuration
export const getStrategyConfigFiltersData = (params) =>
  Api.post(apiConstants.GET_STRATEGY_CONFIGURATION_FILTERS_DATA, params);
export const getStrategyConfigTableData = (params) =>
  Api.post(apiConstants.GET_STRATEGY_CONFIGURATION_TABLE_DATA, params);

export const getStrategyConfigData = (params) =>
  Api.post(apiConstants.GET_STRATEGY_CONFIGURATION_DATA, params);
export const saveStrategyConfigData = (params) =>
  Api.post(apiConstants.SAVE_STRATEGY_CONFIGURATION_DATA, params);
export const deleteStrategyConfigApi = (params) =>
  Api.delete(apiConstants.DELETE_STRATEGY_CONFIG_API, params);
//PO/ASN
export const getPOASNDetails = (params) =>
  Api.post(apiConstants.GET_PO_ASN_DETAILS, params);

//User preference table columns
export const saveUserPreferenceColumns = (params) =>
  Api.post(apiConstants.SAVE_USER_PREFERENCE_COLUMNS, params);

//Inventory Reservation Filter
export const getInventoryReservationFiltersData = (params) =>
  Api.post(apiConstants.GET_INVENTORY_RESERVATION_FILTERS_DATA, params)

export const getInventoryReservationTableData = (params) =>
  Api.post(apiConstants.GET_INVENTORY_RESERVATION_TABLE_DATA, params)

export const editInventoryReservationTableData = (params) =>
  Api.post(apiConstants.EDIT_INVENTORY_RESERVATION_TABLE_DATA, params)

//New Store Setup
export const getStoreListDetailsConfig = (params) =>
  Api.post(apiConstants.GET_STORELIST_DETAILS_CONFIG, params);

export const getDemandConstraintsData = (params) =>
  Api.post(apiConstants.GET_DEMAND_DATA, params)
export const getDemandAddProductsData = (params) =>
  Api.post(apiConstants.GET_DEMAND_ADD_PRODUCTS_DATA, params)
export const getDcSourceDetails = (params) =>
  Api.post(apiConstants.GET_DC_SOURCE_DETAILS, params);
export const updateEditedDcLeadTime = (params) =>
  Api.put(apiConstants.UPDATE_DC_SOURCE_EDITED_TIME, params);
export const getNewStoreAllocations = (params) =>
  Api.post(apiConstants.GET_NEW_STORE_ALLOCATIONS, params);
export const saveNewStoreAllocation = (params) =>
  Api.put(apiConstants.SAVE_NEW_STORE_ALLOCATION, params);
export const getReviewAllocationDetails = (params) =>
  Api.post(apiConstants.GET_REVIEW_ALLOCATION_DETAILS, params);
export const reviewNewStoreAllocation = (params) =>
  Api.post(apiConstants.REVIEW_NEW_STORE_ALLOCATION, params);
export const getArticleAllocation = (params) =>
  Api.post(apiConstants.GET_ARTICLE_ALLOCATION, params);
export const updateArticleAllocation = (params) =>
  Api.put(apiConstants.UPDATE_ARTICLE_ALLOCATION, params);
export const getSisterStoreFilters = (params) =>
  Api.post(apiConstants.GET_SISTER_STORE_FILTERS, params)
export const saveNewStoreInfo = (params) =>
  Api.post(apiConstants.SAVE_NEW_STORE_INFO, params)
export const upsertNewStoreDetails = (params) =>
  Api.post(apiConstants.UPSERT_NEW_STORE_DETAILS, params)
export const getNewReservedStoreList = (params) =>
  Api.post(apiConstants.GET_NEW_RESERVED_STORE_LIST, params)
export const getNewReservedArticleList = (params) =>
  Api.post(apiConstants.GET_NEW_RESERVED_ARTICLE_LIST, params)
export const getReservedArticlesInfo = (params) =>
  Api.post(apiConstants.GET_RESERVED_ARTICLES_INFO, params)
export const deleteNewStore = (params) =>
  Api.post(apiConstants.DELETE_NEW_STORE, params)
export const getNewStoreEditReservedDetails = (params) =>
  Api.post(apiConstants.GET_EDIT_RESERVED_DETAILS, params)
export const editNewStoreReserved = (params) =>
  Api.put(apiConstants.GET_EDIT_RESERVED_DETAILS, params)
export const approveNewStoreArticles = (params) =>
  Api.put(apiConstants.APPROVE_NEW_STORE_ARTICLES, params)
export const getNewStoreAllocatedArticles = (params) =>
  Api.post(apiConstants.GET_NEW_STORE_ALLOCATED_ARTICLES, params)
export const releaseNewStoreArticles = (params) =>
  Api.put(apiConstants.RELEASE_NEW_STORE_ARTICLES, params)
export const getNewStoreSizeCurve = (params) =>
  Api.post(apiConstants.GET_NEW_STORE_SIZE_CURVE, params)
export const getStoreGrades = (params) =>
  Api.get(apiConstants.GET_STORE_GRADES, params)
export const getNewStorePastAllocations = (params) =>
  Api.post(apiConstants.GET_NEW_STORE_PAST_ALLOCATIONS, params)

//To get auto approval details
export const getAutoApprovalDetails = (params) =>
  Api.post(apiConstants.GET_AUTO_ALLOCATION_DETAILS, params)

//Daily summary store view

export const getDailySummaryStoreView = (params) =>
  Api.post(apiConstants.GET_DAILY_SUMMARY_STORE_VIEW, params)
export const getDailySummaryStoreDetails = (params) =>
  Api.post(apiConstants.GET_DAILY_SUMMARY_STORE_VIEW_DETAILS, params)
  export const getDailySummaryStoreArticleDetails = (params) =>
  Api.post(apiConstants.GET_DAILY_SUMMARY_STORE_VIEW_ARTICLE_DETAILS, params)
//To get product config old styles for mapping
export const getProductConfigStyleMapping = (params) =>
  Api.post(apiConstants.GET_PRODUCT_CONFIG_STYLE_MAPPING, params)

export const saveStoreData = (params) =>
  Api.post(apiConstants.SAVE_STORE_DATA, params)

  
//API to get dc list in store config
export const getStoreConfigDcList = (params) => 
  Api.post(apiConstants.GET_STORE_CONFIG_DC_LIST, params)

export const updateAutoAllocationRequired = (params) =>
Api.put(apiConstants.UPDATE_AUTO_ALLOCATION_REQUIRED, params)

// API for default Constraints Filters
export const getDefaultConstraintsFilterData = (params) =>
Api.post(apiConstants.DEFAULT_CONSTRAINTS_FILTER_DATA_API, params);

// API for default Constraints table data
export const getDefaultConstraintsTableData = (params) =>
Api.post(apiConstants.DEFAULT_CONSTRAINTS_TABLE_DATA_API, params);

export const updateDefaultDataData = (params) =>
  Api.post(apiConstants.CONSTRAINTS_UPDATE_DEFAULT_DATA_API, params);

export const getAdaStoreHierarchyData = (params) =>
  Api.post(apiConstants.GET_ADA_STORE_HIERARCHY_API, params);

export const getStoreCapacityData = (params) =>
  Api.post(apiConstants.GET_STORE_CAPACITY_DATA, params);

export const saveWipAllocation = (params) =>
  Api.post(apiConstants.SAVE_WIP_ALLOCATION, params);
  
export const dailySummaryExport = (params) =>
  Api.post(apiConstants.DAILY_SUMMARY_EXPORT, params);

export const getManualWIPAllocations = (params) =>
  Api.post(apiConstants.GET_MANUAL_WIP_ALLOCATIONS, params)

export const getWIPFilter = (params) =>
  Api.post(apiConstants.GET_WIP_FILTER, params)