import { takeLatest, takeEvery, put, all, call, delay } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes"
import {
    getProductConfigFilters, getProductConfiguration, getArticleMapping,
    getStoreGroupMapping, getPPMapping, getDCMapping, getStoresData,
    createProductConfig, deleteProductConfig, getProductConfigStyleMapping, updateAutoAllocationRequired
} from "../../../routes/api";
import moment from "moment";
import missingConfigData from "./missingConfigData.json"
import { isEmpty, isObject } from "lodash";
import { ERROR_MSG } from "../../../constants/globalConstant";

const options = [{
    label: "Active",
    value: "active"
  },
  {
    label: "Deactivated",
    value: "deactivated"
  },
  {
    label: "Paused",
    value: "paused"
  }]

function* fetchFilterDataWorker(action) {
    try {
        const { payload, filterKey } = action;
        let req = {};
        // for (const key in payload) {
        //     if (!isEmpty(payload[key])) {
        //         req[key] = payload[key]
        //     }
        // }

        for (const key in payload) {
            if (Array.isArray(payload[key])) {
                payload[key]?.length && (req[key] = payload[key].map((ele) => ele.value))
            }
            else if (isObject(payload[key])) {
                req[key] = [payload?.[key]?.value]
            }
        }

        if (filterKey === "style_name" || filterKey === "color" || filterKey === "product_type") {
            req["filter_type"] = filterKey;
        }

        const res = yield call(getProductConfigFilters, req);

        if (res?.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping
            // let topObject = Object.keys(res.data.data[0])[0]
            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "l1_name") {
                    k = "departmentOptions";
                } else if (key === "l2_name") {
                    k = "genderOptions";
                } else if (key === "l3_name") {
                    k = "rbuOptions";
                } else if (key === "l4_name") {
                    k = "dcsOptions";
                } else if (key === "l5_name") {
                    k = "level5Options";
                } else if (key === "l6_name") {
                    k = "level6Options";
                } else if (key === "l7_name") {
                    k = "level7Options";
                } else if (key === "product_type") {
                    k = "productTypeOptions";
                } else if (key === "color") {
                    k = "colorOptions";
                } else if (key === "style_name") {
                    k = "styleOptions"
                }

                // else{
                // if (res?.data?.sentFilters?.filter_type == "style_name") {
                //     data["styleOptions"] = res.data?.data
                // }
                // else {
                // if (key === "style_name" || key === "color" || key === "product_type" && false) {
                if (false) {
                    data[k] = res.data.data.map((element) => ({
                        value: element[key],
                        label: element[key],
                    }));
                }
                else {
                    data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
                        value: element,
                        label: element,
                    }));
                }
                // }
                //   }


                // data[k] = res.data.data[0][key]?.filter(val => val)?.map((element) => ({
                //     value: element,
                //     label: element,
                // }));
            }
            // console.log("Filters data")
            // console.log(data)
            yield put({ type: actionTypes.FETCH_FILTER_SUCCESS, data: data, key: filterKey });
        }
    } catch (error) {
        yield put({ type: actionTypes.FETCH_FILTER_ERROR, error: ERROR_MSG });
    }
}

function* getProductStatusDataWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getProductConfiguration, payload);
        if (res?.data.status) {
            let data = res.data?.data
            data.forEach(item => {
                item.created_on = moment(item.created_on).format("MM-DD-YYYY hh:mm:ss")
                item.last_modified = moment(item.last_modified).format("MM-DD-YYYY hh:mm:ss")
            });
            yield put({ type: actionTypes.GET_PRODUCT_STATUS_DATA_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_PRODUCT_STATUS_DATA_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_PRODUCT_STATUS_DATA_ERROR, error: ERROR_MSG });
    }
}

function* getArticleConfigDataWorker(action) {
    try {
        const { payload } = action;
        let req = {...payload}
        delete req?.checkAll
        for (const key in payload) {
            if (Array.isArray(payload[key])) {
                if(!payload[key]?.length) {
                    delete req[key]
                }
                else {
                    req[key] = payload[key].map((ele) => ele?.value || ele)
                }
            }
            else if (isObject(payload[key]) && !isEmpty(payload[key]) && !(["searchColumns", "sortColumn"].includes(key))) {
                req[key] = [payload?.[key]?.value]
            }
        }
        payload?.["storegroup_flag"] && (req["storegroup_flag"] = payload?.["storegroup_flag"]?.value);
        const res = yield call(getArticleMapping, req);
        if (res?.data.status) {
            let data = res.data?.data
            let dataWithOptions = data.map(item => {
                if (!item.product_profiles) {
                    item.product_profiles = ""
                }
                else {
                    item.product_profiles = item.product_profiles.join(", ")
                }

                return{
                    ...item,
                    'options': options,
                    'disable_status': item?.allocation_status === 'deactivated' ? true : false
                }

            })
            let type = payload?.checkAll ? actionTypes.GET_CHECK_ALL_DATA_SUCCESS : (payload?.is_style_mapping ? actionTypes.GET_MAPPING_STYLES_SUCCESS : actionTypes.GET_ARTICLE_CONFIG_DATA_SUCCESS)
            yield put({ type , data: dataWithOptions,
                totalCount: res.data.total_count, nextIndex: res.data.row_index });
        }
        else {
            yield put({ type: payload?.is_style_mapping ? actionTypes.GET_MAPPING_STYLES_ERROR : actionTypes.GET_ARTICLE_CONFIG_DATA_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_ARTICLE_CONFIG_DATA_ERROR, error: ERROR_MSG });
    }
}

function* getStoreGroupDataWorker(action) {
    try {
        const { payload } = action;
        let req = createApiRequest(payload)
        if(payload["style_name"]) {
            req["style_name"] = payload["style_name"]?.value
        }
        if(payload["color"]) {
            req["color"] = payload["color"]?.value
        }
        if(payload["product_type"]) {
            req["product_type"] = payload["product_type"]?.value
        }
        const res = yield call(getStoreGroupMapping, req);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.GET_STORE_GROUP_DATA_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_STORE_GROUP_DATA_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_STORE_GROUP_DATA_ERROR, error: ERROR_MSG });
    }
}

function* getProductProfilesWorker(action) {
    try {
        const { payload } = action;
        let req = createApiRequest(payload)
        if(payload["style_name"]) {
            req["style_name"] = payload["style_name"]?.value
        }
        if(payload["color"]) {
            req["color"] = payload["color"]?.value
        }
        if(payload["product_type"]) {
            req["product_type"] = payload["product_type"]?.value
        }
        const res = yield call(getPPMapping, req);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.GET_PRODUCT_PROFILES_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_PRODUCT_PROFILES_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_PRODUCT_PROFILES_ERROR, error: ERROR_MSG });
    }
}

function* getDcDataWorker(action) {
    try {
        const { payload } = action;
        let req = {
            article: payload.article,
            l1_name: [payload?.filters?.l1_name?.value]
        }
        const res = yield call(getDCMapping, req);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.GET_DC_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_DC_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_DC_ERROR, error: ERROR_MSG });
    }
}

function* getContributionWorker(action) {
    try {
        const { payload } = action;
        const res = yield call(getStoresData, { product_profile_code: payload });
        if (res?.data.status) {
            let data = res.data
            data.storeData.map(item => {
                item.overall = Number(item.overall) ? (Number(item.overall) * 100).toFixed(1) + " %" : "-"
            })
            data.articleData.map(item => {
                item.contribution = Number(item.contribution) ? Number(item.contribution).toFixed(1) : "-"
            })
            yield put({ type: actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_STORE_SIZE_CONTRIBUTIONS_ERROR, error: ERROR_MSG });
    }
}

function* createProductConfigWorker(action) {
    try {
        const { payload } = action;

        // filters, dc, pp, storeGroups, name, configuration_id,
        //     article 
        let req = {
            "updated_by": localStorage?.getItem("email")
        }
        if(payload.isMapping) {
            req = { ...req,  ...payload }
        }
        delete req.isMapping
        if (payload.storeGroups) {
            req.store_groups = []
            payload.storeGroups.map(sg => {
                req.store_groups.push(sg.original.store_group_code)
            })
        }
        if (payload.pp) {
            req.product_profiles = []
            payload.pp.map(product => {
                req.product_profiles.push(product.original.product_profile_code)
            })
        }
        if (payload.dc) {
            req.dcs = []
            payload.dc.map(item => {
                req.dcs.push(item.original.store_code)
            })
        }
        if (payload.name) {
            req.configuration_name = payload.name
        }
        if (payload.article) {
            req.article = Array.isArray(payload.article) ? payload.article : [payload.article]
        }
        if (payload.configuration_id) {
            req.configuration_id = payload.configuration_id
        }
        if (payload.hasOwnProperty("related_article")) {
            req.related_article = payload.related_article
        }
        if (Object.keys(payload).includes("user_consent")) {
            req.user_consent = payload.user_consent
        }
        // if(payload?.filters) {
        //     Object.keys(payload.filters)?.map(key => {
        //         if (payload.filters[key]) {
        //             req[key] = payload.filters[key]
        //         }
        //     })
        // }

        if (payload?.filters) {
            req.l1_name = [payload?.filters['l1_name']?.value]
        }

        if (!req?.isArticleEdit) {
            req.auto_apply = payload.auto_apply
            req.created_by = payload.created_by
        }
        delete req?.isArticleEdit

        const res = yield call(createProductConfig, req);
        if (res?.data.status) {
            let data = res.data?.data

            yield put({ type: actionTypes.CREATE_CONFIG_SUCCESS, data: data });
        }
        else {
            if (res?.data?.no_articles) {
                yield put({ type: actionTypes.NO_ARTICLE_ERROR, error: "No articles available for selected data, Still you want to create configuration?" });
            }
            else {
                yield put({ type: actionTypes.CREATE_CONFIG_ERROR, error: res.data?.message ? res.data?.message : ERROR_MSG });
            }
        }
    }
    catch (error) {
        yield put({ type: actionTypes.CREATE_CONFIG_ERROR, error: ERROR_MSG });
    }
}


function* deleteProductConfigWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(deleteProductConfig, payload);
        if (res?.data.status) {
            let data = res.data

            yield put({ type: actionTypes.DELETE_CONFIG_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.DELETE_CONFIG_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.DELETE_CONFIG_ERROR, error: ERROR_MSG });
    }
}

function* getMissingConfigDataWorker() {

    yield put({ type: actionTypes.GET_MISSING_CONFIG_SUCCESS, data: missingConfigData.data });

}

function* getStyleMappingWorker(action) {
    try {
        const { payload } = action;
        // let req = createApiRequest(payload)
        const res = yield call(getProductConfigStyleMapping, createApiRequest(payload));
        if (res?.data.status) {
            let data = res.data?.data
            let maxLength = 0;
            data.map(item => {
                maxLength = item.article.length > maxLength ? item.article.length : maxLength
                if (!item.product_profiles) {
                    item.product_profiles = "-"
                }
                else {
                    item.product_profiles = item.product_profiles.join(", ")
                }

            })
            yield put({ type: actionTypes.GET_MAPPING_STYLES_SUCCESS, data: data, 
                totalCount: res.data.totalCount, nextIndex: res.data.row_index });
        }
        else {
            yield put({ type: actionTypes.GET_MAPPING_STYLES_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_MAPPING_STYLES_ERROR, error: ERROR_MSG });
    }
}

function* getArticleConfigExcelData(action) {
    try {
        const { payload } = action;
        let req = {...payload}
        delete req?.checkAll

        const res = yield call(getArticleMapping, req);
        if (res?.data.status) {
            let data = res.data?.data
            data.map(item => {
                if (!item.product_profiles) {
                    item.product_profiles = "-"
                }
                else {
                    item.product_profiles = item.product_profiles.join(", ")
                }

            })
            yield put({ type: actionTypes.GET_DOWNLOAD_EXCEL_DATA_SUCCESS , data: data,
                totalCount: res.data.total_count, nextIndex: res.data.row_index });
        }
        else {
            yield put({ type: actionTypes.GET_DOWNLOAD_EXCEL_DATA_ERROR, error: ERROR_MSG });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GET_ARTICLE_CONFIG_DATA_ERROR, error: ERROR_MSG });
    }
}

const createApiRequest = (filters) => {
    let req = {}
    for (const key in filters) {
        if (Array.isArray(filters[key])) {
            if(!filters[key]?.length) {
                delete req[key]
            }
            else {
                req[key] = filters[key].map((ele) => ele?.value || ele)
            }
        }
        else if (isObject(filters[key]) && !isEmpty(filters[key]) && !(["searchColumns", "sortColumn"].includes(key))) {
            req[key] = [filters?.[key]?.value]
        }
        else {
            req[key] = filters[key]
        }
    }
    return req
}

function* updateAutoAllocationRequiredWorker(action) {
    try {
        const { payload } = action;
         let req = {...payload}
        for (const key in payload) {
            if (Array.isArray(payload[key]) && payload[key] !== "allocation_status_inline") {
                if(!payload[key]?.length) {
                    delete req[key]
                }
                else {
                    req[key] = payload[key].map((ele) => ele?.value || ele)
                }
            }
            else if (isObject(payload[key]) && !isEmpty(payload[key]) && key != "searchColumns" && key != "sortColumn") {
                req[key] = [payload?.[key]?.value]
            }
        }
        req = { ...req, "updated_by": localStorage?.getItem("email")}
        const res = yield call(updateAutoAllocationRequired, req);

        if (res?.data.status) {
            let data = res.data
            yield put({ type: actionTypes.UPDATE_AUTO_ALLOCATION_SUCCESS, data: data });
        }
        else {
            yield put({ type: actionTypes.UPDATE_AUTO_ALLOCATION_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.UPDATE_AUTO_ALLOCATION_ERROR, error: "Something went wrong!" });
    }
}

function* fetchFilterDataWatcher() {
    yield takeEvery(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* getProductStatusDataWatcher() {
    yield takeLatest(actionTypes.GET_PRODUCT_STATUS_DATA, getProductStatusDataWorker);
}

function* getArticleConfigDataWatcher() {
    yield takeLatest(actionTypes.GET_ARTICLE_CONFIG_DATA, getArticleConfigDataWorker)
}

function* getStoreGroupDataWatcher() {
    yield takeLatest(actionTypes.GET_STORE_GROUP_DATA, getStoreGroupDataWorker);
}

function* getProductProfilesWatcher() {
    yield takeLatest(actionTypes.GET_PRODUCT_PROFILES, getProductProfilesWorker)
}

function* getDcDataWatcher() {
    yield takeLatest(actionTypes.GET_DC, getDcDataWorker)
}

function* getContributionWatcher() {
    yield takeLatest(actionTypes.GET_STORE_SIZE_CONTRIBUTIONS, getContributionWorker)
}

function* createProductConfigWatcher() {
    yield takeLatest(actionTypes.CREATE_CONFIG, createProductConfigWorker)
}

function* deleteProductConfigWatcher() {
    yield takeLatest(actionTypes.DELETE_CONFIG, deleteProductConfigWorker)
}

function* getMissingConfigDataWatcher() {
    yield takeLatest(actionTypes.GET_MISSING_CONFIG, getMissingConfigDataWorker)
}

function* getStyleMappingWatcher() {
    yield takeLatest(actionTypes.GET_MAPPING_STYLES, getStyleMappingWorker)
}

function* getArticleConfigExcelDataWAtcher() {
    yield takeLatest(actionTypes.DOWNLOAD_EXCEL_DATA, getArticleConfigExcelData)
}

function* updateAutoAllocationWatcher() {
    yield takeLatest(actionTypes.UPDATE_AUTO_ALLOCATION, updateAutoAllocationRequiredWorker)
}

export function* productStatusSaga() {
    yield all([
        fetchFilterDataWatcher(),
        getProductStatusDataWatcher(),
        getStoreGroupDataWatcher(),
        getArticleConfigDataWatcher(),
        getProductProfilesWatcher(),
        getDcDataWatcher(),
        createProductConfigWatcher(),
        getContributionWatcher(),
        deleteProductConfigWatcher(),
        getMissingConfigDataWatcher(),
        getStyleMappingWatcher(),
        getArticleConfigExcelDataWAtcher(),
        updateAutoAllocationWatcher(),
    ])
}