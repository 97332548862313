import React, { useState } from 'react'
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import '../ProductStoreStatus.css'
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import PageLoader from '../../../components/Loader/PageLoader';
import ColumnFilter from "../../UserManagement/ColumnFilter";
import StoreGroupMappingModal from "./Popups/StoreGroupMappingModal"
import AddStoreGroup from "./Popups/AddStoreGroupModal"
import { getDcData, createConfig, resetAll, resetCreateConfigData } from "./Actions/CreateConfigAction"
import * as Notify from '../../../components/Notification/Notifications'
import Notification from '../../../components/Notification/Notifications'
import Modal from "react-awesome-modal";
import { useEffect } from 'react';
import { CREATE_CONFIGURATION } from '../../../constants/routeConstants';
import StyleMapping from './Popups/StyleMapping';
import ExportFile from '../../../components/ExportFile/ExportFile';
import { ALLOCATION_REQUIRED_OPTIONS, TABLE_COLUMNS } from './ProductStatusConstants';
import ColumnFilterServerSide from '../../../components/Filters/ColumnFilterServerSide';
import { getFinalCheckedRowsForCAData, getNewData, removeCheckedArticles } from "../../../utils/paginationHelper"
import { cloneDeep, forEach, isEmpty, isEqual } from 'lodash';
import { getArticleConfigData, getDownloadExcelData, resetDownloadIndex } from './Actions/ProductStatusAction';
import DownloadProgressBar from '../../../components/DownloadProgressBar/DownloadPropgressBar';
import { ERROR_MSG } from '../../../constants/globalConstant';
import SetAllModal from '../../../components/ErrorModal/SetAllModal';
import moment from "moment";
import SelectCellComponent from '../../../components/Table/Cellrenderer/SelectCellComponent';
import DateInput from '../../../components/Table/Cellrenderer/DateInput';
import DatePicker, { today } from '../../../components/DatePicker/DatePicker';
import ReactTollTip from '../../../components/Table/Cellrenderer/ReactToolTip';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const ArticleConfigTable = (props) => {
    const { fetchData, setRTInstance, fecthDataWithSearchTerm, fecthDataWithSorting, initialPageIndex, totalCount, searchTermReq, sortReq,
    downloadNextIndex, downloadTotalCount, resetDownloadIndex, downloadExcelData, updateArticleData, checkAllReset} = props

    const history = useHistory();
    const [showStoreGroupModal, setShowStoreGroupModal] = useState(false)
    const [showProductProfile, setshowProductProfile] = useState(false)
    const [showDCModal, setShowDCModal] = useState(false)
    const [dcInstacne, setDCRTInstance] = useState({})
    const [dcSelectedRows, setDcSelectedRows] = useState({})
    const [selectedArticle, setSelectedArticle] = useState({})
    const [articleTableInstanse, setArticleTableInstanse] = useState({})
    const [disableBulkEdit, setDisableBulkEdit] = useState(false)
    const [dcTableData, setDCTableData] = useState([])
    const [request, setRequest] = useState({})
    const [showStyleModal, setShowStlyleModal] = useState(false)
    const [removeMapping, setRemoveMapping] = useState(false)
    const [exportExcelData, setExcelData] = useState([])
    const [checkAll, setCheckAll] = useState([])
    const [checkAllReqArr, setCheckAllReqArr] = useState([])
    const [prevAction, setPrevAction] = useState("")
    const [isGetAllLoading, setIsGetAllLoading] = useState(false)
    const [checkAllData, setCheckAllData] = useState([])
    const [checkedRows, setCheckedRows] = useState([])
    const [initialSelectedRows, setInitialSelectedRows] = useState({})
    const [downloadExcelLoading, setDownloadLoading] = useState(false)
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [showSetAll, setShowSetAll] = useState(false)
    const [allocationStatus, setallocationStatus] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [updatedId, setUpdatedId] = useState([])
    const [fromDate, setFromDate] = useState(null)
    const [uptoDate, setUptoDate] = useState(null)

    const getEndDate = (p_end, p_start) => {
        let d1 = moment(p_end)
        let d2 = moment(p_start)
        if (d1 > d2) {
            return moment(d1).format("YYYY-MM-DD")
        }
        else {
            return moment(d2).format("YYYY-MM-DD")
        }
    }

    const articleConfigColumns = [
        {
            Header: TABLE_COLUMNS["Article_ID"],
            accessor: "article",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.article?.search} placeholder={"Search in bulk by ','"} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
            ),
            sticky: "left",
            filter: 'bulkFilterCommaSeperated',
        },
        {
            Header: TABLE_COLUMNS["Style_ID"],
            accessor: 'style_code',
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            sticky: "left"
        },
        {
            Header: TABLE_COLUMNS["Color_ID"],
            accessor: 'color_code',
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            sticky: "left"
        },
        // {
        //     Header: "Description",
        //     accessor: "desc",
        //     Filter: ColumnFilter
        // },
        {
            Header: TABLE_COLUMNS["Style"],
            accessor: "style_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 250
        },
        {
            Header: TABLE_COLUMNS["Style_Color"],
            accessor: "color_desc",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_desc?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Global_Color"],
            accessor: "color",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Level1"],
            accessor: "l1_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l1_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Level2"],
            accessor: "l2_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Level3"],
            accessor: "l3_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Level4"],
            accessor: "l4_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Level5"],
            accessor: "l5_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l5_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Level6"],
            accessor: "l6_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l6_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Level7"],
            accessor: "l7_name",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l7_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Product_Type"],
            accessor: "product_type",
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.product_type?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Store_Group"],
            accessor: "store_group_names",
            Cell: (instance) => (
                <div className='row align-items-center'>
                    {/* {instance?.row?.original.storegroup_flag === 1 || instance?.row?.original?.plan_data_flag === 1 ? */}
                    <div className="clickable no_opacity" style={{ width: "260px" }} onClick={() => { openStoreGroupModal(instance) }}>
                        {instance?.value ?  <ReactTollTip {...instance} sliceLength = {30} /> : "-"}
                    </div>
                    {!!instance?.value?.length && !!instance?.row?.original?.storegroup_flag &&
                        <i class="fa fa-times-circle close-circle" onClick={() => deleteMapping(instance, "store_groups", "store group")} />}
                        {/* :
                        instance?.row?.original.storegroup_flag === 0 && instance?.row?.original?.plan_data_flag === 0 ?
                            <div className="clickable" onClick={() => { openStoreGroupModal(instance) }}>
                                -
                            </div> : "-"} */}
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 300
        },
        {
            Header: TABLE_COLUMNS["Product_Profile"],
            accessor: "product_profiles",
            Cell: (instance) => (
                <div className='row align-items-center'>
                    <div className="clickable" style={{ width: "260px" }} onClick={() => { openProductProfileModal(instance) }}>
                        {instance.value || "-"}
                    </div>
                    {!!instance?.value?.length && !!instance?.row?.original?.productprofile_flag &&
                        <i class="fa fa-times-circle close-circle" onClick={() => deleteMapping(instance, "product_profiles", "product profile")} />}
                </div>
            ),
            disableFilters: true,
            // Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.product_profiles?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            width: 300
        },
        {
            Header: TABLE_COLUMNS["DC"],
            accessor: "dcs_count",
            Cell: (instance) => (
                <div>
                    <div className="clickable" onClick={() => { openDcModal(instance) }}>{instance.value}</div>
                </div>
            ),
            disableFilters: true,
            // Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.dcs_count?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Old_Style_Mapped"],
            accessor: "related_article",
            Cell: (instance) => (
                <div className='row align-items-center'>
                    <div className="clickable" style={{ width: "120px" }} onClick={() => { openStyleMapping(instance) }}>
                        {instance?.value || "-"}
                    </div>
                    {!!instance?.value?.length &&
                        <i class="fa fa-times-circle close-circle" onClick={() => deleteMapping(instance, "related_article", "style")} />}
                </div>
            ),
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.related_article?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
        },
        {
            Header: TABLE_COLUMNS["Allocation_Status"],
            accessor: 'allocation_status',
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.allocation_status?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
            filter: 'filterSingleSelectCell',
            Cell: (instance) => <div>
                <SelectCellComponent
                    defaultValue={instance.row.original?.allocation_status}
                    options={instance.row.original?.options}
                    changeHandler={(rowIdx, columnId, value) => {
                        setDisabled(false)
                        setUpdatedId((old) => {
                            return [...old, instance.row.original?.article]
                        })
                        setallocationStatus(value);
                        let l_instance = instance.row.original
                        if (!l_instance.from_date && value === "paused") {
                            instance.updateMyData(rowIdx, "from_date", moment(new Date()).format("YYYY-MM-DD"));

                        }
                        if (!l_instance.to_date && value === "paused")
                            instance.updateMyData(rowIdx, "to_date", moment(new Date()).format("YYYY-MM-DD"));
                        if (value === "active" || value === "deactivated") {
                            instance.updateMyData(rowIdx, "from_date", '-');
                            instance.updateMyData(rowIdx, "to_date", '-')
                        }
                    }}
                    {...instance} />
            </div>,
        },
        {
            Header: TABLE_COLUMNS["Modified_by"],
            accessor: 'updated_by',
            width: 250,
            Filter: (instance) => <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.updated_by?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />,
          },
        {
            Header: TABLE_COLUMNS["From_Date"],
            accessor: 'from_date',
            disableFilters: true,
            Cell: (instance) => instance.row.original.allocation_status === "paused" ? <DateInput {...instance} value={instance.row.original?.from_date == '-' ? moment(new Date()).format("YYYY-MM-DD") : instance.row.original.from_date}
            dateFormat={"MM-DD-YYYY"}
            changeHandler={(rowIdx, columnId, value) => {
                 setUpdatedId((old) =>{
                     return [...old,instance.row.original?.article]
                 })
                 let l_date = getEndDate(instance.row.original.to_date, value)
                 setDisabled(false)
                 instance.updateMyData(rowIdx, "to_date", l_date)
              }}
            /> : '-',
            width: 170,
        },
        {
            Header: TABLE_COLUMNS["Upto_Date"],
            accessor: 'to_date',
            disableFilters: true,
            Cell: (instance) => instance.row.original.allocation_status === "paused" ? <DateInput {...instance} min={instance.row.original?.from_date == '-'? moment(new Date()).format("YYYY-MM-DD") : instance.row.original.from_date }
            value={instance.row.original?.to_date == '-' ? moment(new Date()).format("YYYY-MM-DD") : instance.row.original.to_date}
            changeHandler={(rowIdx, columnId, value) => {
                 setDisabled(false)
                 setUpdatedId((old) =>{
                     return [...old,instance.row.original?.article]
                 })
              }}
            dateFormat={"MM-DD-YYYY"} /> : '-',
            width: 170,
        },
    ]

    const deleteMapping = (inst, key, type) => {
        let req = {
            isMapping: true,
            filters: { ...props.filters },
            [key]: null,
            article: inst?.row?.original?.article ? [inst?.row?.original?.article] : articleTableInstanse?.selectedFlatRows?.map(row => row?.original?.article)
        }
        if (window.confirm(`Are you sure you wish to remove ${type} mapping?`)) {
            setRemoveMapping(true)
            props.createConfig(req)
        }
    }

    const dcColumns = [
        // {
        //     Header: "DC ID",
        //     accessor: "dcId",
        //     Filter: ColumnFilter
        // },
        {
            Header: "DC Name",
            accessor: "store_name",
            Filter: ColumnFilter
        },
        // {
        //     Header: "City",
        //     accessor: "city",
        //     Filter: ColumnFilter
        // },
        // {
        //     Header: "State",
        //     accessor: "state",
        //     Filter: ColumnFilter
        // },
        {
            Header: "Country",
            accessor: "country",
            Filter: ColumnFilter
        }
    ]

    const closeStoreGroupModal = (status, isEdited) => {
        setShowStoreGroupModal(false)
        if (isEdited) {
            callApis()
        }
    }

    const openStoreGroupModal = (instance) => {
        setSelectedArticle(instance.row?.original)
        setShowStoreGroupModal(true)
        props.setIsPopupOpened(true)
    }

    const openProductProfileModal = (instance) => {
        setSelectedArticle(instance.row?.original)
        setshowProductProfile(true)
        props.setIsPopupOpened(true)
    }

    const closeProductProfileModal = (status, isEdited) => {
        setshowProductProfile(false)
        // props.setIsPopupOpened(false)
        if (isEdited) {
            callApis()
        }
    }

    const openDcModal = (instance) => {
        //configuration_id: props.configuration_id
        let req = {}
        if (instance.row?.original?.article) {
            req.article = instance.row?.original?.article
            req.filters = { ...props?.filters }
        }

        setRequest(req)
        props.getDcData(req)
        setShowDCModal(true)
        props.setIsPopupOpened(true)
    }

    const openStyleMapping = (instance) => {
        setSelectedArticle(instance.row?.original)
        setShowStlyleModal(true)
        props.setIsPopupOpened(true)
    }

    const closeDcModal = (status, isEdited) => {
        setShowDCModal(false)
        // props.setIsPopupOpened(false)
        if (isEdited) {
            callApis()
        }
    }

    const callApis = () => {
        setDisabled(true);
        props.getProductStatusData(props?.filters)
        // props.getArticleConfigData()
    }

    useEffect(() => {

        if ((Object.keys(props.dcData).length) && props.dcData?.selected_dcs) {
            let data = []
            let remaining = []
            let selected = props.dcData?.selected_dcs?.length ? props.dcData?.selected_dcs : []
            props.dcData.dcs.map(dc => {
                if (selected.includes(dc.store_code.toString())) {
                    data.push(dc)
                }
                else {
                    remaining.push(dc)
                }
            });
            let initialSelectedRows = {}
            for (let i = 0; i < data.length; i++) {
                initialSelectedRows[i] = true
            }
            setDcSelectedRows(initialSelectedRows)
            remaining = data.concat(remaining)
            setDCTableData(remaining)
        }
    }, [props.dcData])

    const dcMapping = () => {
        if (dcInstacne?.selectedFlatRows?.length) {
            let filters = { ...props?.filters }
            let req = {
                ...request, filters
            }
            req.dc = dcInstacne.selectedFlatRows
            props.createConfig(req)
        }
        else {
            Notify.error("Select Atleaset one row!!")
        }
    }

    useEffect(() => {
        if ((props.createConfigData?.configuration_id || props.createConfigDataSuccess) && showDCModal) {
            props.resetCreateConfigData()
            Notify.success(`DC Mapped Successfully!!`)
            setTimeout(() => {
                closeDcModal(true, true)
            }, 2000)
        }
        else if(props.createConfigDataSuccess && removeMapping) {
            callApis()
            props.resetCreateConfigData()
            Notify.success(`Mapping removed successfully!!`)
            setTimeout(() => {
            setRemoveMapping(false)
            }, 3000)

        }
        else if (props.createConfigDataError) {
            props.resetCreateConfigData()
            Notify.error( ERROR_MSG)
        }
    }, [props.createConfigData, props.createConfigDataError, props.createConfigDataSuccess])

    useEffect(() => {
        return () => {
            props.resetAll()
            setUpdatedId([]);
        }
    }, [])

    // This useEffect is for resetting the check boxes when Filter is clicked.
    useEffect(() => {
        setCheckAll([])
        setInitialSelectedRows({})
    }, [checkAllReset])


    const generateExcelData = () => {
        let keys = [
            { label: TABLE_COLUMNS["Article_ID"], key: "article" }, { label: TABLE_COLUMNS["Style_ID"], key: "style_code" },
            { label: TABLE_COLUMNS["Color_ID"], key: "color_code" }, { label: TABLE_COLUMNS["Style"], key: "style_name" }, { label:TABLE_COLUMNS["Style_Color"], key: "color_desc" },
            { label:TABLE_COLUMNS["Global_Color"], key: "color" }, { label: TABLE_COLUMNS["Level2"], key: "l2_name" }, { label: TABLE_COLUMNS["Level3"], key: "l3_name" },
            { label: TABLE_COLUMNS["Level4"], key: "l4_name" }, { label: TABLE_COLUMNS["Level5"], key: "l5_name" }, { label:TABLE_COLUMNS["Level6"], key: "l6_name" },
            { label:TABLE_COLUMNS["Product_Type"], key: "product_type" }
        ]
        let excelData = []

        exportExcelData?.forEach(row => {
            let obj = {}
            keys.forEach(item => {
                obj[item.label] = row[item.key]
            })
            excelData.push(obj)
        })
        if (downloadExcelLoading) {
            setExcelData(excelData)
            setDownloadExcel(true)
        }
        setTimeout(() => {
            setDownloadLoading(false)
        }, 1000)
        setTimeout(() => {
            resetDownloadIndex()
        }, 2000)
    }

    const setSelectedArticles = (instance) => {
        setArticleTableInstanse(instance)
        setDisableBulkEdit(!(instance?.getSelectedRows?.length > 0))
        setRTInstance(instance)
    }


    const bulkEdit = (data) => {
        let selectedArticles = data?.length ? data.map(row => row?.article) : articleTableInstanse?.selectedFlatRows?.map(row => row?.original?.article)
        let filters = {
            ...props?.filters,
            // configuration_id: props?.configuration_id,
        }
        history.push(`${CREATE_CONFIGURATION}`, {
            filters, isArticlesBulkEdit: true,
            selectedArticles
        });
    }

    const closeStyleMappingModal = (isEdited) => {
        setShowStlyleModal(false)
        isEdited && callApis()
    }

    const updateSetAll = (value) => {
        let l_newAction = {
            "searchTermReq": searchTermReq
        }
        if (!value.checked) {
            setPrevAction("checkAll")
            l_newAction["checkAll"] = true

        }
        else {
            setPrevAction("unCheckAll")
            l_newAction["unCheckAll"] = true
            let l_updatedCheckedRows = removeCheckedArticles(checkedRows, searchTermReq)
            setCheckedRows(l_updatedCheckedRows)
        }
        setCheckAll((old) => [...old, l_newAction])
    }

    const updateCheckedRows = (value) => {
        let l_newAction = {}, l_article = value.instance.article

        if (!value.checked) {
            setPrevAction("checkRow")
            if (prevAction === "checkRow") {
                let checkAllCopy = [...checkAll]
                let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length - 1
                let l_checekdRows = checkAllCopy?.[l_lastValueIndex]
                l_checekdRows && l_checekdRows["checkedRows"]?.push(l_article)
                checkAllCopy.pop()
                l_checekdRows ? setCheckAll([...checkAllCopy, l_checekdRows]) : setCheckAll([...checkAllCopy])
            }
            else {
                l_newAction["checkedRows"] = [l_article]
                setCheckAll((old) => [...old, l_newAction])
            }

            let l_checkedRow = { [l_article]: value.instance }
            setCheckedRows((old) => {
                return {
                    ...old,
                    ...l_checkedRow
                }
            })

        }
        else {
            setPrevAction("unCheckRow")
            if (prevAction === "unCheckRow") {
                let checkAllCopy = [...checkAll]
                let l_lastValueIndex = (checkAllCopy.length - 1 === -1) ? 0 : checkAllCopy.length - 1
                let l_checekdRows = checkAllCopy[l_lastValueIndex]
                l_checekdRows["unCheckedRows"].push(l_article)
                checkAllCopy.pop()
                setCheckAll([...checkAllCopy, l_checekdRows])
            }
            else {
                l_newAction["unCheckedRows"] = [l_article]
                setCheckAll((old) => [...old, l_newAction])
            }


            let l_checkedRow = cloneDeep(checkedRows)
            delete l_checkedRow[l_article]
            setCheckedRows((old) => {
                return {
                    ...l_checkedRow
                }
            })
        }
    }

    const getCheckAllData = (p_checkAllReq, data) => {
        if (!isEmpty(p_checkAllReq)) {
            let l_checkAllRequest = p_checkAllReq[0]
            // if(l_checkAllRequest?.checkAll && isEmpty(l_checkAllRequest?.searchTermReq)) {
            //     setPrevCheckAllCall(true)
            // }
            props.getArticleConfigData({ ...props.filters, checkAll: true, row_count: 1000, row_index: props.checkAllNextIndex, searchColumns: l_checkAllRequest?.searchTermReq, sortColumn: sortReq });

        }
        else {
            bulkEdit(data)
        }
    }

    useEffect(() => {
        if (props?.checkAllData?.length) {
            let l_finalCheckedArticles = getFinalCheckedRowsForCAData(checkAll, props.checkAllData)
            // let data = cloneDeep(checkAllData).concat(props?.checkAllData)
            let data = cloneDeep(checkAllData).concat(Object.values(l_finalCheckedArticles))
            props?.checkAllData?.length && setCheckAllData(data)
            if (props.checkAllNextIndex < props.checkAllTotalCount && isGetAllLoading) {
                getCheckAllData(checkAllReqArr, data)
            }
            else if (props.checkAllNextIndex > props.checkAllTotalCount) {
                let checkAllArr = [...checkAllReqArr]
                checkAllArr.shift()
                // setCheckAll(checkAllArr)
                setCheckAllReqArr(checkAllArr)
                getCheckAllData(checkAllArr, data)
            }
            else if (isGetAllLoading) {
                setPrevAction("")
            }
        }
        else if (props.checkAllNextIndex > props.checkAllTotalCount) {
            let checkAllArr = [...checkAllReqArr]
            checkAllArr.shift()
            setCheckAllReqArr(checkAllArr)
            getCheckAllData(checkAllArr)
        }
    }, [props?.checkAllData])

    const checkPaginationHistory = () => {
        let l_checkedUnchecked = checkAll.filter(val => val.checkAll || val.unCheckAll)
        if (l_checkedUnchecked?.length) {
            let l_checkedUnchecked = checkAll.filter(val => val.checkAll || val.unCheckAll)
            let l_checkAll = []
            for (let i = 0; i < l_checkedUnchecked.length; i++) {
                if (l_checkedUnchecked[i].checkAll) {
                    l_checkAll.push(l_checkedUnchecked[i])
                }
                if (l_checkedUnchecked[i].unCheckAll) {
                    l_checkAll = []
                }
            }

            let checked = l_checkAll.filter(val => val.checkAll)
            let unchecked = l_checkAll.filter(val => val.unCheckAll)
            for (let i = 0; i < checked.length; i++) {
                for (let j = 0; j < unchecked.length; j++) {
                    if ((isEqual(checked[i].searchTermReq, unchecked[j].searchTermReq))) {
                        checked.splice(i, 1)
                    }
                }
            }
            setCheckAllReqArr(checked)
            // setCheckAllData(tableData)
            setIsGetAllLoading(true)
            // setTableData([])
            getCheckAllData(checked)
        }
        else {
            bulkEdit()
        }
    }

    const generateExcel = () => {
        setExcelData([])
        setDownloadLoading(true)
        setDownloadExcel(false)
        getExcelData()
    }

    const getExcelData = () => {
        props.getDownloadExcelData({ ...props.filters, row_index: downloadNextIndex, row_count: 500, searchColumns: searchTermReq, sortColumn: sortReq, reviewCall: props.reviewCall })
    }

    useEffect(() => {
        if (downloadExcelLoading && downloadNextIndex < downloadTotalCount) {
            getExcelData()
        }
        else if (downloadNextIndex > downloadTotalCount) {
            generateExcelData()
        }
    }, [downloadNextIndex])

    useEffect(() => {
        if (downloadExcelData?.length) {
            setExcelData(exportExcelData.concat(downloadExcelData))
        }
    }, [downloadExcelData])

    useEffect(() => {
        if(props.data.length) {
            let l_newData = getNewData({ "p_newData": props?.data, "p_checkAll": checkAll })
            setInitialSelectedRows(l_newData?.finalCheckedRows)
        }
        setDownloadExcel(false)
        setExcelData([])
    }, [props.data])

    const closeDownloadModal = () => {
        setDownloadLoading(false)
        resetDownloadIndex()
        setExcelData([])
    }

    const onAllocationStatusChange = (val) => {
        setallocationStatus(val.value);
        if (val.value === "paused") {
            setFromDate(moment(new Date()).format("MM/DD/YYYY"))
            setUptoDate(moment(new Date()).format("MM/DD/YYYY"))
        }
    }

    const prepInput = React.useMemo(() => [
        {
            id: 1,
            isRequired: true,
            label: "Allocation Status",
            name: "allocation_status",
            className: "setAll__filter",
            onChange: onAllocationStatusChange,
            placeHolder: `Select Allocation Status`,
            options: ALLOCATION_REQUIRED_OPTIONS,
            isMultiSelect: false,
            hideSelectAll: true,
        },
        {
            id: 2,
            hide: allocationStatus !== "paused",
            isRequired: false,
            label: "From",
            name: "from",
            className: "setAll__filter",
            input: true,
            Comp: <DatePicker minOrmax={{ min: today }}
            defaultValue={fromDate}
            onChange={(val) => {
                setFromDate(moment(val).format("MM/DD/YYYY"))
                // If from-date is ahead of to-date reset the to-date to from-date
                if (new Date(moment(val).format("MM/DD/YYYY")).getTime() > new Date(uptoDate).getTime()) {
                    setUptoDate(moment(val).format("MM/DD/YYYY"))
                }
            }}
            dateFormat="MM/DD/YYYY" />
        },
        {
            id: 3,
            hide: allocationStatus !== "paused",
            isRequired: false,
            label: "Upto Date",
            name: "upto_date",
            className: "setAll__filter",
            input: true,
            Comp: <DatePicker minOrmax={{ min: moment(fromDate).format("YYYY-MM-DD") }}
            defaultValue={uptoDate}
            onChange={(val) => setUptoDate(moment(val).format("MM/DD/YYYY"))}
            dateFormat="MM/DD/YYYY" />
        },
    ], [allocationStatus, fromDate, uptoDate])

    const handleSetAllOk = () => {
        let l_lastValueIndex = (checkAll?.length - 1 === -1) ? 0 : checkAll?.length - 1
        let req = {
          ...props.filters,
          allocation_status: allocationStatus,
          article:
            checkAll.length > 0
              ? !checkAll[l_lastValueIndex]?.checkAll
                ? articleTableInstanse?.selectedFlatRows?.map(
                    (row) => row?.original?.article
                  )
                : []
              : articleTableInstanse?.selectedFlatRows?.map(
                  (row) => row?.original?.article
                ),
          from_date: fromDate,
          to_date: uptoDate,
        };
        // find out index of all the data that needs to be updated.
        let setAllRows = articleTableInstanse?.selectedFlatRows?.map(
          (row) => row?.id
        );
        setCheckAll([])
        setInitialSelectedRows({})
        updateArticleData(setAllRows, allocationStatus, fromDate, uptoDate, req);
    }

    const handleSetAllCancel = () => {
        setShowSetAll(false);
    }

    const handleClose = () => {
    showSetAll && setShowSetAll(false);
    }

    const setAllHandler = () => {
        if ((articleTableInstanse?.selectedFlatRows).length > 0) {
          setShowSetAll(true);
          setallocationStatus(null);
        }
        else {
          Notify.error(`Please select atleast 1 article to use Set All functionality!!`)
        }
      }

    const updateChanges = () => {
        let uniqueUpdatedIds = [...new Set(updatedId)]
        let setAllRows = articleTableInstanse?.flatRows?.filter((val) =>
        uniqueUpdatedIds.includes(val.original.article)).map(row => row?.id)
        props.saveProductStatusData(uniqueUpdatedIds, setAllRows );
        setCheckAll([])
        setUpdatedId([]);
        setInitialSelectedRows({})
        setDisabled(true)
    }

    return (
        <>
            <DownloadProgressBar nextIndex={downloadNextIndex} totalCount={downloadTotalCount} showModal={downloadExcelLoading}
            closeModal={closeDownloadModal} />
            {showSetAll && (
                <SetAllModal
                    modalBodyStyle={{ height: '30rem' }}
                    filter={prepInput}
                    messageStyle={{ margin: "auto", color: "red" }}
                    handleOk={handleSetAllOk}
                    handleCancel={handleSetAllCancel}
                    handleClose={() => handleClose()}
                    disableApply={(allocationStatus === "paused" && (!fromDate && !uptoDate)) || !allocationStatus ? true : false }
                />
            )}
            <PageLoader loader={(props.dataLoading && !showStyleModal) || props.createConfigDataLoading} gridLoader={true}>
                {/* {removeMapping && <Notification /> } */}
                <h1 className="fnt-md fnt-bold mb-4">{props.config_name}</h1>
                {props?.data?.length && props?.filters?.storegroup_flag === 0 ?
                    <div className='text-right mb-2'>
                        {/* <ExportFile text={"Excel"} data={exportExcelData} fileName={`product_configuration${new Date().getTime()}`} type={1} /> */}
                        <ExportFile downloadExcelData={downloadExcel} text={"Excel"} callFunc={generateExcel} data={exportExcelData} fileName={`product_configuration${new Date().getTime()}`} type={1} sheetName={"Product Configuration"} tableId="product_config_article1"/>

                    </div> : null
                }
                <ReactTableWithPlugins
                    shouldPagination
                    totalRecordsLen={props.data?.length}
                    data={props.data ? props.data : []}
                    columns={articleConfigColumns}
                    renderMarkup='TableMarkup'
                    keyRT="StrategyPageTable"
                    features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    fetchData={(data) => fetchData(data)}
                    getInstanceOnMount={(instance) => {
                        setSelectedArticles(instance)
                    }}
                    tableId="product_config_article1"
                    getManualSortByOptions={(instance) => {
                        fecthDataWithSorting(instance)
                    }}
                    manualSortBy={true}
                    initialPageIndex={initialPageIndex}
                    totalCount={totalCount}
                    checkAllCallBack={(val) => updateSetAll(val)}
                    checkCallBack={(val) => updateCheckedRows(val)}
                    initialSelectedRows={initialSelectedRows}
                    // tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    // headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                    // hideDropdown
                />
                <div className="text-center mt-4">
                    <button
                        disabled={disableBulkEdit}
                        className="btn btn-primary store-grp-modal__btn fn__btn"
                        onClick={checkPaginationHistory}
                    >
                        Edit
                    </button>
                    <button
                        disabled={disableBulkEdit}
                        className="btn btn-primary store-grp-modal__btn fn__btn"
                        onClick={() => deleteMapping(null, "related_article", "style")}
                    >
                        Remove Style Mapping
                    </button>
                    <button
                        style={{ margin: '2px 1rem' }}
                        className="btn btn-primary"
                        onClick={setAllHandler}
                    >
                        Set All
                    </button>
                    <button
                        className="btn btn-primary"
                        disabled={disabled}
                        onClick={() => updateChanges()}
                    >
                        Save Changes
                    </button>
                </div>
            </PageLoader>
            {
                showStoreGroupModal &&
                <StoreGroupMappingModal
                    filters={props.filters}
                    callApis={callApis}
                    showModal={showStoreGroupModal} closeModal={closeStoreGroupModal}
                    selectedArticle={selectedArticle} configuration_id={props.configuration_id} />
            }
            {
                showProductProfile &&
                <AddStoreGroup
                    request={{ ...selectedArticle, configuration_id: props.configuration_id }}
                    filters={props.filters}
                    selectedArticle={selectedArticle} configuration_id={props.configuration_id}
                    isProductProfileCall={true}
                    showModal={showProductProfile}
                    closeModal={closeProductProfileModal}
                />
            }
            {
                showDCModal &&
                <div className="modal__div">
                    <Modal
                        visible={showDCModal}
                        width="85%"
                        // height="95%"
                        effect="fadeInDown"
                        onClickAway={closeDcModal}
                    >
                        {/* <Notification /> */}
                        <div className="modal-container">
                            <div className="modal-header">
                                <h5 className="page-heading text-center m-0">
                                    Select DC Sources
                                </h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span style={{ cursor: "pointer" }}>
                                        <i
                                            className="fa fa-times text-right m-2 "
                                            aria-hidden="true"
                                            onClick={closeDcModal}
                                        />
                                    </span>
                                </button>
                            </div>
                            <PageLoader loader={props.dcDataLoading || props.createConfigDataLoading} gridLoader={true}>
                                <div className="modal-middle-container modal-body">
                                    <div className="mx-2 p-2">
                                        <ReactTableWithPlugins
                                            hideCol
                                            // shouldPagination
                                            hideColumnsFilter
                                            totalRecordsLen={dcTableData.length}
                                            data={dcTableData ? dcTableData : []}
                                            columns={dcColumns}
                                            initialSelectedRows={dcSelectedRows}
                                            features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                                            renderMarkup='TableMarkup'
                                            keyRT="StrategyPageTable"
                                            getInstanceOnMount={(instance) => {
                                                setDCRTInstance(instance)
                                            }}
                                            tableId="product_config_article2"
                                        />
                                    </div>
                                </div>
                            </PageLoader>

                            <div className="text-center" style={{ padding: "1rem" }}>
                                <div className="center">
                                    <button
                                        className="btn btn-primary store-grp-modal__btn"
                                        onClick={closeDcModal}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="btn btn-primary store-grp-modal__btn fn__btn"
                                        onClick={dcMapping}
                                    >
                                        Edit DC Mapping
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
            {
                showStyleModal &&
                <StyleMapping showModal={showStyleModal} closeModal={closeStyleMappingModal} createConfig={props.createConfig}
                    isPopup filters={props.filters} articleDetails={selectedArticle} selectedArticles={[selectedArticle?.article]}
                    resetCreateConfigData={props.resetCreateConfigData} />
            }
        </>
    )
}

const mapStateToProps = ({ createConfig, productStatus }) => ({
    dcData: createConfig.dcData,
    dcDataLoading: createConfig.dcDataLoading,
    dcDataError: createConfig.dcDataError,
    createConfigData: createConfig.createConfigData,
    createConfigDataSuccess: createConfig.createConfigDataSuccess,
    createConfigDataLoading: createConfig.createConfigDataLoading,
    createConfigDataError: createConfig.createConfigDataError,
    checkAllData: productStatus.checkAllData,
    checkAllNextIndex: productStatus.checkAllNextIndex,
    checkAllTotalCount: productStatus.checkAllTotalCount,
    downloadNextIndex: productStatus.downloadNextIndex,
    downloadExcelData: productStatus.downloadExcelData,
    downloadTotalCount: productStatus.downloadTotalCount,
    downloadExcelError: productStatus.downloadExcelError,
})

const mapDispatchToProps = (dispatch) => ({
    getDcData: (payload) => dispatch(getDcData(payload)),
    createConfig: (payload) => dispatch(createConfig(payload)),
    resetCreateConfigData: () => dispatch(resetCreateConfigData()),
    resetAll: () => dispatch(resetAll()),
    getArticleConfigData: (payload) => dispatch(getArticleConfigData(payload)),
    resetDownloadIndex: () => dispatch(resetDownloadIndex()),
    getDownloadExcelData: (payload) => dispatch(getDownloadExcelData(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleConfigTable)
